const makeLink = (str: string): string => {
  if (str === undefined || str === null) {
    return "";
  }

  const replaceLinks = (inputStr: string): string => {
    return inputStr.replace(/\{%([^%]+)%\}/g, (_, linkContent: string) => {
      const linkParts = linkContent.split("$");
      const url = linkParts[0].includes("https://")
        ? linkParts[0]
        : "https://" + linkParts[0];
      const linkText = linkParts.length > 1 ? linkParts[1] : url;
      return `<a href="${url}" target="_blank">${linkText}</a>`;
    });
  };

  const createListItems = (inputStr: string): string => {
    const lines = inputStr.split("\n");
    return lines
      .map((line) => {
        // Modified to check for lines ending with '#'
        if (line.trim().endsWith("#")) {
          return `<li>${line.trim().slice(0, -1).trim()}</li>`; // Remove '#' and create list item
        }
        return line;
      })
      .join("");
  };

  let processedStr = replaceLinks(str);
  processedStr = createListItems(processedStr);
  if (processedStr.includes("<li>")) {
    processedStr = `<ul>${processedStr}</ul>`;
  }

  return processedStr;
};

export default makeLink;
