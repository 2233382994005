import { GetAnnouncementsModel } from "Models";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { getAnnouncements } from "Services/Queries";
import { announcementsRequest, announcementsRequestSuccess } from "./index";
import { globalStore } from "Store/global";
import { languageId } from "Utils";

function* handleAnnouncementsRequest() {
  try {
    const getAnnouncementResult: GetAnnouncementsModel = yield call(() =>
      getAnnouncements({ languageId: languageId() })
    );
    yield put(
      announcementsRequestSuccess(getAnnouncementResult.AnnouncementList)
    );
  } catch (error) {}
}

function* announcementsSaga() {
  yield takeLatest(announcementsRequest, handleAnnouncementsRequest);
}

export default announcementsSaga;
