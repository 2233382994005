import axios, { AxiosInstance, AxiosRequestConfig, Method } from "axios";

type AxiosConfig = {
  url?: "";
  method: "get" | "post";
  headers?: AxiosRequestConfig["headers"];
  parameters?: object;
  data?: any;
  command?: string;
  gatewayData?: {
    command: string;
    token: string;
  };
} & AxiosRequestConfig;

const axiosApiInstance: AxiosInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=utf-8",
  },
});

export const axiosHelper = async ({
  command,
  gatewayData,
  parameters,
  ...config
}: AxiosConfig) => {
  return await axiosApiInstance({
    ...config,
    params: parameters,
    timeout: 30000,
    validateStatus: function (status) {
      return status === 302 || (status >= 200 && status < 300);
    },
    ...(gatewayData && {
      data: {
        command: gatewayData.command,
        id: gatewayData.token,
        data: config.data,
      },
    }),
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });
};

axiosApiInstance.interceptors.request.use(
  async (request) => {
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.response.config;

    return Promise.reject(error);
  }
);
