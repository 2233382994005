import React, { useState, FC, memo } from "react";
import "./Reports.scss";
import { Box, Typography } from "@material-ui/core";
import { DownloadIcon } from "Icons";
import Images from "Assets/Images";
import { useLocalization, useWindowDimensions } from "Hooks";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import { RootState } from "Store";

export interface IReportsProps {}
type ReportsItemType = {
  title?: string;
  id?: number;
  onClick?: () => void;
  itemID?: string;
  img?: string;
  subTitle?: string;
  data?: any;
};
const Reports: React.FunctionComponent<IReportsProps> = (props) => {
  const { width } = useWindowDimensions();
  const localization = useLocalization();
  const { ActivityReports: currentReports } = useSelector(
    (state: RootState) => state.getActivityReports
  );
  // const currentReports = [
  //   {
  //     id: 1,
  //     imgPath: Images.ReportImg,
  //     title: "2022 Faaliyet Raporu",
  //     pdfUrl: localization?.institutional_reports_current_report_1,
  //     subTitle: "PDF döküman",
  //   },
  //   {
  //     id: 2,
  //     imgPath: Images.ReportImg,
  //     title: "2022 Faaliyet Raporu",
  //     pdfUrl: localization?.institutional_reports_current_report_2,
  //     subTitle: "PDF döküman",
  //   },
  // ];
  // const pastYears = [
  //   {
  //     id: 1,
  //     imgPath: Images.ReportImg,
  //     title: "2021 Faaliyet Raporu",
  //     pdfUrl: localization?.institutional_reports_past_reports_1,
  //     subTitle: "PDF döküman",
  //   },
  //   {
  //     id: 2,
  //     imgPath: Images.ReportImg,
  //     title: "2020 Faaliyet Raporu",
  //     pdfUrl: localization?.institutional_reports_past_reports_2,
  //     subTitle: "PDF döküman",
  //   },
  //   {
  //     id: 3,
  //     imgPath: Images.ReportImg,
  //     title: "2019 Faaliyet Raporu",
  //     pdfUrl: localization?.institutional_reports_past_reports_3,
  //     subTitle: "PDF döküman",
  //   },
  //   {
  //     id: 4,
  //     imgPath: Images.ReportImg,
  //     title: "2018 Faaliyet Raporu",
  //     pdfUrl: localization?.institutional_reports_past_reports_4,
  //     subTitle: "PDF döküman",
  //   },
  //   {
  //     id: 5,
  //     imgPath: Images.ReportImg,
  //     title: "2017 Faaliyet Raporu",
  //     pdfUrl: localization?.institutional_reports_past_reports_5,
  //     subTitle: "PDF döküman",
  //   },
  //   {
  //     id: 6,
  //     imgPath: Images.ReportImg,
  //     title: "2016 Faaliyet Raporu",
  //     pdfUrl: localization?.institutional_reports_past_reports_6,
  //     subTitle: "PDF döküman",
  //   },
  // ];
  function array_chunks(array: any, chunks: number) {
    let result: any = [];
    let n = array.length;
    for (let i = 0; i < n; i += chunks) {
      result = [...result, array.slice(i, i + chunks)];
    }
    return result;
  }
  return (
    <div id="reports" className="pageContainer">
      <div style={{ height: 50 }} />
      <Typography variant="h5" align="left">
        <Box fontWeight={700} className="reports__title">
          {localization?.institutional_reports_current_report_header}
        </Box>
      </Typography>
      {array_chunks(
        currentReports.filter(
          (item) => item?.ReportCategory?.Value === "Güncel"
        ),
        width < 900 ? 1 : 4
      ).map((items: any, id: number) => {
        return (
          <div className="reports__columns">
            {items.map(function (item: any, id: number) {
              return <ReportsItem data={item} />;
            })}
          </div>
        );
      })}

      <Typography
        style={{
          marginTop: "30px",
        }}
        variant="h5"
        align="left"
      >
        <Box className="reports__title">
          {localization?.institutional_reports_past_reports_header}
        </Box>
      </Typography>
      {array_chunks(
        currentReports.filter(
          (item) => item?.ReportCategory?.Value === "Geçmiş"
        ),
        width < 900 ? 1 : 4
      ).map((items: any, id: number) => {
        return (
          <div className="reports__columns">
            {items.map(function (item: any, id: number) {
              return <ReportsItem data={item} />;
            })}
          </div>
        );
      })}
    </div>
  );
};
export default Reports;

const ReportsItem: FC<ReportsItemType> = memo(({ data, itemID }) => {
  return (
    <div
      className="reports__item"
      onClick={() => {
        saveAs(
          data?.FileUrl ??
            "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          `${data?.FileName}.png`
        );
      }}
      itemID={itemID}
    >
      <img
        src={data?.FileCoverImageUrl ?? Images.ReportImg}
        alt={data?.FileName}
        className="reports__item__img"
      />
      <div style={{ width: 8 }} />
      <div>
        <Typography variant="h6" align="left">
          <Box fontWeight={700} className="reports__item__title">
            {data?.FileName}
          </Box>
        </Typography>
        <Typography variant="h6" align="left">
          <Box fontWeight={700} className="reports__item__subTitle">
            {data?.subTitle ?? "PDF döküman"}
          </Box>
        </Typography>
      </div>
      <div style={{ width: 8 }} />
      <DownloadIcon />
    </div>
  );
});
