import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetSlidersModel } from "Models";
import { RootState } from "../index";
import { SlidersState } from "./types";

export const initialState: SlidersState = {
  loading: true,
  WebSliderList: undefined,
};

export const slidersSlice = createSlice({
  name: "sliders",
  initialState,
  reducers: {
    onInit: (state) => {},
    slidersRequest: (state: SlidersState) => {
      state.loading = true;
    },
    slidersRequestSuccess: (
      state: SlidersState,
      action: PayloadAction<GetSlidersModel["WebSliderList"]>
    ) => {
      state.WebSliderList = action.payload;
      state.loading = false;
    },
  },
});

export const { onInit,  slidersRequest, slidersRequestSuccess } =
  slidersSlice.actions;

export const slidersStore = (state: RootState) => state.sliders;
export const storeSlidersData = (state: RootState) => state.sliders.WebSliderList;

export default slidersSlice.reducer;
