import { FunctionComponent } from "react";

const Calender: FunctionComponent<React.SVGProps<SVGSVGElement>> = (props) => {
  const randomId = Math.floor(Math.random() * 1000) + "_" + Math.floor(Math.random() * 100);
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id={`mask0_535_23051${randomId ?? 0}`}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="10"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 2H9C9.82843 2 10.5 2.67157 10.5 3.5V9.5C10.5 10.3284 9.82843 11 9 11H3C2.17157 11 1.5 10.3284 1.5 9.5V3.5C1.5 2.67157 2.17157 2 3 2H3.5V1.5C3.5 1.22386 3.72386 1 4 1C4.27614 1 4.5 1.22386 4.5 1.5V2H7.5V1.5C7.5 1.22386 7.72386 1 8 1C8.27614 1 8.5 1.22386 8.5 1.5V2ZM3.5 8C3.5 8.27614 3.72386 8.5 4 8.5C4.27614 8.5 4.5 8.27614 4.5 8C4.5 7.72386 4.27614 7.5 4 7.5C3.72386 7.5 3.5 7.72386 3.5 8ZM8 8.5H6C5.72386 8.5 5.5 8.27614 5.5 8C5.5 7.72386 5.72386 7.5 6 7.5H8C8.27614 7.5 8.5 7.72386 8.5 8C8.5 8.27614 8.27614 8.5 8 8.5ZM2.5 5.5H9.5V3.5C9.5 3.22386 9.27614 3 9 3H8.5V3.5C8.5 3.77614 8.27614 4 8 4C7.72386 4 7.5 3.77614 7.5 3.5V3H4.5V3.5C4.5 3.77614 4.27614 4 4 4C3.72386 4 3.5 3.77614 3.5 3.5V3H3C2.72386 3 2.5 3.22386 2.5 3.5V5.5Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_535_23051${randomId ?? 0})`}>
        <rect width="12" height="12" fill={props?.fill ?? "#383C52"} />
      </g>
    </svg>
  );
};

export default Calender;
