import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import GetProductsAndServicesModel, {
  Category,
} from "Models/ProductsAndServices";
import { RootState } from "../index";
import { ProductAndServices } from "./types";

export const initialState: ProductAndServices = {
  loading: true,
  ProductAndService: [],
  Categories: [],
};

export const productsAndServiceSlice = createSlice({
  name: "ProductAndService",
  initialState,
  reducers: {
    onInit: (state) => {},
    ProductAndService: (
      state: ProductAndServices,
      action: PayloadAction<any>
    ) => {
      state.loading = action.payload;
    },
    ProductAndServiceSuccess: (
      state: ProductAndServices,
      action: PayloadAction<GetProductsAndServicesModel>
    ) => {
      state.ProductAndService = action.payload.ProductsAndServicesList;
      state.Categories = action.payload.Categories;
      state.loading = false;
    },
  },
});

export const { onInit, ProductAndService, ProductAndServiceSuccess } =
  productsAndServiceSlice.actions;

export const productsAndServiceStore = (state: RootState) =>
  state.getProductsAndServices;

export default productsAndServiceSlice.reducer;
