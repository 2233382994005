import Grid from "@material-ui/core/Grid";
import Images from "Assets/Images";
import { useLocalization } from "Hooks";
import { SecurityRequest } from "Store/getSecurity";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { A11y, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Security.scss";

const Security: React.FunctionComponent = () => {
  const localization = useLocalization();
  const dispatch = useDispatch();
  const securityInfo = useSelector(
    (state: any) => state?.security?.Security?.[0]
  );
  useEffect(() => {
    dispatch(SecurityRequest(""));
  }, []);
  const dummyContent = [
    {
      title: localization?.security_mobile_section_subheader1,
      desc: localization?.security_mobile_section_first_sub_desc,
      imageUrl: Images.SifremiDegistir,
    },
    {
      title: localization?.security_mobile_section_subheader2,
      desc: localization?.security_mobile_section_second_sub_desc,
      imageUrl: Images.SifremiDegistir,
    },
    {
      title: localization?.security_mobile_section_subheader3,
      desc: localization?.security_mobile_section_subdesc3,

      imageUrl: Images.SifremiDegistir,
    },
    {
      title: localization?.security_mobile_section_subheader4,
      desc: localization?.security_mobile_section_subdesc4,
      imageUrl: Images.SifremiDegistir,
    },
    {
      title: localization?.security_mobile_section_subheader5,
      desc: localization?.security_mobile_section_subdesc5,
      imageUrl: Images.SifremiDegistir,
    },
    {
      title: localization?.security_mobile_section_subheader6,
      desc: localization?.security_mobile_section_subdesc6,
      imageUrl: Images.SifremiDegistir,
    },
  ];

  const dummyContentLeft = [dummyContent[0], dummyContent[1], dummyContent[2]];
  const dummyContentRight = [dummyContent[3], dummyContent[4], dummyContent[5]];
  const convertLinksToHTML = (text?: string) => {
    if (!text) {
      return "";
    }

    const regex = /\{%([^%]*)%\}/g;
    return text.replace(
      regex,
      '<a class="security__inline__link" target="_blank" href="$1">$1</a>'
    );
  };

  return (
    <div id="security">
      {/* <div className="pageContainer">
        <h1 className="security__title">
          {localization?.security_page_header}
        </h1>
        <div className="security__content">
           ITEM START
          <Grid className="security__content__item" container spacing={10}>
            <Grid item xs={12} md={7}>
              <h3>{localization?.security_section1_header}</h3>
              <div className="text">
                <p
                  dangerouslySetInnerHTML={{
                    __html: convertLinksToHTML(localization?.security_section1_desc),
                  }}
                ></p>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <img src={Images.SecurityImg} alt="" />
            </Grid>
          </Grid>
           ITEM END
           ITEM START
          <Grid className="security__content__item" container spacing={2}>
            <Grid item xs={12} md={7}>
              <h3>{localization?.security_section2_header}</h3>
              <div className="text">
                <p
                  dangerouslySetInnerHTML={{
                    __html: convertLinksToHTML(localization?.security_section2_desc),
                  }}
                ></p>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <img src={Images.SecurityImg} alt="" />
            </Grid>
          </Grid>
           ITEM END
        </div>
      </div>*/}
      <div className="securityAppInfo">
        <div className="pageContainer">
          <h4>
            {localization?.belbim_security_use_istanbulcard_safely_header}
          </h4>
          <Grid container spacing={4}>
            <Grid className="securityAppInfo__info" item md={4}>
              {dummyContentLeft.map(({ title, desc }, key) => (
                <div className="securityAppInfo__info__item" key={key}>
                  <h5>{title}</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: convertLinksToHTML(desc),
                    }}
                  />
                </div>
              ))}
            </Grid>
            <Grid item md={4}>
              <div className="banner">
                <img alt="" src={Images.SifremiDegistir} />
              </div>
            </Grid>
            <Grid className="securityAppInfo__info right__content" item md={4}>
              {dummyContentRight.map(({ title, desc }, key) => (
                <div className="securityAppInfo__info__item" key={key}>
                  <h5>{title}</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: convertLinksToHTML(desc),
                    }}
                  />
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="securityAppInfo__mobile">
        <h3>{localization?.security_mobile_section_header}</h3>
        <Swiper
          modules={[Pagination, A11y]}
          slidesPerView={1}
          pagination={true}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
        >
          {dummyContent.map(({ title, desc, imageUrl }, key) => (
            <SwiperSlide className="slide__mobile__item" key={key}>
              <div className="swiperItem__image">
                <img src={imageUrl} alt={title} />
              </div>
              <div className="swiperItem__text">
                <h5>{title}</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: convertLinksToHTML(desc),
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="pageContainer">
        <h1 className="security__title">
          {localization?.belbim_security_page_security_applications_header}
        </h1>
        <div className="security__content">
          {/* ITEM START */}
          <Grid className="security__content__item" container spacing={10}>
            <Grid item xs={12} md={7}>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: convertLinksToHTML(securityInfo?.ImageContentText),
                }}
              ></div>
            </Grid>
            <Grid item xs={12} md={5}>
              <img src={securityInfo?.ImageUrl} alt="" />
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: -35 }} className="security__content">
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: convertLinksToHTML(securityInfo?.SecurityPageText),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Security;
