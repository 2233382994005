// @ts-nocheck
import { CompanyJourneyList } from "Models/CompanyJourneyModal";
import { axiosHelper } from "../AxiosHelper";

const url = `${process.env.REACT_APP_CMS_API_URL}/Api/company/getBelbimCompanyJourney`;

const getCompanyJourney = async ({
  languageId,
}: any): Promise<CompanyJourneyList> => {
  return (
    await axiosHelper({
      method: "get",
      url,
      parameters: { languageId },
    })
  ).data;
};

export default getCompanyJourney;
