import { DownloadIconBlue } from "Icons";
import { FunctionComponent, useState } from "react";
import { saveAs } from "file-saver";
import "./PressImageBox.scss";

type PressImageBoxProps = {
  image?: any;
  title?: string;
  linkName?: string;
  downloadPath?: any;
};

const PressImageBox: FunctionComponent<PressImageBoxProps> = ({
  image,
  title,
  linkName,
  downloadPath,
}) => {
  const [isTouched, setIsTouched] = useState(false);
  return (
    <div className="press__image">
      {/* <div className="press__image__image">{image}</div> */}
      <img className="press__image__image" src={image} alt="image" />
      <p className="press__image__title">{title}</p>
      <a
        onClick={() => {
          saveAs(downloadPath, title);
        }}
        className="press__image__link"
        style={{
          textDecoration: isTouched ? "underline" : "none",
          cursor: "pointer",
        }}
        onMouseOver={() => {
          setIsTouched(true);
        }}
        onMouseLeave={() => {
          setIsTouched(false);
        }}
      >
        <p className="press__image__link__text">{linkName}</p>
        <DownloadIconBlue />
      </a>
    </div>
  );
};

export default PressImageBox;
