import React from "react";
import "./InformationSocietyServices.scss";
import { useLocalization } from "Hooks";
export interface IInformationSocietyServicesProps {}

const InformationSocietyServices: React.FunctionComponent<
  IInformationSocietyServicesProps
> = (props) => {
  const localization = useLocalization();
  return (
    <div className="informationSocietyServices pageContainer">
      <h1>{localization?.information_society_services_page_header}</h1>
      <iframe
        width={"100%"}
        height={"100%"}
        src="https://www.belgemodul.com/sirket/1480"
      ></iframe>
    </div>
  );
};

export default InformationSocietyServices;
