// @ts-nocheck
import { getNewsParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetNewsModel } from "./../../Models";

const url = `${process.env.REACT_APP_CMS_API_URL}/api/News/GetBelbimIstanbulNews`;

const getNews = async ({
  languageId,
}: getNewsParams): Promise<GetNewsModel> => {
  return (
    await axiosHelper({
      method: "post",
      url,
      data: { LanguageId: languageId },
      parameters: { languageId },
    })
  ).data;
};

export default getNews;
