import { useEffect } from "react";
import { ThemeProvider } from "@material-ui/core";
import Navigator from "./Navigator";
import { materialUITheme } from "./Theme/MaterialUITheme";
import { useDispatch } from "react-redux";
import { slidersRequest } from "Store/getSliders";
import { announcementsRequest } from "Store/getAnnouncements";
import { newsRequest } from "Store/getNews";
import { collaborationsRequest } from "Store/getCollaborations";
import { cmsRequest } from "./Store/global";
import { citiesRequest } from "Store/getCityList";
import { Footer, Header, Loading } from "Components";
import { useLocalization } from "Hooks";

function App() {
  const dispatch = useDispatch();
  const localization = useLocalization();

  useEffect(() => {
    dispatch(cmsRequest());
    dispatch(slidersRequest());
    dispatch(announcementsRequest());
    dispatch(newsRequest());
    dispatch(collaborationsRequest());
    dispatch(citiesRequest());
  }, []);
  return (
    <ThemeProvider theme={materialUITheme}>
      {!localization ? (
        <Loading />
      ) : (
        <>
          <Header />
          <Navigator />
          <Footer />
        </>
      )}
    </ThemeProvider>
  );
}

export default App;
