import { FunctionComponent } from "react";

const OrangeFavorite: FunctionComponent = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99988 12.25C6.84483 12.2509 6.6958 12.19 6.58572 12.0808L2.05322 7.54251C0.869956 6.34688 0.869956 4.42147 2.05322 3.22584C3.24622 2.03621 5.17688 2.03621 6.36988 3.22584L6.99988 3.85584L7.62988 3.22584C8.82288 2.03621 10.7536 2.03621 11.9466 3.22584C13.1298 4.42147 13.1298 6.34688 11.9466 7.54251L7.41405 12.0808C7.30397 12.19 7.15494 12.2509 6.99988 12.25Z"
        fill="black"
      />
      <mask
        id="mask0_412_10995"
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="12"
        height="11"
      >
        <path
          d="M6.99988 12.25C6.84483 12.2509 6.6958 12.19 6.58572 12.0808L2.05322 7.54251C0.869956 6.34688 0.869956 4.42147 2.05322 3.22584C3.24622 2.03621 5.17688 2.03621 6.36988 3.22584L6.99988 3.85584L7.62988 3.22584C8.82288 2.03621 10.7536 2.03621 11.9466 3.22584C13.1298 4.42147 13.1298 6.34688 11.9466 7.54251L7.41405 12.0808C7.30397 12.19 7.15494 12.2509 6.99988 12.25Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_412_10995)">
        <rect width="14" height="14" fill="#F47524" />
      </g>
    </svg>
  );
};

export default OrangeFavorite;
