import { GetAnnouncementsModel } from "Models";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { getActivityReports, getAnnouncements } from "Services/Queries";
import {
  GetActivityReportsState,
  getActivityReportsRequest,
  getActivityReportsRequestFail,
  getActivityReportsRequestSuccess,
} from "./index";
import { languageId } from "Utils";

function* handleGetActivityReportsRequest() {
  try {
    const getActivityReportsResult: GetActivityReportsState = yield call(() =>
      getActivityReports({ languageId: languageId() })
    );
    yield put(getActivityReportsRequestSuccess(getActivityReportsResult));
  } catch (error) {
    yield put(getActivityReportsRequestFail());
  }
}

function* announcementsSaga() {
  yield takeLatest(getActivityReportsRequest, handleGetActivityReportsRequest);
}

export default announcementsSaga;
