// @ts-nocheck
import { Security } from "Models/GetSecurity";
import { axiosHelper } from "../AxiosHelper";

const url = `${process.env.REACT_APP_CMS_API_URL}/Api/ProductsAndServices/GetProductsAndServices`;

const GetProductsAndServices = async ({ languageId }): Promise<Security> => {
  return (
    await axiosHelper({
      method: "get",
      parameters: { languageId },
      url,
    })
  ).data;
};

export default GetProductsAndServices;
