import * as React from "react";

function DownloadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 17C19.5523 17 20 17.4477 20 18V20C20 20.5523 19.5523 21 19 21H5C4.44771 21 4 20.5523 4 20V18C4 17.4477 4.44772 17 5 17C5.55228 17 6 17.4477 6 18V19H18V18C18 17.4477 18.4477 17 19 17ZM8 11C7.68524 11 7.38885 11.1482 7.2 11.4C7.04087 11.6122 6.97254 11.8789 7.01005 12.1414C7.04756 12.404 7.18783 12.6409 7.4 12.8L11.4 15.8C11.7514 16.0568 12.2286 16.0568 12.58 15.8L16.58 12.98C17.0288 12.6616 17.1361 12.0404 16.82 11.59C16.6669 11.3716 16.4329 11.2233 16.17 11.1782C15.9071 11.1332 15.6371 11.195 15.42 11.35L12.9984 13.0564C12.9995 13.0377 13 13.0189 13 13V5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V13C11 13.0024 11 13.0047 11 13.0071L8.6 11.2C8.4269 11.0702 8.21637 11 8 11Z"
        fill="black"
      />
      <mask
        id="mask0_337_10396"
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="16"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 17C19.5523 17 20 17.4477 20 18V20C20 20.5523 19.5523 21 19 21H5C4.44771 21 4 20.5523 4 20V18C4 17.4477 4.44772 17 5 17C5.55228 17 6 17.4477 6 18V19H18V18C18 17.4477 18.4477 17 19 17ZM8 11C7.68524 11 7.38885 11.1482 7.2 11.4C7.04087 11.6122 6.97254 11.8789 7.01005 12.1414C7.04756 12.404 7.18783 12.6409 7.4 12.8L11.4 15.8C11.7514 16.0568 12.2286 16.0568 12.58 15.8L16.58 12.98C17.0288 12.6616 17.1361 12.0404 16.82 11.59C16.6669 11.3716 16.4329 11.2233 16.17 11.1782C15.9071 11.1332 15.6371 11.195 15.42 11.35L12.9984 13.0564C12.9995 13.0377 13 13.0189 13 13V5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V13C11 13.0024 11 13.0047 11 13.0071L8.6 11.2C8.4269 11.0702 8.21637 11 8 11Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_337_10396)">
        <rect
          width="24"
          height="24"
          transform="matrix(1 0 0 -1 0 25)"
          fill="#004469"
        />
      </g>
    </svg>
  );
}

export default DownloadIcon;
