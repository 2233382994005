import { GetCollaborationsModel } from "Models";
import { call, put, takeLatest } from "redux-saga/effects";
import { getCollaborations } from "Services/Queries";
import { collaborationsRequest, collaborationsRequestSuccess } from "./index";
import { languageId } from "Utils";

function* handleCollaborationsRequest() {
  try {
    const getCollaborationsResult: GetCollaborationsModel = yield call(() =>
      getCollaborations({ languageId: languageId() })
    );
    yield put(collaborationsRequestSuccess(getCollaborationsResult.References));
  } catch (error) {}
}

function* collaborationsSaga() {
  yield takeLatest(collaborationsRequest, handleCollaborationsRequest);
}

export default collaborationsSaga;
