import { FunctionComponent } from "react";
import {
  TextFieldProps as MaterialUITextFieldProps,
  TextField as MaterialUITextField,
  IconButton,
} from "@material-ui/core";
import "./SearchField.scss";
import { Search, CloseFilled, SearchMobile } from "Icons";

type TextFieldProps = {
  text?: string;
  isDisabled?: boolean;
  light?: boolean;
  marginTop?: number | string;
  maxWidth?: string;
  backgroundColor?: string;
  setSearchQuery: Function;
  searchQuery: any;
  placeHolder?: string;
  keyValue?: any;
} & MaterialUITextFieldProps;

const SearchField: FunctionComponent<TextFieldProps> = ({
  children,
  text,
  setSearchQuery,
  searchQuery,
  placeHolder,
  keyValue,
  ...props
}) => {
  return (
    <>
      <MaterialUITextField
        id="search-bar"
        className="searchField"
        onInput={(e: any) => {
          setSearchQuery(e?.target?.value);
        }}
        value={searchQuery}
        // label={placeHolder ?? "Search something"}
        variant="outlined"
        placeholder={placeHolder}
        size="medium"
      />
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => {}}
        edge="end"
        style={{ marginLeft: -65, marginTop: 2, position: "absolute" }}
      >
        {searchQuery.length > 0 ? (
          <div
            onClick={() => {
              setSearchQuery("");
            }}
          >
            <CloseFilled />
          </div>
        ) : (
          <SearchMobile />
        )}
      </IconButton>
    </>
  );
};

export default SearchField;
