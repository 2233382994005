import Images from "Assets/Images";
import { useLocalization } from "Hooks";
import classNames from "classnames";
import React, { FC, memo } from "react";
import "./OrganizationChart.scss";
type OrganizationChartProps = {
  detailData: object;
  setDetailData: Function;
};

// Servisten bilgiler alındığında bu component'e props aracığı ile bu bilgiler girilmesi sağlanmalıdır.
const ItemComponent = ({
  name,
  title,
  photo,
  onClickItem,
  subChild,
  lastChild,
  parent,
  style,
  notRedirect,
}: any) => (
  <div
    className={classNames(
      "box",
      "connectedTo",
      subChild && "sub-item-box",
      lastChild && "sub-item-box sub-item-box--last",
      !parent && "child-box"
    )}
    onClick={onClickItem}
    style={{
      wordBreak: "break-all",
      cursor: !notRedirect ? "default !important" : "pointer",
      userSelect: "none",
      ...style,
    }}
  >
    {photo && <img src={photo ?? Images.GenelBaskanImg} />}
    <h4
      style={{
        cursor: !notRedirect ? "default !important" : "pointer",
        userSelect: "none",
      }}
    >
      {name}
    </h4>
    <label
      style={{
        cursor: !notRedirect ? "default !important" : "pointer",
        userSelect: "none",
      }}
    >
      {title}
    </label>
  </div>
);

const OrganizationChart: FC<OrganizationChartProps> = memo(
  ({ detailData, setDetailData }) => {
    const localization = useLocalization();
    const yonetim = [
      {
        id: 1,
        parent: true,
        name: localization.ic_kontrol_sorumlusu_isim,
        title: localization.ic_kontrol_sorumlusu_unvan,
        photo: localization.ic_kontrol_sorumlusu_resim,
        onClickItem: () =>
          localization.ic_kontrol_sorumlusu_detay_baslik &&
          setDetailData({
            title: localization.ic_kontrol_sorumlusu_detay_unvan,
            name: localization.ic_kontrol_sorumlusu_detay_baslik,
            imgPath: localization.ic_kontrol_sorumlusu_detay_resim,
            content1: localization.ic_kontrol_sorumlusu_detay_paragraph_1,
            content2: localization.ic_kontrol_sorumlusu_detay_paragraph_2,
            content3: localization.ic_kontrol_sorumlusu_detay_paragraph_3,
            content4: localization.ic_kontrol_sorumlusu_detay_paragraph_4,

            content5: "",
            content6: "",
            content7: "",
            bottomTitle: "",
            bottomTitle2: "",
          }),
        notRedirect: !!localization.ic_kontrol_sorumlusu_detay_baslik,
      },
      {
        id: 2,
        parent: true,

        name: localization.genel_mudur_isim,
        title: localization.genel_mudur_unvan,
        photo: localization.genel_mudur_resim,
        onClickItem: () =>
          localization.genel_mudur_detay_baslik &&
          setDetailData({
            title: localization.genel_mudur_detay_unvan,
            name: localization.genel_mudur_detay_baslik,
            imgPath: localization.genel_mudur_detay_resim,
            content1: localization.genel_mudur_detay_paragraph_1,
            content2: localization.genel_mudur_detay_paragraph_2,
            content3: localization.genel_mudur_detay_paragraph_3,
            content4: localization.genel_mudur_detay_paragraph_4,

            content5: "",
            content6: "",
            content7: "",
            bottomTitle: "",
            bottomTitle2: "",
          }),
        notRedirect: !!localization.genel_mudur_detay_baslik,
      },
      {
        id: 3,
        parent: true,

        name: localization.denetim_mudurlugu_isim,
        title: localization.denetim_mudurlugu_unvan,
        photo: localization.denetim_mudurlugu_resim,
        onClickItem: () =>
          localization.denetim_mudurlugu_detay_baslik &&
          setDetailData({
            title: localization.denetim_mudurlugu_detay_unvan,
            name: localization.denetim_mudurlugu_detay_baslik,
            imgPath: localization.denetim_mudurlugu_detay_resim,

            content1: localization.denetim_mudurlugu_detay_paragraph_1,
            content2: localization.denetim_mudurlugu_detay_paragraph_2,
            content3: localization.denetim_mudurlugu_detay_paragraph_3,
            content4: localization.denetim_mudurlugu_detay_paragraph_4,

            content5: "",
            content6: "",
            content7: "",
            bottomTitle: "",
            bottomTitle2: "",
          }),
        notRedirect: !!localization.denetim_mudurlugu_detay_baslik,
      },
      {
        id: 4,
        parent: true,

        name: localization.risk_yonetimi_ve_uyum_sefligi_isim,
        title: localization.risk_yonetimi_ve_uyum_sefligi_unvan,
        photo: localization.risk_yonetimi_ve_uyum_sefligi_resim,
        onClickItem: () =>
          localization.risk_yonetimi_ve_uyum_sefligi_detay_baslik &&
          setDetailData({
            title: localization.risk_yonetimi_ve_uyum_sefligi_detay_unvan,
            name: localization.risk_yonetimi_ve_uyum_sefligi_detay_baslik,
            imgPath: localization.risk_yonetimi_ve_uyum_sefligi_detay_resim,
            content1:
              localization.risk_yonetimi_ve_uyum_sefligi_detay_paragraph_1,
            content2:
              localization.risk_yonetimi_ve_uyum_sefligi_detay_paragraph_2,
            content3:
              localization.risk_yonetimi_ve_uyum_sefligi_detay_paragraph_3,
            content4:
              localization.risk_yonetimi_ve_uyum_sefligi_detay_paragraph_4,
            content5: "",
            content6: "",
            content7: "",
            bottomTitle: "",
            bottomTitle2: "",
          }),
        notRedirect: !!localization.risk_yonetimi_ve_uyum_sefligi_detay_baslik,
      },
    ];

    const personel1 = [
      {
        parent: true,
        name: localization.hukuk_ve_regulasyon_musavirligi_isim,
        subChild: true,
        title: localization.hukuk_ve_regulasyon_musavirligi_unvan,
        photo: localization.hukuk_ve_regulasyon_musavirligi_resim,
        notRedirect:
          !!localization.hukuk_ve_regulasyon_musavirligi_detay_baslik,

        onClickItem: () =>
          localization?.hukuk_ve_regulasyon_musavirligi_detay_baslik &&
          setDetailData({
            id: 1,
            title: localization?.hukuk_ve_regulasyon_musavirligi_detay_unvan,
            name: localization?.hukuk_ve_regulasyon_musavirligi_detay_baslik,
            imgPath: localization?.hukuk_ve_regulasyon_musavirligi_detay_resim,
            content1:
              localization?.hukuk_ve_regulasyon_musavirligi_detay_paragraph_1,
            content2:
              localization?.hukuk_ve_regulasyon_musavirligi_detay_paragraph_2,
            content3:
              localization?.hukuk_ve_regulasyon_musavirligi_detay_paragraph_3,
            content4:
              localization?.hukuk_ve_regulasyon_musavirligi_detay_paragraph_4,
            content5: "",
            content6: "",
            content7: "",
            bottomTitle: "",
            bottomTitle2: "",
          }),
        children: [],
      },
    ];

    const personel2 = [
      {
        parent: true,
        subChild: true,
        name: localization.teknoloji_gmy_isim,
        title: localization.teknoloji_gmy_unvan,
        photo: localization.teknoloji_gmy_resim,
        notRedirect: !!localization.teknoloji_gmy_detay_baslik,

        onClickItem: function () {
          localization.teknoloji_gmy_detay_baslik &&
            setDetailData({
              id: 3,
              title: localization.teknoloji_gmy_detay_unvan,
              name: localization.teknoloji_gmy_detay_baslik,
              imgPath: localization.teknoloji_gmy_detay_resim,
              content1: localization.teknoloji_gmy_detay_paragraph_1,
              content2: localization.teknoloji_gmy_detay_paragraph_2,
              content3: localization.teknoloji_gmy_detay_paragraph_3,
              content4: localization.teknoloji_gmy_detay_paragraph_4,
            });
        },
        children: [
          {
            subChild: true,
            name: localization.proje_yonetim_ve_teknoloji_mudurlugu_isim,
            title: localization.proje_yonetim_ve_teknoloji_mudurlugu_unvan,
            photo: localization.proje_yonetim_ve_teknoloji_mudurlugu_resim,
            notRedirect:
              !!localization.proje_yonetim_ve_teknoloji_mudurlugu_detay_baslik,

            onClickItem: function () {
              localization.proje_yonetim_ve_teknoloji_mudurlugu_detay_baslik &&
                setDetailData({
                  id: 4,
                  title:
                    localization.proje_yonetim_ve_teknoloji_mudurlugu_detay_unvan,
                  name: localization.proje_yonetim_ve_teknoloji_mudurlugu_detay_baslik,
                  imgPath:
                    localization.proje_yonetim_ve_teknoloji_mudurlugu_detay_resim,
                  content1:
                    localization.proje_yonetim_ve_teknoloji_mudurlugu_detay_paragraph_1,
                  content2:
                    localization.proje_yonetim_ve_teknoloji_mudurlugu_detay_paragraph_2,
                  content3:
                    localization.proje_yonetim_ve_teknoloji_mudurlugu_detay_paragraph_3,
                  content4:
                    localization.proje_yonetim_ve_teknoloji_mudurlugu_detay_paragraph_4,
                });
            },
            children: [
              {
                subChild: true,
                name: localization.uygulama_gelistirme_mudurlugu_isim,
                title: localization.uygulama_gelistirme_mudurlugu_unvan,
                photo: localization.uygulama_gelistirme_mudurlugu_resim,
                notRedirect:
                  !!localization.uygulama_gelistirme_mudurlugu_detay_baslik,

                onClickItem: function () {
                  localization.uygulama_gelistirme_mudurlugu_detay_baslik &&
                    setDetailData({
                      id: 5,
                      title:
                        localization.uygulama_gelistirme_mudurlugu_detay_unvan,
                      name: localization.uygulama_gelistirme_mudurlugu_detay_baslik,
                      imgPath:
                        localization.uygulama_gelistirme_mudurlugu_detay_resim,
                      content1:
                        localization.uygulama_gelistirme_mudurlugu_detay_paragraph_1,
                      content2:
                        localization.uygulama_gelistirme_mudurlugu_detay_paragraph_2,
                      content3:
                        localization.uygulama_gelistirme_mudurlugu_detay_paragraph_3,
                      content4:
                        localization.uygulama_gelistirme_mudurlugu_detay_paragraph_4,
                    });
                },
                children: [
                  {
                    subChild: true,
                    name: localization.bilgi_teknolojileri_operasyon_mudurlugu_isim,
                    title:
                      localization.bilgi_teknolojileri_operasyon_mudurlugu_unvan,
                    photo:
                      localization.bilgi_teknolojileri_operasyon_mudurlugu_resim,
                    notRedirect:
                      !!localization.bilgi_teknolojileri_operasyon_mudurlugu_detay_baslik,

                    onClickItem: function () {
                      localization.bilgi_teknolojileri_operasyon_mudurlugu_detay_baslik &&
                        setDetailData({
                          id: 6,
                          title:
                            localization.bilgi_teknolojileri_operasyon_mudurlugu_detay_unvan,
                          name: localization.bilgi_teknolojileri_operasyon_mudurlugu_detay_baslik,
                          imgPath:
                            localization.bilgi_teknolojileri_operasyon_mudurlugu_detay_resim,
                          content1:
                            localization.bilgi_teknolojileri_operasyon_mudurlugu_detay_paragraph_1,
                          content2:
                            localization.bilgi_teknolojileri_operasyon_mudurlugu_detay_paragraph_2,
                          content3:
                            localization.bilgi_teknolojileri_operasyon_mudurlugu_detay_paragraph_3,
                          content4:
                            localization.bilgi_teknolojileri_operasyon_mudurlugu_detay_paragraph_4,
                        });
                    },
                    children: [
                      {
                        name: localization.servis_operasyonlari_mudurlugu_isim,
                        title:
                          localization.servis_operasyonlari_mudurlugu_unvan,
                        photo:
                          localization.servis_operasyonlari_mudurlugu_resim,
                        lastChild: true,
                        notRedirect:
                          !!localization.servis_operasyonlari_mudurlugu_detay_baslik,

                        onClickItem: function () {
                          localization.servis_operasyonlari_mudurlugu_detay_baslik &&
                            setDetailData({
                              id: 7,
                              title:
                                localization.servis_operasyonlari_mudurlugu_detay_unvan,
                              name: localization.servis_operasyonlari_mudurlugu_detay_baslik,
                              imgPath:
                                localization.servis_operasyonlari_mudurlugu_detay_resim,
                              content1:
                                localization.servis_operasyonlari_mudurlugu_detay_paragraph_1,
                              content2:
                                localization.servis_operasyonlari_mudurlugu_detay_paragraph_2,
                              content3:
                                localization.servis_operasyonlari_mudurlugu_detay_paragraph_3,
                              content4:
                                localization.servis_operasyonlari_mudurlugu_detay_paragraph_4,
                            });
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ];

    const personel3 = [
      {
        parent: true,
        name: localization.pazarlama_ve_musteri_yonetim_gmy_isim,
        title: localization.pazarlama_ve_musteri_yonetim_gmy_unvan,
        photo: localization.pazarlama_ve_musteri_yonetim_gmy_resim,
        notRedirect:
          !!localization.pazarlama_ve_musteri_yonetim_gmy_detay_baslik,

        onClickItem: () =>
          localization.pazarlama_ve_musteri_yonetim_gmy_detay_baslik &&
          setDetailData({
            id: 1,
            title: localization.pazarlama_ve_musteri_yonetim_gmy_detay_unvan,
            name: localization.pazarlama_ve_musteri_yonetim_gmy_detay_baslik,
            imgPath: localization.pazarlama_ve_musteri_yonetim_gmy_detay_resim,
            content1:
              localization.pazarlama_ve_musteri_yonetim_gmy_detay_paragraph_1,
            content2:
              localization.pazarlama_ve_musteri_yonetim_gmy_detay_paragraph_2,
            content3:
              localization.pazarlama_ve_musteri_yonetim_gmy_detay_paragraph_3,
            content4:
              localization.pazarlama_ve_musteri_yonetim_gmy_detay_paragraph_4,
            content5: "",
            content6: "",
            content7: "",
            bottomTitle: "",
            bottomTitle2: "",
          }),
        subChild: true,
        children: [
          {
            name: localization.pazarlama_mudurlugu_isim,
            title: localization.pazarlama_mudurlugu_unvan,
            photo: localization.pazarlama_mudurlugu_resim,
            notRedirect: !!localization.pazarlama_mudurlugu_detay_baslik,

            onClickItem: () =>
              localization.pazarlama_mudurlugu_detay_baslik &&
              setDetailData({
                id: 2,
                title: localization.pazarlama_mudurlugu_detay_unvan,
                name: localization.pazarlama_mudurlugu_detay_baslik,
                imgPath: localization.pazarlama_mudurlugu_detay_resim,
                content1: localization.pazarlama_mudurlugu_detay_paragraph_1,
                content2: localization.pazarlama_mudurlugu_detay_paragraph_2,
                content3: localization.pazarlama_mudurlugu_detay_paragraph_3,
                content4: localization.pazarlama_mudurlugu_detay_paragraph_4,
                content5: "",
                content6: "",
                content7: "",
                bottomTitle: "",
                bottomTitle2: "",
              }),
            subChild: true,
            children: [
              {
                name: localization.musteri_yonetim_mudurlugu_isim,
                title: localization.musteri_yonetim_mudurlugu_unvan,
                photo: localization.musteri_yonetim_mudurlugu_resim,
                notRedirect:
                  !!localization.musteri_yonetim_mudurlugu_detay_baslik,

                onClickItem: () =>
                  localization.musteri_yonetim_mudurlugu_detay_baslik &&
                  setDetailData({
                    id: 3,
                    title: localization.musteri_yonetim_mudurlugu_detay_unvan,
                    name: localization.musteri_yonetim_mudurlugu_detay_baslik,
                    imgPath: localization.musteri_yonetim_mudurlugu_detay_resim,
                    content1:
                      localization.musteri_yonetim_mudurlugu_detay_paragraph_1,
                    content2:
                      localization.musteri_yonetim_mudurlugu_detay_paragraph_2,
                    content3:
                      localization.musteri_yonetim_mudurlugu_detay_paragraph_3,
                    content4:
                      localization.musteri_yonetim_mudurlugu_detay_paragraph_4,
                    content5: "",
                    content6: "",
                    content7: "",
                    bottomTitle: "",
                    bottomTitle2: "",
                  }),
                subChild: true,
                children: [
                  {
                    name: localization.satis_ve_is_gelistirme_mudurlugu_isim,
                    title: localization.satis_ve_is_gelistirme_mudurlugu_unvan,
                    photo: localization.satis_ve_is_gelistirme_mudurlugu_resim,
                    notRedirect:
                      !!localization.satis_ve_is_gelistirme_mudurlugu_detay_baslik,

                    onClickItem: () =>
                      localization.satis_ve_is_gelistirme_mudurlugu_detay_baslik &&
                      setDetailData({
                        id: 4,
                        title:
                          localization.satis_ve_is_gelistirme_mudurlugu_detay_unvan,
                        name: localization.satis_ve_is_gelistirme_mudurlugu_detay_baslik,
                        imgPath:
                          localization.satis_ve_is_gelistirme_mudurlugu_detay_resim,
                        content1:
                          localization.satis_ve_is_gelistirme_mudurlugu_detay_paragraph_1,
                        content2:
                          localization.satis_ve_is_gelistirme_mudurlugu_detay_paragraph_2,
                        content3:
                          localization.satis_ve_is_gelistirme_mudurlugu_detay_paragraph_3,
                        content4:
                          localization.satis_ve_is_gelistirme_mudurlugu_detay_paragraph_4,
                        content5: "",
                        content6: "",
                        content7: "",
                        bottomTitle: "",
                        bottomTitle2: "",
                      }),
                    lastChild: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
    const personel4 = [
      {
        parent: true,
        subChild: true,
        name: localization?.mali_ve_idari_gmy_isim,
        title: localization?.mali_ve_idari_gmy_unvan,
        photo: localization?.mali_ve_idari_gmy_resim,
        notRedirect: !!localization.mali_ve_idari_gmy_detay_baslik,

        onClickItem: () =>
          localization?.mali_ve_idari_gmy_detay_baslik &&
          setDetailData({
            id: 1,
            title: localization?.mali_ve_idari_gmy_detay_unvan,
            name: localization?.mali_ve_idari_gmy_detay_baslik,
            imgPath: localization?.mali_ve_idari_gmy_detay_resim,
            content1: localization?.mali_ve_idari_gmy_detay_paragraph_1,
            content2: localization?.mali_ve_idari_gmy_detay_paragraph_2,
            content3: localization?.mali_ve_idari_gmy_detay_paragraph_3,
            content4: localization?.mali_ve_idari_gmy_detay_paragraph_4,
            content5: "",
            content6: "",
            content7: "",
            bottomTitle: "",
            bottomTitle2: "",
          }),
        children: [
          {
            subChild: true,
            name: localization.destek_hizmetleri_mudurlugu_isim,
            title: localization.destek_hizmetleri_mudurlugu_unvan,
            photo: localization.destek_hizmetleri_mudurlugu_resim,
            notRedirect:
              !!localization.destek_hizmetleri_mudurlugu_detay_baslik,

            onClickItem: () =>
              localization?.destek_hizmetleri_mudurlugu_detay_baslik &&
              setDetailData({
                id: 1,
                title: localization?.destek_hizmetleri_mudurlugu_detay_unvan,
                name: localization?.destek_hizmetleri_mudurlugu_detay_baslik,
                imgPath: localization?.destek_hizmetleri_mudurlugu_detay_resim,
                content1:
                  localization?.destek_hizmetleri_mudurlugu_detay_paragraph_1,
                content2:
                  localization?.destek_hizmetleri_mudurlugu_detay_paragraph_2,
                content3:
                  localization?.destek_hizmetleri_mudurlugu_detay_paragraph_3,
                content4:
                  localization?.destek_hizmetleri_mudurlugu_detay_paragraph_4,
                content5: "",
                content6: "",
                content7: "",
                bottomTitle: "",
                bottomTitle2: "",
              }),
            children: [
              {
                subChild: true,
                name: localization?.mali_isler_mudurlugu_isim,
                title: localization.mali_isler_mudurlugu_unvan,
                photo: localization.mali_isler_mudurlugu_resim,
                notRedirect: !!localization.mali_isler_mudurlugu_detay_baslik,

                onClickItem: () =>
                  localization?.mali_isler_mudurlugu_detay_baslik &&
                  setDetailData({
                    id: 1,
                    title: localization?.mali_isler_mudurlugu_detay_unvan,
                    name: localization?.mali_isler_mudurlugu_detay_baslik,
                    imgPath: localization?.mali_isler_mudurlugu_detay_resim,
                    content1:
                      localization?.mali_isler_mudurlugu_detay_paragraph_1,
                    content2:
                      localization?.mali_isler_mudurlugu_detay_paragraph_2,
                    content3:
                      localization?.mali_isler_mudurlugu_detay_paragraph_3,
                    content4:
                      localization?.mali_isler_mudurlugu_detay_paragraph_4,
                    content5: "",
                    content6: "",
                    content7: "",
                    bottomTitle: "",
                    bottomTitle2: "",
                  }),
                children: [
                  {
                    subChild: true,
                    name: localization?.butce_raporlama_ve_finans_mudurlugu_isim,
                    title:
                      localization.butce_raporlama_ve_finans_mudurlugu_unvan,
                    photo:
                      localization.butce_raporlama_ve_finans_mudurlugu_resim,
                    notRedirect:
                      !!localization.butce_raporlama_ve_finans_mudurlugu_detay_baslik,

                    onClickItem: () =>
                      localization?.butce_raporlama_ve_finans_mudurlugu_detay_baslik &&
                      setDetailData({
                        id: 1,
                        title:
                          localization?.butce_raporlama_ve_finans_mudurlugu_detay_unvan,
                        name: localization?.butce_raporlama_ve_finans_mudurlugu_detay_baslik,
                        imgPath:
                          localization?.butce_raporlama_ve_finans_mudurlugu_detay_resim,
                        content1:
                          localization?.butce_raporlama_ve_finans_mudurlugu_detay_paragraph_1,
                        content2:
                          localization?.butce_raporlama_ve_finans_mudurlugu_detay_paragraph_2,
                        content3:
                          localization?.butce_raporlama_ve_finans_mudurlugu_detay_paragraph_3,
                        content4:
                          localization?.butce_raporlama_ve_finans_mudurlugu_detay_paragraph_4,
                        content5: "",
                        content6: "",
                        content7: "",
                        bottomTitle: "",
                        bottomTitle2: "",
                      }),
                    children: [
                      {
                        lastChild: true,
                        name: localization?.insan_kaynaklari_mudurlugu_isim,
                        title: localization.insan_kaynaklari_mudurlugu_unvan,
                        photo: localization.insan_kaynaklari_mudurlugu_resim,
                        notRedirect:
                          !!localization.insan_kaynaklari_mudurlugu_detay_baslik,

                        onClickItem: () =>
                          localization?.insan_kaynaklari_mudurlugu_detay_baslik &&
                          setDetailData({
                            id: 1,
                            title:
                              localization?.insan_kaynaklari_mudurlugu_detay_unvan,
                            name: localization?.insan_kaynaklari_mudurlugu_detay_baslik,
                            imgPath:
                              localization?.insan_kaynaklari_mudurlugu_detay_resim,
                            content1:
                              localization?.insan_kaynaklari_mudurlugu_detay_paragraph_1,
                            content2:
                              localization?.insan_kaynaklari_mudurlugu_detay_paragraph_2,
                            content3:
                              localization?.insan_kaynaklari_mudurlugu_detay_paragraph_3,
                            content4:
                              localization?.insan_kaynaklari_mudurlugu_detay_paragraph_4,
                            content5: "",
                            content6: "",
                            content7: "",
                            bottomTitle: "",
                            bottomTitle2: "",
                          }),
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
    const personel5 = [
      {
        parent: true,
        subChild: true,
        name: localization.saha_ve_kart_operasyonlari_gmy_isim,
        title: localization.saha_ve_kart_operasyonlari_gmy_unvan,
        photo: localization.saha_ve_kart_operasyonlari_gmy_resim,
        notRedirect: !!localization.saha_ve_kart_operasyonlari_gmy_detay_baslik,

        onClickItem: () =>
          setDetailData({
            id: 1,
            title: localization.saha_ve_kart_operasyonlari_gmy_detay_unvan,
            name: localization.saha_ve_kart_operasyonlari_gmy_detay_baslik,
            imgPath: localization.saha_ve_kart_operasyonlari_gmy_detay_resim,
            content1: localization.saha_ve_kart_operasyonlari_gmy_detay_prg1,
            content2: localization.saha_ve_kart_operasyonlari_gmy_detay_prg2,
            content3: localization.saha_ve_kart_operasyonlari_gmy_detay_prg3,
            content4: localization.saha_ve_kart_operasyonlari_gmy_detay_prg4,
            content5: "",
            content6: "",
            content7: "",
            bottomTitle: "",
            bottomTitle2: "",
          }),
        children: [
          {
            subChild: true,
            name: localization.terminal_mudurlugu_isim,
            title: localization.terminal_mudurlugu_unvan,
            photo: localization.terminal_mudurlugu_resim,
            notRedirect: !!localization.terminal_mudurlugu_detay_baslik,

            onClickItem: () =>
              setDetailData({
                id: 1,
                name: localization.terminal_mudurlugu_detay_baslik,
                title: localization.terminal_mudurlugu_detay_unvan,
                imgPath: localization.terminal_mudurlugu_detay_resim,
                content1: localization.terminal_mudurlugu_detay_prg1,
                content2: localization.terminal_mudurlugu_detay_prg2,
                content3: localization.terminal_mudurlugu_detay_prg3,
                content4: localization.terminal_mudurlugu_detay_prg4,
                content5: "",
                content6: "",
                content7: "",
                bottomTitle: "",
                bottomTitle2: "",
              }),
            children: [
              {
                lastChild: true,
                name: localization.saha_ve_kart_operasyonlari_mudurlugu_isim,
                title: localization.saha_ve_kart_operasyonlari_mudurlugu_unvan,
                photo: localization.saha_ve_kart_operasyonlari_mudurlugu_resim,
                notRedirect:
                  !!localization.saha_ve_kart_operasyonlari_mudurlugu_detay_baslik,

                onClickItem: () =>
                  setDetailData({
                    id: 1,
                    name: localization.saha_ve_kart_operasyonlari_mudurlugu_detay_baslik,
                    title:
                      localization.saha_ve_kart_operasyonlari_mudurlugu_detay_unvan,
                    imgPath:
                      localization.saha_ve_kart_operasyonlari_mudurlugu_detay_resim,
                    content1:
                      localization.saha_ve_kart_operasyonlari_mudurlugu_detay_prg1,
                    content2:
                      localization.saha_ve_kart_operasyonlari_mudurlugu_detay_prg2,
                    content3:
                      localization.saha_ve_kart_operasyonlari_mudurlugu_detay_prg3,
                    content4:
                      localization.saha_ve_kart_operasyonlari_mudurlugu_detay_prg4,
                    content5: "",
                    content6: "",
                    content7: "",
                    bottomTitle: "",
                    bottomTitle2: "",
                  }),
              },
            ],
          },
        ],
      },
    ];

    function renderPersonel(personel: any) {
      console.log(personel);
      return personel.map((item: any, index: any) => (
        <React.Fragment key={index}>
          <ItemComponent {...item} />
          {item.children && item.children.length > 0 && (
            <ul>{renderPersonel(item.children)}</ul>
          )}
        </React.Fragment>
      ));
    }
    return (
      <div id="organizationChart" className="pageContainer">
        <div className="tree">
          <ul className="treeList">
            <li>
              <div className="box1">
                <label>{localization?.yonetim_kurulu as string}</label>
              </div>
              <div className="line"></div>
              <ul>
                <li className="secondItem secondRow">
                  <ul style={{ paddingLeft: 230 }}>
                    {yonetim.map((yonetici, index) => (
                      <ItemComponent
                        key={yonetici.id}
                        {...yonetici}
                        parent={true}
                        style={{
                          marginTop: index == 1 && 130,
                          marginBottom: index == 1 && -75,
                        }}
                      />
                    ))}
                  </ul>
                </li>
              </ul>
              <ul>
                <li className="secondItem child-row">
                  <ul className="f1">
                    <li
                      className={`${personel2[0].children.length != 0 && ""}`}
                    >
                      {renderPersonel(personel2)}
                    </li>
                    <li
                      className={`${personel5[0].children.length != 0 && ""}`}
                    >
                      {renderPersonel(personel5)}
                    </li>
                    <li
                      className={`${personel3[0].children.length != 0 && ""}`}
                    >
                      {renderPersonel(personel3)}
                    </li>

                    <li
                      className={`${personel4[0].children.length != 0 && ""}`}
                    >
                      {renderPersonel(personel4)}
                    </li>
                    <li
                      className={`${personel1[0].children.length != 0 && ""}`}
                    >
                      {renderPersonel(personel1)}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
);
export default OrganizationChart;
