import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetOurRepresentativesModel } from "Models";
import { RootState } from "../index";
import { OurRepresentativesState } from "./types";

export const initialState: OurRepresentativesState = {
  loading: true,
  Representatives: undefined,
};

export const ourRepresentativesSlice = createSlice({
  name: "ourRepresentatives",
  initialState,
  reducers: {
    resetState: (state) => {
      return initialState; // state'i başlangıç durumuna sıfırla
    },
    onInit: (state) => {},
    ourRepresentativesRequest: (
      state: OurRepresentativesState,
      { payload }: PayloadAction<any>
    ) => {
      state.loading = true;
    },
    ourRepresentativesRequestSuccess: (
      state: OurRepresentativesState,
      action: PayloadAction<GetOurRepresentativesModel["Representatives"]>
    ) => {
      state.Representatives = action.payload;
      state.loading = false;
    },
    ourRepresentativesRequestFailed: (
      state: OurRepresentativesState,
      action: PayloadAction<any>
    ) => {
      state.Representatives = action.payload; 
      state.loading = false;
    },
  },
});

export const {
  resetState, // Dışarıya aktar
  onInit,
  ourRepresentativesRequest,
  ourRepresentativesRequestSuccess,
  ourRepresentativesRequestFailed,
} = ourRepresentativesSlice.actions;

export const OurRepresentativesStore = (state: RootState) =>
  state.ourRepresentatives;

export default ourRepresentativesSlice.reducer;
