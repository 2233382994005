import { routePath } from "Navigator/routes";
import { FunctionComponent } from "react";
import "./NewBox.scss";
import { useNavigate } from "react-router-dom";

type NewBoxProps = {
  title?: string;
  date?: string;
  image?: string;
  desc?: string;
  Id?: number | string;
};

const NewBox: FunctionComponent<NewBoxProps> = ({
  title,
  date,
  image,
  desc,
  Id,
}) => {
  const navigate = useNavigate();
  const descfilter = desc?.replace(/(<([^>]+)>)/gi, "");
  return (
    <div
      className="news__box"
      onClick={() => {
        navigate({
          pathname: routePath.newsDetail,
          search: `?id=${Id}`,
        });
      }}
    >
      <div className="news__box__image">
        <img src={image} alt={title} />
      </div>
      <div className="news__box__content">
        <span>{date}</span>
        <h3>{title}</h3>
        <p>{descfilter}</p>
      </div>
    </div>
  );
};

export default NewBox;
