import { PayloadAction } from "@reduxjs/toolkit";
import { GetOurRepresentativesModel } from "Models";
import { getOurRepresentatives } from "Services/Queries";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  ourRepresentativesRequest,
  ourRepresentativesRequestFailed,
  ourRepresentativesRequestSuccess,
} from "./index";

function* handleOurRepresentativesRequest({ payload }: PayloadAction<any>) {
  try {
    const getOurRepresentativesResult: GetOurRepresentativesModel = yield call(
      () => getOurRepresentatives({ payload })
    );
    //@ts-ignore
    yield put(
      ourRepresentativesRequestSuccess(
        //@ts-ignore
        getOurRepresentativesResult.data.CpMerchantDataList
      )
    );
  } catch (error) {
    yield put(ourRepresentativesRequestFailed(error))
  }
}

function* ourRepresentativesSaga() {
  yield takeLatest(ourRepresentativesRequest, handleOurRepresentativesRequest);
}

export default ourRepresentativesSaga;
