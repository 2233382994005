import { FunctionComponent } from "react";

const Left: FunctionComponent<React.SVGProps<SVGSVGElement>> = ({
  fill,
  width,
  height,
}: any) => {
  return (
    <svg
      width={width ?? "48"}
      height={height ?? "48"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_90_4346"
        maskUnits="userSpaceOnUse"
        x="16"
        y="10"
        width="16"
        height="28"
      >
        <path
          d="M28.5858 10.5858C29.3668 9.80474 30.6332 9.80474 31.4142 10.5858C32.1352 11.3068 32.1906 12.4412 31.5806 13.2258L31.4142 13.4142L20.83 24L31.4142 34.5858C32.1352 35.3068 32.1906 36.4412 31.5806 37.2258L31.4142 37.4142C30.6932 38.1352 29.5588 38.1906 28.7742 37.5806L28.5858 37.4142L16.5858 25.4142C15.8648 24.6932 15.8094 23.5588 16.4194 22.7742L16.5858 22.5858L28.5858 10.5858Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_90_4346)">
        <rect width={width ?? "48"} height={height ?? "48"} fill="white" />
      </g>
    </svg>
  );
};

export default Left;
