import { useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { RouteChangeTracker } from "../Components";
import { routes } from "./routes";
const RedirectToHome: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return null;
};
const Navigator = () => {
  const Wrapper = ({ children }: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return (
    <>
      <Wrapper>
        <Routes>
          {routes.map((item, key) => {
            return (
              <Route
                key={`page-${key}`}
                path={item.path}
                element={<item.component />}
              />
            );
          })}
          <Route path="*" element={<RedirectToHome />} />
        </Routes>
        <RouteChangeTracker />
      </Wrapper>
    </>
  );
};

export default Navigator;
