// @ts-nocheck
import { axiosHelper } from "../AxiosHelper";
import { GetCityListModel } from "Models";

const params = "/il";
const url = process.env.REACT_APP_ADDRESS_URL + params;
const getCityList = async (): Promise<GetCityListModel> => {
  return (
    await axiosHelper({
      method: "get",
      url,
    })
  ).data;
};

export default getCityList;
