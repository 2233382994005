import Grid from "@material-ui/core/Grid";
import { NewBox } from "Components";
import React from "react";
import "./News.scss";
import { useSelector } from "react-redux";
import { RootState } from "Store";
import { formatDate } from "Utils";
import { useLocalization } from "Hooks";

export interface IPage1Props {}

const News: React.FunctionComponent<IPage1Props> = (props) => {
  const localization = useLocalization();
  const { NewsList } = useSelector((state: RootState) => state.news);
  const arrayToSort = NewsList || [];
  const sortedAnnouncementList = arrayToSort
    .slice()
    .sort(function (a: any, b: any) {
      return (
        new Date(a.RelaseDate).valueOf() - new Date(b.RelaseDate).valueOf()
      );
    });
  return (
    <div id="news" className="pageContainer">
      <h1 className="news__title">{localization?.news_page_header}</h1>
      <div>
        <Grid container spacing={2}>
          {sortedAnnouncementList &&
            sortedAnnouncementList.reverse().map(
              (
                { HeadLine, WebCoverPictureUrl, Description, RelaseDate, Id },
                id: number
              ) => {
                return (
                  <Grid className="news__grid" item xs={12} md={4}>
                    <NewBox
                      Id={Id}
                      title={HeadLine}
                      desc={Description}
                      date={formatDate(RelaseDate)}
                      image={WebCoverPictureUrl}
                    />
                  </Grid>
                );
              }
            )}
        </Grid>
      </div>
    </div>
  );
};
export default News;
