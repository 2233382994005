import { FunctionComponent } from "react";

const ProfileBlue: FunctionComponent = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#1AAADC" fillOpacity="0.1" />
      <mask
        id="mask0_412_11170"
        maskUnits="userSpaceOnUse"
        x="18"
        y="14"
        width="28"
        height="36"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 22C40 26.4183 36.4183 30 32 30C27.5817 30 24 26.4183 24 22C24 17.5817 27.5817 14 32 14C36.4183 14 40 17.5817 40 22ZM46 48C46 49.1046 45.1046 50 44 50H20C18.8954 50 18 49.1046 18 48C18 40.268 24.268 34 32 34C39.732 34 46 40.268 46 48Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_412_11170)">
        <rect x="8" y="8" width="48" height="48" fill="#0080BC" />
      </g>
    </svg>
  );
};

export default ProfileBlue;
