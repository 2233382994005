import React from "react";
export interface IPage1Props {}

const Page1: React.FunctionComponent<IPage1Props> = (props) => {
  return (
    <div>
      <h1>Page1</h1>
    </div>
  );
};
export default Page1;
