import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetNewsModel } from "Models";
import { RootState } from "../index";
import { NewsState } from "./types";

export const initialState: NewsState = {
  loading: true,
  NewsList: undefined,
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    onInit: (state) => {},
    newsRequest: (state: NewsState) => {
      state.loading = true;
    },
    newsRequestSuccess: (
      state: NewsState,
      action: PayloadAction<GetNewsModel["NewsList"]>
    ) => {
      state.NewsList = action.payload;
      state.loading = false;
    },
  },
});

export const { onInit,  newsRequest, newsRequestSuccess } =
  newsSlice.actions;

export const newsStore = (state: RootState) => state.news;
export const storeNewsData = (state: RootState) => state.news.NewsList;

export default newsSlice.reducer;
