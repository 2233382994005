import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetTownListModel } from "Models";
import { RootState } from "../index";
import { TownListState } from "./types";

export const initialState: TownListState = {
  loading: true,
  TownList: undefined,
};

export const townListSlice = createSlice({
  name: "townList",
  initialState,
  reducers: {
    onInit: (state) => {},
    townListRequest: (
      state: TownListState,
      { payload }: PayloadAction<any>
    ) => {
      state.loading = true;
    },
    townListRequestSuccess: (
      state: TownListState,
      action: PayloadAction<GetTownListModel["TownList"]>
    ) => {
      state.TownList = action.payload;
      state.loading = false;
    },
  },
});

export const { onInit, townListRequest, townListRequestSuccess } =
  townListSlice.actions;

export const TownListStore = (state: RootState) => state.townList;

export default townListSlice.reducer;
