import { GetTownListModel } from "Models";
import { call, put, takeLatest } from "redux-saga/effects";
import { getTownList } from "Services/Queries";
import { townListRequest, townListRequestSuccess } from "./index";
import { PayloadAction } from "@reduxjs/toolkit";

function* handleTownListRequest({ payload }: PayloadAction<any>) {
  try {
    const getTownListResult: GetTownListModel["TownList"] = yield call(() =>
      getTownList(payload)
    );
    yield put(townListRequestSuccess(getTownListResult));
  } catch (error) {}
}

function* townListSaga() {
  yield takeLatest(townListRequest, handleTownListRequest);
}

export default townListSaga;
