import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { GetCmsModel } from "Models";
import { GlobalState } from "./types";
import languageId from "Utils/LanguageId";
import { localStorageKeys } from "Config";

const cmsKeys = localStorage.getItem("CmsKeys");

export const initialState: GlobalState = {
  loading: true,
  bireyselHizmetler: true,
  kurumsalHizmetler: false,
  euts: false,
  sosyalHizmetler: false,
  isDetailScreen: false,
  languageId: parseInt(languageId()) ?? 2,
  cmsData: cmsKeys
    ? JSON.parse(localStorage.getItem("CmsKeys") || "{}")
    : undefined,
  isLoadedCmsDataOnce: false,
  cmsDataForContactPage: ''
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    onInit: (state) => {},
    apiGWTokenRequest: (state: GlobalState) => {
      state.loading = true;
    },
    cmsRequest: (state: GlobalState) => {
      state.loading = true;
    },
    cmsRequestSuccess: (
      state: GlobalState,
      action: PayloadAction<GetCmsModel>
    ) => {
      state.cmsData = action.payload;
      state.loading = false;
      state.isLoadedCmsDataOnce = true;
    },
    cmsDataForContactPage:(
      state, action
    ) => {
      state.cmsDataForContactPage = action.payload;
      state.loading = false;
    },
    setBireyselHizmetler: (state, action) => {
      state.bireyselHizmetler = action.payload;
    },
    setKurumsalHizmetler: (state, action) => {
      state.kurumsalHizmetler = action.payload;
    },
    setEuts: (state, action) => {
      state.euts = action.payload;
    },
    setSosyalHizmetler: (state, action) => {
      state.sosyalHizmetler = action.payload;
    },
    setIsDetailScreen: (state, action) => {
      state.isDetailScreen = action.payload;
    },
    setLanguageId: (state: GlobalState, action: PayloadAction<number>) => {
      state.languageId = action.payload;
      localStorage.setItem(
        localStorageKeys.i18nextLng,
        action.payload == 1 ? "en" : "tr"
      );
    },
  },
});

export const {
  onInit,
  apiGWTokenRequest,
  cmsRequest,
  cmsRequestSuccess,
  setBireyselHizmetler,
  setKurumsalHizmetler,
  setEuts,
  setSosyalHizmetler,
  setIsDetailScreen,
  setLanguageId,
  cmsDataForContactPage
} = globalSlice.actions;

export const globalStore = (state: RootState) => state.global;
export const storeCmsData = (state: RootState) => state.global.cmsData;
export const contactCmsData = (state: RootState) => state.global.cmsDataForContactPage
export const selectLocalizationLoaded = (state: RootState) =>
  state.global.isLoadedCmsDataOnce;
export default globalSlice.reducer;
