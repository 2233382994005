import { FC, memo, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { SearchField, Button, ModalComp } from "Components";
import "./Faqs.scss";
import { ChevronDarkRightIcon, Email, ChevronDown, ChevronUp } from "Icons";
import { routePath } from "Navigator/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalization, useWindowDimensions } from "Hooks";
import Images from "Assets/Images";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store";
import { faqsRequest } from "Store/getFaqs";
import { FaqCategories, makeLink } from "Utils";

type FaqsProps = {
  title: string;
};

type FaqsItemType = {
  title: string;
  id: number;
  itemID: string;
  parentCategory: string;
};

type CuriositiesType = {
  title: string;
  selectedCategory: string;
  onClick: () => void;
  itemID?: string;
  isOpenOnClick?: () => void;
};

const Faqs: FC<FaqsProps> = memo((props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const localization = useLocalization();
  const { FaqList } = useSelector((state: RootState) => state.faqs);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [childCategory, setChildCategory] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory]: any = useState("");

  useEffect(() => {
    dispatch(faqsRequest(selectedCategory));
  }, [selectedCategory]);

  useEffect(() => {
    if (location.state?.category) {
      setSelectedCategory(location.state?.category);
    }
  }, [location.state?.category]);

  const filterData = (query: any, data: any) => {
    if (!query) {
      return data;
    } else {
      let nameObject = data.filter((item: any) => {
        return (
          item.Question.toLowerCase().includes(query.toLowerCase()) && item
        );
      });
      return nameObject;
    }
  };
  const dataFiltered = filterData(searchQuery, FaqList);

  return (
    <div id="faqs" className="pageContainer">
      <h1 className="faqs__title">{localization?.faq_page_header}</h1>
      <div className="faqs__container">
        <Box className="faqs__curiosities">
          <h5 className="faqs__curiosities__title">
            {localization?.faq_category_header}
          </h5>
          <CuriositiesItem
            title={FaqCategories.BIREYSEL_TEMSILCI}
            onClick={() => {}}
            selectedCategory={selectedCategory}
            isOpenOnClick={() => {
              setSelectedCategory(FaqCategories.BIREYSEL_TEMSILCI);
              dispatch(faqsRequest(FaqCategories.BIREYSEL_TEMSILCI));
            }}
          />
          <CuriositiesItem
            title={FaqCategories.KURUMSAL_TEMSILCI}
            onClick={() => {}}
            selectedCategory={selectedCategory}
            isOpenOnClick={() => {
              setSelectedCategory(FaqCategories.KURUMSAL_TEMSILCI);
              dispatch(faqsRequest(FaqCategories.KURUMSAL_TEMSILCI));
            }}
          />
          <CuriositiesItem
            title={FaqCategories.UYE_IS_YERI}
            onClick={() => {}}
            selectedCategory={selectedCategory}
            isOpenOnClick={() => {
              setSelectedCategory(FaqCategories.UYE_IS_YERI);
              dispatch(faqsRequest(FaqCategories.UYE_IS_YERI));
            }}
          />
          <CuriositiesItem
            title={FaqCategories.KURUMSAL_MUSTERİ}
            onClick={() => {}}
            selectedCategory={selectedCategory}
            isOpenOnClick={() => {
              setSelectedCategory(FaqCategories.KURUMSAL_MUSTERİ);
              dispatch(faqsRequest(FaqCategories.KURUMSAL_MUSTERİ));
            }}
          />
        </Box>
        <Box className="faqs__rightmenu">
          <SearchField
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeHolder={localization?.faq_search_desc}
            keyValue={"mask0_726_375faqs"}
            //onSearch={handleSearch}
          />
          <div style={{ height: 10 }} />
          <Box
            className="faqs__curiosities__mobile"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <Typography variant="h6" align="left">
              <Box
                fontWeight={700}
                className="faqs__curiosities__title"
                color={"#C3C4CB"}
              >
                {selectedCategory === ""
                  ? localization?.faq_category_header
                  : selectedCategory}
              </Box>
            </Typography>
            <div style={{ marginBottom: 12 }}>
              <ChevronDown fill="#C3C4CB" width={24} height={24} />
            </div>
          </Box>
          {dataFiltered &&
            dataFiltered.map((item: any, id: number) => (
              <FaqsItem
                id={id}
                title={item.Question}
                key={id}
                itemID={""}
                parentCategory={childCategory || item.Category.Value}
              />
            ))}
          {dataFiltered && dataFiltered.length === 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 50,
              }}
            >
              <img
                src={Images.SadEmoji}
                width={31}
                height={31}
                style={{ marginBottom: 20 }}
                alt={"Emoji"}
              />
              <Typography variant="h3" align="center">
                <Box fontWeight={600} className="faqs__title">
                  {localization?.faq_result_not_found}
                </Box>
              </Typography>
            </div>
          )}
          <Typography variant="h6" align="center">
            <Box
              fontWeight={450}
              fontSize={"16px"}
              className="faqs__rightmenu__answer"
              color={"#606375"}
              dangerouslySetInnerHTML={{
                __html: makeLink(localization?.faq_write_us_text_desc),
              }}
            ></Box>
          </Typography>
          <div className="faqs__rightmenu__buttonContainer">
            <Button
              text={localization?.faq_write_us_button_desc}
              onClick={(e) => {
                window.location.href = "mailto:B2B-info-test@belbim.com.tr";
                e.preventDefault();
              }}
              variant="outlined"
              className="faqs__rightmenu__button"
              // leftIcon={<Email />}
            />
          </div>
        </Box>
        <ModalComp
          modalIsOpen={modalIsOpen}
          setIsOpen={(val: any) => {
            setIsOpen(val);
          }}
          selectedCategory={(val: string) => {
            setSelectedCategory(val);
            dispatch(faqsRequest(val));
          }}
        />
      </div>
    </div>
  );
});

export default Faqs;

const FaqsItem: FC<FaqsItemType> = memo(({ title, itemID, id }) => {
  const navigate = useNavigate();
  return (
    <div
      className="faqs__item"
      onClick={() => {
        navigate({
          pathname: routePath.faqsDetail,
          search: `?id=${id}&parent=${title}`,
        });
      }}
      itemID={itemID}
    >
      <h6 className="faqs__item__title"> {title}</h6>
      {useWindowDimensions().width < 959 ? (
        <ChevronDarkRightIcon color="#383C52" />
      ) : (
        <ChevronDarkRightIcon />
      )}
    </div>
  );
});
const CuriositiesItem: FC<CuriositiesType> = memo(
  ({ title, onClick, itemID, selectedCategory, isOpenOnClick = () => {} }) => {
    const location = useLocation();

    return (
      <div
        className="faqs__curiosities__item"
        onClick={onClick}
        itemID={itemID}
      >
        <Typography
          className="faqs__curiosities__item__title"
          variant="h6"
          align="left"
        >
          <Box
            style={{ fontWeight: selectedCategory === title ? "700" : "450" }}
            fontSize={"16px"}
            color={"#383C52"}
            onClick={() => {
              isOpenOnClick();
            }}
          >
            {title}
          </Box>
        </Typography>
      </div>
    );
  }
);
