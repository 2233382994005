import { GetIstanbulkartFindContentModel } from "Models";
import { call, put, takeLatest } from "redux-saga/effects";
import { getIstanbulkartFindContent } from "Services/Queries";
import {
  istanbulkartFindContentRequest,
  istanbulkartFindContentRequestSuccess,
} from "./index";
import { PayloadAction } from "@reduxjs/toolkit";

function* handleIstanbulkartFindContentRequest({
  payload,
}: PayloadAction<any>) {
  try {
    const getIstanbulkartFindContentResult: GetIstanbulkartFindContentModel =
      yield call(() => getIstanbulkartFindContent({ payload }));

    yield put(
      istanbulkartFindContentRequestSuccess(
        getIstanbulkartFindContentResult.BelbimIstanbulSearchList
      )
    );
  } catch (error) {}
}

function* istanbulkartFindContentSaga() {
  yield takeLatest(
    istanbulkartFindContentRequest,
    handleIstanbulkartFindContentRequest
  );
}

export default istanbulkartFindContentSaga;
