import { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { ChevronDarkRightIcon } from "Icons";
import "./Items.scss";

export type ItemsType = {
  title: string;
  subTitle: string;
  Icon: any;
  func?: () => void;
  itemID: string;
  showArrow?: boolean;
};

const Items: FC<ItemsType> = ({
  title,
  subTitle,
  Icon,
  func,
  itemID,
  showArrow,
}) => (
  <Box id="item" onClick={func} itemID={itemID}>
    <Box className="item__icon" display="flex">
      <Icon fill="rgb(26,170,220)" />
    </Box>
    <div className="item__desc">
      <Typography variant="h5">{title}</Typography>
      <Typography variant="h6">{subTitle}</Typography>
    </div>
    {showArrow && (
      <Box display="flex" alignSelf="center" className="item__icon">
        <ChevronDarkRightIcon width={24} height={24} color="#004469" />
      </Box>
    )}
  </Box>
);

export default Items;
