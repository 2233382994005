import * as React from "react";

function DownloadIconBlue(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33765 16.7519C3.3348 16.7239 3.33334 16.6954 3.33334 16.6667V16.6667V15C3.33334 14.5398 3.70644 14.1667 4.16668 14.1667C4.62691 14.1667 5.00001 14.5398 5.00001 15V15.8333H15V15C15 14.5398 15.3731 14.1667 15.8333 14.1667C16.2936 14.1667 16.6667 14.5398 16.6667 15V16.6667C16.6667 16.6954 16.6652 16.7239 16.6624 16.7519C16.6197 17.1721 16.2648 17.5 15.8333 17.5H4.16668C4.02285 17.5 3.88754 17.4636 3.76946 17.3994C3.60702 17.3112 3.4772 17.1705 3.40272 17.0001C3.36893 16.9228 3.34653 16.8393 3.33765 16.7519ZM6.66668 9.16667C6.40438 9.16667 6.15739 9.29016 6.00001 9.5C5.8674 9.67681 5.81046 9.89906 5.84172 10.1179C5.87298 10.3366 5.98987 10.5341 6.16668 10.6667L9.50001 13.1667C9.79286 13.3807 10.1905 13.3807 10.4833 13.1667L13.8167 10.8167C14.1907 10.5513 14.2801 10.0337 14.0167 9.65833C13.8891 9.47629 13.6941 9.35276 13.475 9.3152C13.2559 9.27765 13.0309 9.32917 12.85 9.45833L10.832 10.8804C10.8329 10.8648 10.8333 10.8491 10.8333 10.8333V4.16667C10.8333 3.70643 10.4602 3.33333 10 3.33333C9.53977 3.33333 9.16668 3.70643 9.16668 4.16667V10.8333C9.16668 10.8353 9.16668 10.8373 9.1667 10.8392L7.16668 9.33333C7.02243 9.22515 6.84699 9.16667 6.66668 9.16667Z"
        fill="#1AAADC"
      />
      <mask
        id="mask0_287_17814"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="14"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33765 16.7519C3.3348 16.7239 3.33334 16.6954 3.33334 16.6667V16.6667V15C3.33334 14.5398 3.70644 14.1667 4.16668 14.1667C4.62691 14.1667 5.00001 14.5398 5.00001 15V15.8333H15V15C15 14.5398 15.3731 14.1667 15.8333 14.1667C16.2936 14.1667 16.6667 14.5398 16.6667 15V16.6667C16.6667 16.6954 16.6652 16.7239 16.6624 16.7519C16.6197 17.1721 16.2648 17.5 15.8333 17.5H4.16668C4.02285 17.5 3.88754 17.4636 3.76946 17.3994C3.60702 17.3112 3.4772 17.1705 3.40272 17.0001C3.36893 16.9228 3.34653 16.8393 3.33765 16.7519ZM6.66668 9.16667C6.40438 9.16667 6.15739 9.29016 6.00001 9.5C5.8674 9.67681 5.81046 9.89906 5.84172 10.1179C5.87298 10.3366 5.98987 10.5341 6.16668 10.6667L9.50001 13.1667C9.79286 13.3807 10.1905 13.3807 10.4833 13.1667L13.8167 10.8167C14.1907 10.5513 14.2801 10.0337 14.0167 9.65833C13.8891 9.47629 13.6941 9.35276 13.475 9.3152C13.2559 9.27765 13.0309 9.32917 12.85 9.45833L10.832 10.8804C10.8329 10.8648 10.8333 10.8491 10.8333 10.8333V4.16667C10.8333 3.70643 10.4602 3.33333 10 3.33333C9.53977 3.33333 9.16668 3.70643 9.16668 4.16667V10.8333C9.16668 10.8353 9.16668 10.8373 9.1667 10.8392L7.16668 9.33333C7.02243 9.22515 6.84699 9.16667 6.66668 9.16667Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_287_17814)">
        <rect
          width="20"
          height="20"
          transform="matrix(1 0 0 -1 0 20.8333)"
          fill="#1AAADC"
        />
        <rect
          width="20"
          height="20"
          transform="matrix(1 0 0 -1 0 20.8333)"
          fill="#1AAADC"
        />
      </g>
    </svg>
  );
}

export default DownloadIconBlue;
