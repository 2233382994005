import { FunctionComponent } from "react";

const Quotes: FunctionComponent = (props: React.SVGProps<SVGSVGElement>) => {
  const randomId =
    Math.floor(Math.random() * 1000) + "_" + Math.floor(Math.random() * 100);
  return (
    <svg
      width="31"
      height="26"
      viewBox="0 0 31 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3369 25.0859H0.0859375V17.0381C0.0859375 13.7816 0.366211 11.2191 0.926758 9.35059C1.514 7.4554 2.58171 5.76042 4.12988 4.26562C5.67806 2.77083 7.65332 1.59635 10.0557 0.742188L12.2578 5.38672C10.0156 6.13411 8.40072 7.17513 7.41309 8.50977C6.45215 9.8444 5.94499 11.6195 5.8916 13.835H11.3369V25.0859ZM19.4277 1.46289H30.6787V9.51074C30.6787 12.7673 30.3984 15.3431 29.8379 17.2383C29.2773 19.1068 28.2096 20.7884 26.6348 22.2832C25.0866 23.778 23.1113 24.9525 20.709 25.8066L18.5068 21.1621C20.7757 20.4147 22.3906 19.387 23.3516 18.0791C24.3125 16.7712 24.8197 14.9827 24.873 12.7139H19.4277V1.46289Z"
        fill="#43A9D6"
      />
    </svg>
  );
};

export default Quotes;
