import { ChevronDarkRightIcon, Notification } from "Icons";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import "./AnnouncementCard.scss";
import moment from "moment";
type AnnouncementCardProps = {
  title: string;
  subtitle: string;
  id: number;
  onClick: () => void;
  itemID: string;
};

const AnnouncementCard: FunctionComponent<AnnouncementCardProps> = ({
  title,
  subtitle,
  onClick,
  itemID,
}) => (
  <div onClick={onClick} className="announcementcard__container">
    <div className="icon">
      <Notification />
    </div>
    <div className="text">
      <h3>{title}</h3>
      <span>
        {moment(subtitle.includes("Z") ? subtitle : subtitle + "Z")
          .utc()
          .format("DD.MM.YYYY")}
      </span>
    </div>
    <div className="icon">
      <ChevronDarkRightIcon />
    </div>
  </div>
);

export default AnnouncementCard;
