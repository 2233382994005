import { FunctionComponent, useState } from "react";
import "./MaskInput.scss";
import { ErrorMessage } from "Components";
import InputMask from "react-input-mask";
export interface IInputProps {
  labelText: string;
  typeInput?: string;
  text?: string;
  onClick?: Function;
  backgroundColor?: string;
  value: string;
  onChangeText: Function;
  maxLength?: number;
  style?: object;
  isQuestionMark?: boolean;
  width?: number | string;
  errorMessage?: string | undefined | boolean;
  handleFocus?: Function;
  isTooltip?: boolean;
  onClickTooltip?: Function;
  onMouseOverTooltip?: Function;
  onMouseLeaveTooltip?: Function;
  openPopover?: boolean;
  marginLeft?: string | number;
  marginRight?: string | number;
  onKeyDown?: Function;
  height?: number | string;
  RightIcon?: any;
  onPressRightIcon?: Function;
  suffix?: string;
  handleBlur?: Function;
  maskType?: string;
}
const MaskInput: FunctionComponent<IInputProps> = ({
  labelText,
  text,
  backgroundColor,
  onClick,
  typeInput = "text",
  value,
  onChangeText,
  maxLength,
  style,
  isQuestionMark,
  width,
  errorMessage,
  isTooltip,
  onClickTooltip = () => {},
  onMouseOverTooltip = () => {},
  onMouseLeaveTooltip = () => {},
  openPopover,
  marginLeft,
  marginRight,
  onKeyDown = () => {},
  height,
  RightIcon,
  onPressRightIcon = () => {},
  suffix,
  handleBlur,
  handleFocus,
  maskType,
  ...props
}) => {
  const [_isFocused, setIsFocused] = useState(false);
  const _handleFocus = (e: any) => {
    if (!_isFocused) {
      setIsFocused(true);
      handleFocus && handleFocus(e);
    }
  };

  const _handleBlur = (e: any) => {
    if (_isFocused) {
      setIsFocused(false);
      handleBlur && handleBlur(e);
    }
  };
  return (
    <>
      <div
        className={`maskInput ${!!errorMessage ? "maskInput__error" : ""}`}
        style={{
          width: width,
          height: height,
        }}
      >
        <label className="maskInput__label">{labelText}</label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {suffix && <span className="maskInput__prefix">{suffix}</span>}
          <InputMask
            mask={maskType ?? "99.99.9999"}
            // placeholder="dd.mm.yyyy"
            className="maskInput__inputField"
            value={value}
            onChange={(event) => {
              onChangeText(event.target.value);
            }}
            max={maxLength}
            style={style}
            onFocus={(e) => _handleFocus(e)}
            onBlur={(e) => {
              _handleBlur(e);
            }}
          />
        </div>

        {RightIcon && (
          <div
            className="maskInput__close"
            onClick={() => {
              onPressRightIcon();
            }}
          >
            {RightIcon}
          </div>
        )}
      </div>

      {errorMessage ? (
        <ErrorMessage
          textStyle={{
            color: "#FF5757",
            fontFamily: "Mark Pro",
            marginBottom: 4,
            marginTop: 4,
            fontSize: 11,
          }}
          errorText={errorMessage}
        />
      ) : null}
    </>
  );
};

export default MaskInput;
