// @ts-nocheck
import { getTownListParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetTownListModel } from "Models";

const getTownList = async (
  params: getTownListParams
): Promise<GetTownListModel> => {
  const param = `/il/ilce/${params.ilKayitNo}`;
  const url = process.env.REACT_APP_ADDRESS_URL + param;
  return (
    await axiosHelper({
      method: "get",
      url,
    })
  ).data;
};

export default getTownList;
