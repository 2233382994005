import { FunctionComponent } from "react";
//import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import CreatableSelect from "react-select";
import "./SelectList.scss";
import { ErrorMessage } from "Components";

type SelectListProps = {
  selectedValue: object | string | number | any;
  data: any;
  placeholder: string;
  onChangeValue: Function;
  onDelete?: Function;
  errorMessage?: string | boolean;
};

const SelectList: FunctionComponent<SelectListProps> = ({
  selectedValue,
  data,
  placeholder,
  onChangeValue,
  onDelete = () => {},
  errorMessage,
  ...props
}) => {
  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "white",
      borderColor: "#c9c7c7",
      borderRadius: 12,
      padding: 7,
      paddingLeft: 4,
      fontSize: 12,
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
      };
    },
  };
  return (
    <>
      <CreatableSelect
        className={`selectList ${!!errorMessage ? "selectList__error" : ""}`}
        classNamePrefix="selectList"
        styles={colourStyles}
        placeholder={placeholder}
        defaultValue={0}
        value={selectedValue}
        name={placeholder}
        options={data}
        isClearable
        onChange={(val: any) => {
          onChangeValue(val);
        }}
      />
      {errorMessage ? (
        <ErrorMessage
          textStyle={{
            color: "#FF5757",
            fontFamily: "Mark Pro",
            marginBottom: 4,
            marginTop: 4,
            fontSize: 11,
          }}
          errorText={errorMessage}
        />
      ) : null}
    </>
  );
};

export default SelectList;
