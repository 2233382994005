import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
const RouteChangeTracker = () => {
  const location = useLocation();
  ReactGA.set({ page: location.pathname });
  ReactGA.send(location.pathname);

  return <div></div>;
};

export default RouteChangeTracker;
