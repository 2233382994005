import React, { useEffect, useState } from "react";
import "./AnnouncementPopup.scss";
import { useLocalization } from "../../Hooks";

interface ILocalization {
    belbim_announcement_image?: string;
    belbim_announcement_link?: string;
    belbim_announcement_time?: string;
    belbim_announcement_exit_button?: string;
}

function shouldShowPopup(localization: ILocalization) {
    return localization?.belbim_announcement_image;
}

function addProtocol(url: string) {
    const protocolRegExp = /^https?:\/\//i;
    if (!protocolRegExp.test(url)) {
        return "https://" + url;
    }
    return url;
}

export interface IAnnouncementPopupProps {
    header: string;
    description: string;
    image: string;
    link: string;
    time: number;
    onClose: () => void;
}

const AnnouncementPopup: React.FunctionComponent<IAnnouncementPopupProps> = ({
                                                                                 header,
                                                                                 description,
                                                                                 image,
                                                                                 link,
                                                                                 time,
                                                                                 onClose,
                                                                             }) => {
    const localization = useLocalization() as ILocalization;
    const [closing, setClosing] = useState(false);

    const handleClose = () => {
        setClosing(true);
        setTimeout(() => {
            onClose();
        }, 300);
    };


    useEffect(() => {
        if (localization?.belbim_announcement_time) {
            const timeInMilliseconds =
                parseInt(localization.belbim_announcement_time, 10) * 1000;
            if (!isNaN(timeInMilliseconds) && timeInMilliseconds > 0) {
                const timer = setTimeout(() => {
                    handleClose();
                }, timeInMilliseconds);
                return () => clearTimeout(timer);
            }
        }
    }, [localization, onClose]);


    if (!shouldShowPopup(localization)) {
        return null;
    }

    const announcementLink = localization?.belbim_announcement_link
        ? addProtocol(localization.belbim_announcement_link)
        : "";
    const handleImageClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleClose();
    };

    if (!shouldShowPopup(localization)) {
        return null;
    }

    return (
        <div>
            <div
                className={`announcement-popup ${
                    closing ? "announcement-popup--closing" : ""
                }`}
                onClick={handleImageClick}
            >
                <a href={announcementLink} target="_blank" rel="noopener noreferrer">
                    <img src={localization.belbim_announcement_image} />
                </a>
                <button
                    className="announcement-popup__close"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                    }}
                >
                    {localization?.belbim_announcement_exit_button}
                </button>
            </div>
        </div>
    );

};

export default AnnouncementPopup;
