export enum FaqCategories {
  BIREYSEL_TEMSILCI = "Bireysel Temsilci",
  KURUMSAL_TEMSILCI = "Kurumsal Temsilci",
  UYE_IS_YERI = "Üye İş Yeri",
  KURUMSAL_MUSTERİ = "Kurumsal Müşteri",
}

export enum SearchCategory {
  NEWS = "News",
  WEB_SLIDER = "WebSlider",
  FAQ = "FAQ",
  ANNOUNCEMENTS = "Announcements",
  SECURITYPAGECONTENT= "SecurityPageContent",
  PRODUCTSANDSERVICESCONTENT= "ProductsAndServicesContent",
  
}
