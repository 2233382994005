import { Box, Typography } from "@material-ui/core";
import Images from "Assets/Images";
import { useLocalization, useWindowDimensions } from "Hooks";
import SectionTab from "Pages/ProcessingPoints/SectionTab";
import { RootState } from "Store";
import { CompanyJourneyRequest } from "Store/getBelbimCompanyJourney";
import { makeLink } from "Utils";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwiperCore, { A11y, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { OrganizationChart } from "../OrganizationChart";
import { PersonalDetail } from "../PersonalDetail";
import TimeLine from "../TimeLine/TimeLine";
import "./AboutAs.scss";
export interface IInstitutionalProps {
  activeTab: string;
}

const AboutAs: React.FunctionComponent<IInstitutionalProps> = (props) => {
  const activeTab = props.activeTab;
  const localization = useLocalization();
  const [isDetail, setIsDetail] = useState(false);
  const [data, setData] = useState({});
  const [hakkinda, setHakkinda] = useState(activeTab === "1");
  const [selectedTimeLineIndex, setSelectedTimeLineIndex] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(CompanyJourneyRequest());
  }, []);
  const [vizyonmisyon, setVizyonMisyon] = useState(activeTab === "2");
  const CompanyJourneyList = useSelector(
    (store: RootState) => store.getBelbimCompanyJourney
  );
  const [organizasyonSemasi, setOrganizasyonSemasi] = useState(
    activeTab === "3"
  );

  const slidesPerView = useWindowDimensions().width > 900 ? 2 : 1;
  const swiperRef = useRef<SwiperCore | null>(null);
  const content =
    localization?.institutional_about_us_tab_vission_and_mission_missions_list_1;
  const content2 =
    localization?.institutional_about_us_tab_vission_and_mission_missions_list_2;
  const reversedJourneyList = [...CompanyJourneyList?.CompanyJourneyList]?.sort(
    (a: any, b: any) => b.Year - a.Year
  );
  return (
    <div id="aboutas" className="pageContainer">
      <div style={{ display: "flex", flexDirection: "row", marginTop: 33 }}>
        <SectionTab
          text={localization?.institutional_about_us_tab_about}
          isActive={hakkinda}
          onPress={() => {
            setHakkinda(true);
            setVizyonMisyon(false);
            setOrganizasyonSemasi(false);
          }}
        />
        <SectionTab
          text={localization?.institutional_about_us_tab_vision_and_mission}
          isActive={vizyonmisyon}
          onPress={() => {
            setHakkinda(false);
            setVizyonMisyon(true);
            setOrganizasyonSemasi(false);
          }}
        />
        <SectionTab
          text={localization?.institutional_about_us_tab_organization_chart}
          isActive={organizasyonSemasi}
          onPress={() => {
            setHakkinda(false);
            setVizyonMisyon(false);
            setOrganizasyonSemasi(true);
            setIsDetail(false);
          }}
        />
      </div>
      {hakkinda ? (
        <div>
          <Typography variant="h2" align="left">
            <Box fontWeight={700} className="aboutas__title">
              {localization?.institutional_about_us_tab_about_header}
            </Box>
          </Typography>
          <p
            className="aboutas__content"
            dangerouslySetInnerHTML={{
              __html: makeLink(
                localization?.institutional_about_us_tab_about_desc_paragraph_1
              ),
            }}
          ></p>
          <p
            className="aboutas__content"
            dangerouslySetInnerHTML={{
              __html: makeLink(
                localization?.institutional_about_us_tab_about_desc_paragraph_2
              ),
            }}
          ></p>
          <p
            className="aboutas__content"
            dangerouslySetInnerHTML={{
              __html: makeLink(
                localization?.institutional_about_us_tab_about_desc_paragraph_3
              ),
            }}
          ></p>
          <Typography variant="h5" align="center">
            <Box fontWeight={700} className="aboutas__map">
              {localization?.institutional_company_journey_header}
            </Box>
          </Typography>
          <Swiper
            modules={[Pagination, Navigation, A11y]}
            slidesPerView={slidesPerView}
            navigation={true}
            onSlideChange={(swiper) => {
              setSelectedTimeLineIndex(swiper.activeIndex);
            }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            className="company_journey__slider"
          >
            {reversedJourneyList.map(
              ({ Title, Year, Description }: any): any => (
                <SwiperSlide className="aboutas__item">
                  <div>
                    <h4 className="aboutas__item__title">{Year}</h4>
                    <h6 className="aboutas__item__subTitle"> {Title}</h6>
                    <p
                      className="aboutas__item__content"
                      dangerouslySetInnerHTML={{
                        __html: makeLink(Description),
                      }}
                    ></p>
                  </div>
                  {Title ? (
                    <div
                      className="aboutas__verticalline"
                      style={{
                        height: 330,
                        width: 1,
                        backgroundColor: "#DEE4E6",
                        marginRight: 20,
                        marginLeft: 20,
                        marginTop: 80,
                      }}
                    />
                  ) : null}
                </SwiperSlide>
              )
            )}
            <div className="company_journey__slider__mask">
              <img src={Images.CompanyJourney} />
            </div>
          </Swiper>
          <div className="aboutas__timeline">
            <div style={{ height: 130 }} />
            <TimeLine
              events={reversedJourneyList}
              selectedIndex={selectedTimeLineIndex}
              onPress={(yearIndex: number) => {
                if (swiperRef.current) {
                  setSelectedTimeLineIndex(yearIndex);
                  swiperRef.current.slideTo(yearIndex, 500);
                }
              }}
            />
          </div>
        </div>
      ) : vizyonmisyon ? (
        <div>
          <Typography variant="h2" align="left">
            <Box fontWeight={700} className="aboutas__title">
              {
                localization?.institutional_about_us_tab_vission_and_mission_header
              }
            </Box>
          </Typography>
          <Typography variant="h5" align="left">
            <Box fontWeight={700} className="aboutas__subTitle">
              {
                localization?.institutional_about_us_tab_vission_and_mission_vission_subheader
              }
            </Box>
          </Typography>
          <p
            className="aboutas__content"
            dangerouslySetInnerHTML={{
              __html: makeLink(
                localization?.institutional_about_us_tab_vission_and_mission_vission_desc
              ),
            }}
          ></p>
          <Typography variant="h5" align="left">
            <Box fontWeight={700} className="aboutas__subTitle">
              {
                localization?.institutional_about_us_tab_vission_and_mission_mission_subheader
              }
            </Box>
          </Typography>
          <p
            className="aboutas__content"
            dangerouslySetInnerHTML={{
              __html: makeLink(
                localization?.institutional_about_us_tab_vission_and_mission_desc
              ),
            }}
          ></p>
          <Typography variant="h5" align="left">
            <Box fontWeight={700} className="aboutas__subTitle">
              {
                localization?.institutional_about_us_tab_vission_and_mission_missions_subheader
              }
            </Box>
          </Typography>
          <div style={{ display: "flex", flexDirection: "row", gap: "13px" }}>
            <div>
              <p
                className="aboutas__content"
                dangerouslySetInnerHTML={{ __html: content ? content : "" }}
              ></p>
              <p
                className="aboutas__content"
                style={{ marginTop: 30 }}
                dangerouslySetInnerHTML={{ __html: content2 ? content2 : "" }}
              ></p>
            </div>
            <div></div>
          </div>
        </div>
      ) : !isDetail && organizasyonSemasi ? (
        <OrganizationChart
          detailData={{}}
          setDetailData={(val: any) => {
            setIsDetail(true);
            setData(val);
            //  setOrganizasyonSemasi(false);
            window.scrollTo({ top: 0 });
          }}
        />
      ) : (
        isDetail && (
          <PersonalDetail
            data={data}
            isOrganization={true}
            onClickBack={() => {
              setIsDetail(false);
              setHakkinda(false);
              setVizyonMisyon(false);
              setOrganizasyonSemasi(true);
            }}
          />
        )
      )}
    </div>
  );
};
export default AboutAs;
