import * as React from "react";

function LinkedInGrayIcon(
  props: React.SVGProps<SVGSVGElement> & { svgStyle?: any }
) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.svgStyle}
    >
      <circle cx="12" cy="12.58" r="12" fill={props.color ?? "#888A97"} />
      <mask
        id="mask0_1322_1746"
        maskUnits="userSpaceOnUse"
        x={props.x || "7"}
        y={props.y || "7"}
        width="11"
        height="11"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.2178 10.0158C8.5408 10.0158 8.8506 9.88746 9.079 9.65906C9.3074 9.43066 9.4357 9.12086 9.4357 8.79786C9.4357 8.47486 9.3074 8.16506 9.079 7.93676C8.8506 7.70836 8.5408 7.57996 8.2178 7.57996C7.8948 7.57996 7.5851 7.70836 7.3567 7.93676C7.1283 8.16506 7 8.47486 7 8.79786C7 9.12086 7.1283 9.43066 7.3567 9.65906C7.5851 9.88746 7.8948 10.0158 8.2178 10.0158ZM9.249 10.8845H7.1989V17.5016H9.249V10.8845ZM13.7754 12.5449C12.9553 12.5449 12.525 13.0969 12.525 14.0266L12.5128 17.5015H10.548V10.8845H12.5128V11.7776C12.7223 11.4384 13.0159 11.1593 13.3652 10.9672C13.7145 10.7751 14.1075 10.6767 14.5061 10.6815C15.9107 10.6815 16.9174 11.5381 16.9174 13.3121V17.5015H14.8877V14.0266C14.8877 13.0766 14.5264 12.5449 13.7754 12.5449Z"
          fill="white"
          style={props.style}
        />
      </mask>
      <g mask="url(#mask0_1322_1746)">
        <rect y="0.579956" width="25" height="25" fill="white" />
      </g>
    </svg>
  );
}

export default LinkedInGrayIcon;
