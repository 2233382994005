import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetCollaborationsModel } from "Models";
import { RootState } from "../index";
import { CollaborationsState } from "./types";

export const initialState: CollaborationsState = {
  loading: true,
  References: undefined,
};

export const collaborationsSlice = createSlice({
  name: "collaborations",
  initialState,
  reducers: {
    onInit: (state) => {},
    collaborationsRequest: (state: CollaborationsState) => {
      state.loading = true;
    },
    collaborationsRequestSuccess: (
      state: CollaborationsState,
      action: PayloadAction<GetCollaborationsModel["References"]>
    ) => {
      state.References = action.payload;
      state.loading = false;
    },
  },
});

export const { onInit, collaborationsRequest, collaborationsRequestSuccess } =
  collaborationsSlice.actions;

export const CollaborationsStore = (state: RootState) => state.collaborations;

export default collaborationsSlice.reducer;
