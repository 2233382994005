import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "Store";
import { ProductAndService as fetchProductAndService } from "Store/getProductsAndServices";
import Images from "Assets/Images";
import "./ProductsAndServices.scss";
import { Category, ProductAndService } from "Models/ProductsAndServices";
import { TabBox } from "./TabBox";
import { DetailScreen } from "./DetailScreen";

export interface InstitutionalProps {}

const ProductsAndServices: React.FC<InstitutionalProps> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id, tab, subTab } = location?.state || {};

  const getProductsAndServices = useSelector(
    (state: RootState) => state.getProductsAndServices
  );
  const [selectedTab, setSelectedTab] = useState<any | null>();
  const [selectedProduct, setSelectedProduct] =
    useState<ProductAndService | null>(null);

  useEffect(() => {
    dispatch(fetchProductAndService(""));
  }, [dispatch]);
  console.log(selectedTab);
  useEffect(() => {
    if (tab) {
      setSelectedTab({ Id: tab });
    }
    else if (getProductsAndServices.Categories[0]) {
      setSelectedTab(getProductsAndServices.Categories[0]);
    }
  }, [getProductsAndServices.Categories.length]);

  const handleTabPress = useCallback((item: Category) => {
    setSelectedTab(item);
    setSelectedProduct(null);
    window.scrollTo({ top: 0 });
  }, []);

  const filteredProducts = useMemo(() => {
    return getProductsAndServices.ProductAndService?.filter(
      (product: any) => product?.Category.Id == selectedTab?.Id
    );
  }, [getProductsAndServices.ProductAndService, selectedTab]);

  const handleProductSelect = useCallback((product: ProductAndService) => {
    setSelectedProduct(product);
    window.scrollTo({ top: 0 });
  }, []);

  const handleBackClick = () => {
    setSelectedTab(selectedProduct?.Category);
    setSelectedProduct(null);
  };
  return (
    <div id="productsAndServices" className="pageContainer">
      {!selectedProduct && (
        <div className="productsAndServices__category-list">
          {getProductsAndServices.Categories?.map(
            (item: Category, index: number) => (
              <TabBox
                key={index}
                text={item?.Value}
                isActive={selectedTab?.Id == item.Id}
                onPress={() => handleTabPress(item)}
                photoPath={Images.GenelBaskanImg}
              />
            )
          )}
        </div>
      )}
      {!selectedProduct && (
        <div
          style={{ display: "flex", flexWrap: "wrap", marginTop: 55, gap: 16 }}
        >
          {filteredProducts?.map((item: any, index: number) => (
            <div key={index} onClick={() => handleProductSelect(item)}>
              <img
                src={item.CoverImageUrl}
                alt=""
                className="productsAndServices__image"
              />
            </div>
          ))}
        </div>
      )}
      {selectedProduct && (
        <img
          className="personalDetail__backButton"
          height="100%"
          src={Images.Back}
          onClick={handleBackClick}
        />
      )}
      {selectedProduct && <DetailScreen data={selectedProduct} />}
    </div>
  );
};

export default ProductsAndServices;
