import { FunctionComponent } from "react";
import Modal from "react-modal";
import { Box, Typography } from "@material-ui/core";
import { FaqCategories } from "Utils";
import { useLocalization } from "Hooks";

const customStyles = {
  content: {
    display: "fixed",
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 23,
    width: "70%",
    height: "400px",
    backgroundColor: "white",
    paddingTop: "16px",
    paddingBottom: "16px",
    zIndex: 999,
  },
};
type ModalCompProps = {
  modalIsOpen?: any;
  setIsOpen?: any;
  selectedCategory?: Function;
};

Modal.setAppElement(document.getElementById("root")!);

const ModalComp: FunctionComponent<ModalCompProps> = ({
  modalIsOpen,
  setIsOpen,
  selectedCategory = () => {},
}) => {
  const localization = useLocalization();
  function closeModal() {
    setIsOpen(false);
  }
  const menuList = [
    { id: 1, title: FaqCategories.BIREYSEL_TEMSILCI },
    { id: 2, title: FaqCategories.KURUMSAL_TEMSILCI },
    { id: 3, title: FaqCategories.UYE_IS_YERI },
    { id: 4, title: FaqCategories.KURUMSAL_MUSTERİ },
  ];
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Typography variant="h6" align="center">
          <Box fontWeight={700} className="faqs__curiosities__title">
            {localization?.faq_category_header}
          </Box>
        </Typography>
        {menuList.map((item: any, i: number) => {
          return (
            <>
              <div
                style={{
                  height: 2,
                  backgroundColor: "#F5F5F6",
                  marginTop: 13,
                  marginBottom: 13,
                }}
              />
              <Typography variant="h6" align="left">
                <Box
                  fontWeight={700}
                  className="faqs__curiosities__title"
                  onClick={() => {
                    closeModal();
                    selectedCategory(item?.title ?? "");
                  }}
                >
                  {item?.title}
                </Box>
              </Typography>
            </>
          );
        })}
      </Modal>
    </div>
  );
};
export default ModalComp;
