import React from "react";
import "./CardNumberInfoPopup.scss";
import { useLocalization } from "Hooks";
import { Close } from "Icons";
import { makeLink } from "Utils";
import Images from "Assets/Images";
import classnames from "classnames";

export interface ICardNumberInfoPopupProps {
  setOpenModal?: Function;
  setSelectedTab?: Function;
  selectedTab?: string;
  openModal?: boolean;
}

const CardNumberInfoPopup: React.FunctionComponent<
  ICardNumberInfoPopupProps
> = ({
  selectedTab,
  openModal,
  setSelectedTab = () => {},
  setOpenModal = () => {},
}) => {
  const localization = useLocalization();
  return (
    <div className="cardnumberinfo-popup">
      <div
        className="cardnumberinfo-popup__close"
        onClick={() => {
          setOpenModal(false);
        }}
      >
        <Close />
      </div>
      <h4 className="cardnumberinfo-popup__title">
        {
          localization?.online_transactions_personalization_form_card_tooltip_popup_header
        }
      </h4>
      <p
        className="cardnumberinfo-popup__content"
        dangerouslySetInnerHTML={{
          __html: makeLink(
            localization?.online_transactions_personalization_form_card_tooltip_popup_desc1
          ),
        }}
      ></p>
      <p
        className="cardnumberinfo-popup__content"
        dangerouslySetInnerHTML={{
          __html: makeLink(
            localization?.online_transactions_personalization_form_card_tooltip_popup_desc2
          ),
        }}
      ></p>
      <div className="cardnumberinfo-popup__cardContainer"> 
        <div className="cardnumberinfo-popup__tabContainer">
          <div
            onClick={() => {
              setSelectedTab("New");
            }}
          >
            <h6
              className={classnames({
                "cardnumberinfo-popup__selectTabText":
                  selectedTab === "New" ? true : false,
                "cardnumberinfo-popup__unselectTabText":
                  selectedTab === "New" ? false : true,
              })}
            >
              {
                localization?.online_transactions_personalization_form_card_tooltip_popup_new_card
              }
            </h6>
          </div>
          <div
            onClick={() => {
              setSelectedTab("Old");
            }}
          >
            <h6
              className={classnames({
                "cardnumberinfo-popup__selectTabText":
                  selectedTab === "New" ? false : true,
                "cardnumberinfo-popup__unselectTabText":
                  selectedTab === "New" ? true : false,
              })}
            >
              {
                localization?.online_transactions_personalization_form_card_tooltip_popup_old_card
              }
            </h6>
          </div>
        </div>
        <img
          className="cardnumberinfo-popup__img"
          src={
            selectedTab === "New"
              ? Images.IstanbulkartYeniGorsel
              : Images.IstanbulkartEskiGorsel
          }
          alt={
            localization?.online_transactions_personalization_form_card_tooltip_popup_old_card
          }
        />
      </div>
    </div>
  );
};

export default CardNumberInfoPopup;
