import { FunctionComponent } from "react";
import { Box, Typography } from "@material-ui/core";
import "./OurRepresentatives.scss";
import { Pagination, Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import { useSelector } from "react-redux";
import { RootState } from "Store";
import { useLocalization } from "Hooks";

type OurRepresentativesProps = {};

const OurRepresentatives: FunctionComponent<OurRepresentativesProps> = ({}) => {
  const localization = useLocalization();
  const { References } = useSelector(
    (state: RootState) => state.collaborations
  );
  return (
    <div id="ourRepresentatives" className="pageContainer">
      <Typography variant="h5" align="center">
        <Box fontWeight={700} className="ourRepresentatives__title">
          {
            localization?.trading_points_ind_rep_apply_representer_comments_header
          }
        </Box>
      </Typography>
      <div className="ourRepresentatives__representatives">
        <Swiper
          modules={[Pagination, Navigation, A11y]}
          slidesPerView={1}
          spaceBetween={50}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
          className="pageContainer"
          navigation
          loop
        >
          {References &&
            References.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      width={128}
                      height={128}
                      src={item?.Logo}
                      alt={item?.Title}
                    />

                    <Typography variant="h5" align="center">
                      <Box
                        fontWeight={700}
                        className="ourRepresentatives__representatives__title"
                      >
                        {item?.Title}
                      </Box>
                    </Typography>
                    <p className="ourRepresentatives__representatives__content">
                      {item?.Description}
                    </p>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export default OurRepresentatives;
