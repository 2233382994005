import { FC, memo } from "react";
import { Box } from "@material-ui/core";
import "./Announcements.scss";
import { ChevronDarkRightIcon, Notification } from "Icons";
import { routePath } from "Navigator/routes";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "Store";
import moment from "moment";
import { useLocalization } from "Hooks";

type AnnouncementsProps = {
  title: string;
};

type AnnouncementstItemType = {
  title: string;
  subtitle: string;
  id: number;
  onClick: () => void;
  itemID: string;
};

const Announcements: FC<AnnouncementsProps> = memo((props) => {
  const navigate = useNavigate();
  const localization = useLocalization();
  const { AnnouncementList } = useSelector(
    (state: RootState) => state.announcements
  );
  const arrayToSort = AnnouncementList || [];
  const sortedAnnouncementList = arrayToSort
    .slice()
    .sort(function (a: any, b: any) {
      return new Date(a.Date).valueOf() - new Date(b.Date).valueOf();
    });
  return (
    <div id="announcements" className="pageContainer">
      <h1 className="announcements__item__header">{localization?.announcements_page_header}</h1>
      {sortedAnnouncementList &&
        sortedAnnouncementList
          .reverse()
          .map(({ Header, Description, Id, Date }, i) => (
            <AnnouncementsItem
              id={i}
              title={Header}
              subtitle={Date}
              onClick={() => {
                navigate({
                  pathname: routePath.announcementsDetail,
                  search: `?id=${Id}`,
                });
              }}
              key={i}
              itemID={"announcements2BtnTest"}
            />
          ))}
    </div>
  );
});

export default Announcements;

const AnnouncementsItem: FC<AnnouncementstItemType> = memo(
  ({ title, subtitle, onClick, itemID }) => {
    return (
      <div className="announcements__item" onClick={onClick} itemID={itemID}>
        <Notification />
        <Box className="announcements__item__text">
          <h5 className="announcements__item__title">{title}</h5>
          <p className="announcements__item__date">
            {moment(subtitle.includes("Z") ? subtitle : subtitle + "Z")
              .utc()
              .format("DD.MM.YYYY")}
          </p>
        </Box>
        <ChevronDarkRightIcon />
      </div>
    );
  }
);
