// @ts-nocheck
import { getCheckParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { ApproveKVKKAgreementDemoModel } from "./../../Models";

const url =
  "https://testadp.belbim.istanbul/EparaAdapter/api/RestAdapter/ApproveKVKKAgreementDemo";
const approveKVKKAgreement = async ({
  kvkkAgreementVersion,
  cardNumber,
  userSMSCode,
  birthday,
  token,
  commercialMessageStatus,
}: getCheckParams): Promise<ApproveKVKKAgreementDemoModel.request> => {
 
  return (
    await axiosHelper({
        headers: {
            "Access-Control-Allow-Origin": "*",
        },
      method: "post",
      url,
      data: {
        kvkkAgreementVersion,
        cardNumber,
        userSMSCode,
        birthday,
        token,
        commercialMessageStatus,
      },
    })
  ).data;
};

export default approveKVKKAgreement;
