import { DownloadIconBlue } from "Icons";
import { FunctionComponent } from "react";
import "./ErrorMessage.scss";

type ErrorMessaageProps = {
  errorText: string | boolean;
  style?: any;
  textStyle?: any;
};

const ErrorMessaage: FunctionComponent<ErrorMessaageProps> = ({
  errorText,
  style,
  textStyle,
}) => {
  return (
    <div className="errormessage" style={style}>
      <h4 className="errormessage__errorText" style={textStyle}>{errorText}</h4>
    </div>
  );
};

export default ErrorMessaage;
