import { PayloadAction } from "@reduxjs/toolkit";
import GetProductsAndServicesModel from "Models/ProductsAndServices";
import { getProductsAndServices } from "Services/Queries";
import { languageId } from "Utils";
import { call, put, takeLatest } from "redux-saga/effects";
import { ProductAndService, ProductAndServiceSuccess } from "./index";

function* handleProductsAndServices({}: PayloadAction<any>) {
  try {
    const getProductsAndService: GetProductsAndServicesModel = yield call(() =>
      getProductsAndServices({ languageId: languageId() })
    );
    yield put(ProductAndServiceSuccess(getProductsAndService));
  } catch (error) {}
}

function* ProductsAndServicesSaga() {
  yield takeLatest(ProductAndService, handleProductsAndServices);
}

export default ProductsAndServicesSaga;
