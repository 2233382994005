import { Grid } from "@material-ui/core";
import { FC } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { routePath } from "Navigator/routes";
import { faqsRequest } from "Store/getFaqs";
import { useDispatch } from "react-redux";
import { useLocalization } from "Hooks";
import Images from "Assets/Images";
import { LinkedInGrayIcon } from "Icons";

type FooterProps = {};

const Footer: FC<FooterProps> = () => {
  const dispatch = useDispatch();
  const localization = useLocalization();
  const showVersion = process.env.REACT_APP_SHOW_VERSION === "true";

  return (
    <footer id="app__footer">
      <div className="pageContainer">
        <Grid container>
          <Grid item md={4}>
            <div className="footer__row1">
              <img src={Images.IbbLogo} width="160" height="160" />
              <img src={Images.BelbimLogo} width={160} />
            </div>
            <div className="footer__row1">
              <img className="tcmb__img" width="160" src={Images.Tcmb} />
              <img className="bkm__img" width="160" src={Images.BKMLogo} />
            </div>
            <div className="copyright">
              <p> {localization?.footer_belbim_rights_desc}</p>
            </div>
          </Grid>
          <Grid item md={1} xs={12}></Grid>
          <Grid item md={3} xs={12}>
            <ul className="app__footer__link__list">
              <li>
                <Link to={routePath.legalText}>
                  {localization?.belbim_footer_legal_texts}
                </Link>
              </li>
              <li>
                <a
                  href={"https://www.belgemodul.com/sirket/1480"}
                  target="_blank"
                >
                  {localization?.footer_information_society_services}
                </a>
              </li>
              <li>
                <Link to={routePath.news}>
                  {localization?.belbim_footer_news}
                </Link>
              </li>
              <li>
                <Link to={routePath.announcements}>
                  {localization?.belbim_footer_announcements}
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.faqs}
                  onClick={() => {
                    dispatch(faqsRequest(""));
                  }}
                >
                  {localization?.belbim_footer_faq}
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item md={2} xs={12}>
            <ul className="app__footer__link__list">
              <li>
                <Link to={routePath.mediaMaterials}>
                  {localization?.belbim_footer_press_materials}
                </Link>
              </li>
              <li>
                <a
                  href="https://kariyer.ibb.istanbul/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {localization?.footer_career}
                </a>
              </li>
              <li>
                <Link to={routePath.security}>
                  {localization?.belbim_footer_security}
                </Link>
              </li>
              <li>
                <Link to={routePath.sitemap}>
                  {localization?.belbim_footer_site_map}
                </Link>
              </li>
              <li>
                <Link to={routePath.contact}>
                  {localization?.belbim_footer_contact}
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item md={2} xs={12}>
            <ul className="footer-bottom-ul">
              <li>
                <h5>{localization?.footer_individual_customer_line_header}</h5>
              </li>
              <li>
                <a href="tel:153">
                  <span className="footer-blue">
                    {localization?.footer_individual_customer_line_desc}
                  </span>
                </a>
              </li>
            </ul>
            <ul className="footer-bottom-ul">
              <li>
                <h5>
                  {localization?.footer_institutional_customer_line_header}
                </h5>
              </li>
              <li>
                <a href="tel:4442161">
                  <span className="footer-blue">
                    {localization?.footer_institutional_customer_line_desc}
                  </span>
                </a>
              </li>
            </ul>
            <ul className="footer-bottom-ul">
              <li>
                <h5>{localization?.belbim_footer_social_media}</h5>
              </li>
              <li className="linkList">
                <a target="_blank" href="https://www.instagram.com/belbim/">
                  <img src="assets/svg/instagram.svg" alt="Instagram" />
                </a>
                <a target="_blank" href="https://www.facebook.com/belbim">
                  <img src="assets/svg/facebook.svg" alt="Facebook" />
                </a>
                <a target="_blank" href="https://x.com/belbimibb">
                  <img src="assets/svg/x.svg" alt="X" />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UC_ywtcJjIf0LWxDj0EZUOEw"
                >
                  <img src="assets/svg/youtube.svg" alt="Youtube" />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/belbimas"
                >
                  <LinkedInGrayIcon
                    style={{
                      transform: "translate(0.3px, 0px)",
                    }}
                    svgStyle={{
                      width: "24px",
                      height: "24px",
                    }}
                    y={8}
                    color="#1581BE"
                  />{" "}
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
        <div className="mobile__bottom">
          <ul className="footer-bottom-ul">
            {/* <li>
              <h5>{localization?.footer_mobile_applications}</h5>
            </li>
            <li>
              <a target="_blank" href="/">
                <img
                  src="assets/png/play-store-banner.png"
                  alt="Google Play Store"
                />
              </a>
            </li>
            <li>
              <a target="_blank" href="/">
                <img
                  src="assets/png/app-store-banner.png"
                  alt="Apple App Store"
                />
              </a>
            </li>
            <li>
              <a target="_blank" href="/">
                <img
                  src="assets/png/app-gallery-banner.png"
                  alt="Huawei App Gallery"
                />
              </a>
            </li> */}
            <li
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 20,
              }}
              className="mobile__icon__tcmb__belbim"
            >
              <img src={Images.IbbLogo} width="130px" />
              <img src={Images.BelbimLogo} width={"130px"} />{" "}
            </li>
            <li
              className="mobile__icon__tcmb__belbim"
              style={{
                alignItems: "center",
                justifyContent: "center",
                gap: "1.5rem",
              }}
            >
              <img className="tcmb__img" width="130" src={Images.Tcmb} />
              <img className="bkm__img" width="130" src={Images.BKMLogo} />{" "}
            </li>
            <li className="footer__mobile__copyrow">
              <p className="copyright__mobile__text">
                {localization?.footer_belbim_rights_desc}
              </p>
            </li>
          </ul>
        </div>
        {showVersion && (
          <p className="version__number">
            Versiyon {process.env.REACT_APP_VERSION}
          </p>
        )}
      </div>
    </footer>
  );
};

export default Footer;
