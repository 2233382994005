import { FunctionComponent } from "react";

const ListBlue: FunctionComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#1AAADC" fillOpacity="0.1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.95 20C14.4253 20 14 20.4253 14 20.95V23.05C14 23.5747 14.4253 24 14.95 24H49.05C49.5747 24 50 23.5747 50 23.05V20.95C50 20.4253 49.5747 20 49.05 20H14.95ZM14 30.95C14 30.4253 14.4253 30 14.95 30H49.05C49.5747 30 50 30.4253 50 30.95V33.05C50 33.5747 49.5747 34 49.05 34H14.95C14.4253 34 14 33.5747 14 33.05V30.95ZM14 40.95C14 40.4253 14.4253 40 14.95 40H49.05C49.5747 40 50 40.4253 50 40.95V43.05C50 43.5747 49.5747 44 49.05 44H14.95C14.4253 44 14 43.5747 14 43.05V40.95Z"
        fill="#0080BC"
      />
      <mask
        id="mask0_412_10853"
        maskUnits="userSpaceOnUse"
        x="14"
        y="20"
        width="36"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.95 20C14.4253 20 14 20.4253 14 20.95V23.05C14 23.5747 14.4253 24 14.95 24H49.05C49.5747 24 50 23.5747 50 23.05V20.95C50 20.4253 49.5747 20 49.05 20H14.95ZM14 30.95C14 30.4253 14.4253 30 14.95 30H49.05C49.5747 30 50 30.4253 50 30.95V33.05C50 33.5747 49.5747 34 49.05 34H14.95C14.4253 34 14 33.5747 14 33.05V30.95ZM14 40.95C14 40.4253 14.4253 40 14.95 40H49.05C49.5747 40 50 40.4253 50 40.95V43.05C50 43.5747 49.5747 44 49.05 44H14.95C14.4253 44 14 43.5747 14 43.05V40.95Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_412_10853)">
        <rect
          x="5.33325"
          y="5.33334"
          width="53.3333"
          height="53.3333"
          fill="#1581BE"
        />
      </g>
    </svg>
  );
};

export default ListBlue;
