import { FunctionComponent } from "react";

const ChevronUp: FunctionComponent<React.SVGProps<SVGSVGElement>> = ({
  fill,
  width,
  height,
}: any) => {
  const randomId =
    Math.floor(Math.random() * 1000) + "_" + Math.floor(Math.random() * 100);
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id={`mask0_1056_23394${randomId ?? 0}`}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="10"
        height="6"
      >
        <path
          d="M12.4714 10.4714C12.231 10.7117 11.8529 10.7302 11.5914 10.5268L11.5286 10.4714L7.99996 6.94329L4.47136 10.4714C4.23104 10.7117 3.85289 10.7302 3.59136 10.5268L3.52855 10.4714C3.28823 10.231 3.26975 9.85289 3.47309 9.59136L3.52855 9.52856L7.52855 5.52856C7.76888 5.28823 8.14703 5.26975 8.40856 5.4731L8.47136 5.52856L12.4714 9.52855C12.7317 9.7889 12.7317 10.211 12.4714 10.4714Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_1056_23394${randomId ?? 0})`}>
        <rect
          x="16"
          y="16"
          width="16"
          height="16"
          transform="rotate(180 16 16)"
          fill="#1AAADD"
        />
      </g>
    </svg>
  );
};

export default ChevronUp;
