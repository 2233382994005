import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetIstanbulkartFindContentModel } from "Models";
import { RootState } from "../index";
import { IstanbulkartFindContentState } from "./types";

export const initialState: IstanbulkartFindContentState = {
  loading: true,
  BelbimIstanbulSearchList: [],
};

export const IstanbulkartFindContentSlice = createSlice({
  name: "istanbulkartFindContent",
  initialState,
  reducers: {
    onInit: (state) => {},
    istanbulkartFindContentRequest: (
      state: IstanbulkartFindContentState,
      { payload }: PayloadAction<any>
    ) => {
      state.loading = true;
    },
    istanbulkartFindContentRequestSuccess: (
      state: IstanbulkartFindContentState,
      action: PayloadAction<
        GetIstanbulkartFindContentModel["BelbimIstanbulSearchList"]
      >
    ) => {
      state.BelbimIstanbulSearchList = action.payload;
      state.loading = false;
    },
  },
});

export const {
  onInit,
  istanbulkartFindContentRequest,
  istanbulkartFindContentRequestSuccess,
} = IstanbulkartFindContentSlice.actions;

export const istanbulkartFindContentStore = (state: RootState) =>
  state.istanbulkartFindContent;

export default IstanbulkartFindContentSlice.reducer;
