import { axiosHelper } from "Services/AxiosHelper";
import { GetAnonymousTokenModel } from "Models";
import { cookieKeys, cookies } from "Config";
const { apiGWTokenAnonsmousToken } = cookieKeys;

export const setAnonymousToken = (token: string) => {
  cookies.set(apiGWTokenAnonsmousToken.name, token, {
    ...apiGWTokenAnonsmousToken.options,
    maxAge: 1800,
  });
};

const getAnonymousToken = async (): Promise<GetAnonymousTokenModel> =>
  (
    await axiosHelper({
      method: "post",
      data: {
        command: "login.lgU&pvv",
        id: process.env.REACT_APP_API_ID,
        password: process.env.REACT_APP_API_PASSWORD,
      },
    })
  ).data;

export default getAnonymousToken;
