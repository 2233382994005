// @ts-nocheck
import { getFaqsParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetFaqsModel } from "./../../Models";

const url = `${process.env.REACT_APP_CMS_API_URL}/Api/Faq/BelbimIstanbulFaqs`;
const getFaqs = async ({
  languageId,
  Filter = "",
}: getFaqsParams): Promise<GetFaqsModel> => {
  return (
    await axiosHelper({
      method: "post",
      url,
      data: { LanguageId: languageId, Filter },
      parameters: { languageId, Filter },
    })
  ).data;
};

export default getFaqs;
