import { useState, useEffect } from 'react';
import { deviceHeight } from 'Theme/Variables';
import { styleNumParse } from 'Utils';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const isTinyHeightDevice = height < styleNumParse(deviceHeight.tiny);
  return {
    width,
    height,
    isTinyHeightDevice,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
