import React, { FC, memo } from "react";
import "./SearchListPage.scss";
import { useSelector } from "react-redux";
import { istanbulkartFindContentStore } from "Store/getIstanbulkartFindContent";
import { routePath } from "Navigator/routes";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { fontSizes } from "Theme/Variables";
import { ChevronDarkRightIcon } from "Icons";
import parse from "html-react-parser";
import { SearchCategory } from "Utils";
import Images from "Assets/Images";
import { faqsRequest } from "Store/getFaqs";
import { useDispatch } from "react-redux";
import { useLocalization } from "Hooks";
/**@Todo Burada html kodları dönüşümü yapılıp sadece 2 satır görünmesi sağlanacak */
export interface IPage1Props {
  title: string;
}
type SearchItemType = {
  title: string;
  id: number;
  description: string;
  itemID: string;
  category: string;
};
const SearchListPage: React.FunctionComponent<IPage1Props> = (props) => {
  const localization = useLocalization();
  const { BelbimIstanbulSearchList } = useSelector(
    istanbulkartFindContentStore
  );

  return (
    <div id="searchListPage" className="searchListPage pageContainer">
      <h1>{localization?.search_results_page_header}</h1>
      {BelbimIstanbulSearchList &&
        BelbimIstanbulSearchList.map((item, id) => {
          return (
            <SearchItem
              id={id}
              title={item?.Title}
              key={id}
              itemID={""}
              description={item?.Content}
              category={item?.Category}
            />
          );
        })}
      {BelbimIstanbulSearchList?.length === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 50,
          }}
        >
          <img
            src={Images.SadEmoji}
            width={31}
            height={31}
            style={{ marginBottom: 20 }}
          />
          <Typography variant="h3" align="center" color="primary">
            <Box fontWeight={600}>{"Sonuç Bulunamadı.*"}</Box>
          </Typography>
        </div>
      )}
    </div>
  );
};
export default SearchListPage;

const SearchItem: FC<SearchItemType> = memo(
  ({ title, description, itemID, id, category }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    return (
      <div
        className="searchListPage__item"
        onClick={() => {
          if (category === SearchCategory.NEWS) {
            navigate(routePath?.news);
          } else if (category === SearchCategory.FAQ) {
            dispatch(faqsRequest(""));
            navigate(routePath.faqs);
          } else if (category === SearchCategory.WEB_SLIDER) {
            navigate(routePath.welcome);
          } else if (category === SearchCategory.ANNOUNCEMENTS) {
            navigate(routePath.announcements);
          }
          else if (category === SearchCategory.SECURITYPAGECONTENT) {
            navigate(routePath.security);
          }
          else if (category === SearchCategory.PRODUCTSANDSERVICESCONTENT) {
            navigate(routePath.productsAndServices, {
              state:{tab:1 ,Id:0}
            });
          }
        }}
        itemID={itemID}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "96%" }}>
          <Typography
            className="searchListPage__item__title"
            variant="h6"
            align="left"
          >
            <Box fontWeight="bold" fontSize={fontSizes?.regular}>
              {title && parse(title)}
            </Box>
          </Typography>
          <p>
            {/* {description?.includes("<p>")
              ? parse(
                  description?.substring(
                    description?.indexOf("<p>") + 3,
                    description?.indexOf("</p>")
                  )
                )
              : description} */}
              {description && parse(description)}
          </p>
        </div>

        <ChevronDarkRightIcon />
      </div>
    );
  }
);
