import { FunctionComponent } from "react";

const BelbimBlue: FunctionComponent = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="119"
      height="27"
      viewBox="0 0 119 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="belbim__mobil__logo"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.76003 19.4548H8.68253C9.79663 19.4548 10.6172 19.3306 11.1452 19.0801C11.9236 18.7244 12.3124 18.0632 12.3124 17.0934C12.3124 15.4042 11.0921 14.5586 8.65448 14.5586H5.76003V19.4548ZM8.36694 10.9578C10.1703 10.9578 11.074 10.2645 11.074 8.87091C11.074 7.33502 10.1703 6.56757 8.36694 6.56757H5.76003V10.9578H8.36694ZM0.690491 23.1408V2.99585H8.12147C13.4695 2.99585 16.1416 4.71909 16.1426 8.16558C16.1426 9.98901 15.2499 11.3045 13.4635 12.111C16.2568 12.9836 17.6544 14.7369 17.6544 17.3669C17.6544 21.2161 14.9994 23.1408 9.69043 23.1408H0.690491Z"
        fill="#004469"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.2799 23.1409V2.99603H54.3204V19.2526H63.0017V23.1409H49.2799Z"
        fill="#004469"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.3313 19.4548H73.2548C74.3689 19.4548 75.1904 19.3306 75.7174 19.0801C76.4949 18.7244 76.8836 18.0632 76.8836 17.0934C76.8836 15.4042 75.6653 14.5586 73.2257 14.5586H70.3313V19.4548ZM72.9382 10.9578C74.7436 10.9578 75.6453 10.2645 75.6453 8.87091C75.6453 7.33502 74.7436 6.56757 72.9382 6.56757H70.3313V10.9578H72.9382ZM65.2637 23.1408V2.99585H72.6937C78.0408 2.99585 80.7148 4.71909 80.7148 8.16558C80.7148 9.98901 79.8211 11.3045 78.0368 12.111C80.83 12.9836 82.2257 14.7369 82.2257 17.3669C82.2257 21.2161 79.5727 23.1408 74.2637 23.1408H65.2637Z"
        fill="#004469"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.9111 23.1409V3.36273H95.2511V5.50275C96.8581 3.84764 98.7697 3.02008 100.988 3.02008C103.483 3.02008 105.168 4.06304 106.043 6.14997C108.023 4.06304 110.178 3.02008 112.517 3.02008C116.378 3.02008 118.308 5.22222 118.31 9.6205V23.1409H112.932V10.3579C112.932 8.01949 112.127 6.85229 110.521 6.85229C109.046 6.85229 107.769 7.75098 106.692 9.54837V23.1409H101.474V10.3579C101.474 8.01949 100.67 6.85229 99.0653 6.85229C97.5775 6.85229 96.3061 7.75098 95.2511 9.54837V23.1409H89.9111Z"
        fill="#004469"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.0174 23.141L83.0164 9.48526C83.0755 9.55439 83.1376 9.62052 83.2037 9.68764C83.8489 10.3188 84.6314 10.6354 85.5541 10.6354C86.4869 10.6354 87.2704 10.3258 87.9015 9.70768C87.9817 9.62853 88.0568 9.54638 88.126 9.46222H88.1951V23.141H83.0174Z"
        fill="#004469"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.3075 5.89855C82.3075 4.97681 82.6231 4.20236 83.2563 3.56917C83.9015 2.92295 84.685 2.60034 85.6067 2.60034C86.5405 2.60034 87.3229 2.91594 87.9561 3.54913C88.5873 4.18032 88.9029 4.96179 88.9039 5.89855C88.9039 6.83231 88.5873 7.61578 87.9561 8.24797C87.3229 8.86513 86.5405 9.17572 85.6067 9.17572C84.685 9.17572 83.9015 8.86012 83.2563 8.22793C82.6231 7.59474 82.3075 6.81928 82.3075 5.89855Z"
        fill="#80B4CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7038 12.5095C21.3048 9.15524 24.3756 6.24977 28.0545 4.25702C26.3293 6.16862 24.6792 8.34772 23.9588 10.5889C21.6545 17.7534 26.6919 23.1095 34.8062 22.5865C39.468 22.2859 44.3963 20.2441 48.0491 17.0761C44.1298 22.7869 35.8161 26.567 28.5314 25.9619C20.4282 25.2916 16.4767 19.2683 19.7038 12.5095Z"
        fill="#80B4CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4895 9.94817C27.783 7.24107 30.2596 4.89566 33.2282 3.28864C31.8366 4.83254 30.7696 6.5668 30.1895 8.37421C28.331 14.1571 32.1302 18.5033 38.6775 18.0795C42.4386 17.837 46.2898 16.0717 49.2383 13.5169C46.0764 18.1246 39.493 21.2915 33.6139 20.8026C27.0746 20.2626 23.8856 15.4014 26.4895 9.94817Z"
        fill="#80B4CB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0096 9.59448C37.8996 11.5542 35.7365 12.3547 34.3109 12.53C35.2176 11.982 37.0109 10.946 38.2423 9.38007C40.5556 6.43754 40.8141 3.08823 38.614 1.6345C37.3496 0.797924 35.5953 0.834994 33.8881 1.57238C36.2846 -0.318176 39.8954 -0.589687 41.6096 1.22773C43.5152 3.24854 42.4872 7.06071 40.0096 9.59448Z"
        fill="#80B4CB"
      />
    </svg>
  );
};

export default BelbimBlue;
