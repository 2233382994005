import { FunctionComponent } from "react";

const Belbim: FunctionComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="150"
      height="34"
      viewBox="0 0 150 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="belbim__logo"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.46533 24.811H10.1932C11.6123 24.811 12.6592 24.6522 13.3338 24.3332C14.3251 23.88 14.8212 23.0363 14.8212 21.7998C14.8212 19.6449 13.2656 18.5663 10.1567 18.5663H6.46533V24.811ZM9.79006 13.9741C12.0901 13.9741 13.2418 13.0891 13.2418 11.3128C13.2418 9.35404 12.0901 8.37543 9.79006 8.37543H6.46533V13.9741H9.79006ZM0 29.5119V3.8197H9.47655C16.2974 3.8197 19.7047 6.0182 19.7063 10.4136C19.7063 12.7399 18.5682 14.4169 16.2895 15.4447C19.8523 16.5583 21.6342 18.7933 21.6342 22.1482C21.6342 27.0571 18.2491 29.5119 11.4782 29.5119H0Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9668 29.5119V3.81964H68.3948V24.5522H79.4667V29.5119H61.9668Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.8135 24.811H92.5422C93.9629 24.811 95.0098 24.6522 95.682 24.3332C96.6734 23.88 97.1694 23.0363 97.1694 21.7998C97.1694 19.6449 95.6162 18.5663 92.5049 18.5663H88.8135V24.811ZM92.1375 13.9741C94.4399 13.9741 95.5908 13.0891 95.5908 11.3128C95.5908 9.35404 94.4399 8.37543 92.1375 8.37543H88.8135V13.9741H92.1375ZM82.3506 29.5119V3.8197H91.8271C98.6457 3.8197 102.055 6.0182 102.055 10.4136C102.055 12.7399 100.916 14.4169 98.6409 15.4447C102.203 16.5583 103.983 18.7933 103.983 22.1482C103.983 27.0571 100.6 29.5119 93.8288 29.5119H82.3506Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.784 29.5119V4.2879H120.595V7.01737C122.644 4.90698 125.082 3.85138 127.91 3.85138C131.092 3.85138 133.241 5.18159 134.357 7.8428C136.882 5.18159 139.631 3.85138 142.614 3.85138C147.538 3.85138 149.998 6.66022 150 12.2692V29.5119H143.143V13.2089C143.143 10.2278 142.116 8.73807 140.068 8.73807C138.188 8.73807 136.557 9.88494 135.184 12.1771V29.5119H128.529V13.2089C128.529 10.2278 127.506 8.73807 125.458 8.73807C123.562 8.73807 121.94 9.88494 120.595 12.1771V29.5119H113.784Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.992 29.5119L104.991 12.0969C105.066 12.1842 105.145 12.2692 105.23 12.3541C106.053 13.1597 107.051 13.5637 108.226 13.5637C109.418 13.5637 110.416 13.1692 111.222 12.3803C111.323 12.2795 111.419 12.1739 111.507 12.0676H111.596V29.5119H104.992Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.087 7.52249C104.087 6.34705 104.489 5.35812 105.297 4.55095C106.12 3.7279 107.118 3.31677 108.294 3.31677C109.485 3.31677 110.483 3.71917 111.29 4.52634C112.095 5.33114 112.498 6.328 112.499 7.52249C112.499 8.71301 112.095 9.71225 111.29 10.5186C110.483 11.306 109.485 11.702 108.294 11.702C107.118 11.702 106.12 11.2988 105.297 10.4932C104.489 9.68606 104.087 8.69634 104.087 7.52249Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2475 15.9534C26.2896 11.6763 30.2056 7.96977 34.8979 5.42841C32.697 7.86739 30.5938 10.6453 29.6739 13.5041C26.7357 22.6418 33.1597 29.4714 43.5085 28.8047C49.4532 28.4213 55.7376 25.8173 60.3965 21.7766C55.3987 29.0603 44.7959 33.8811 35.5058 33.1096C25.1713 32.2548 20.1322 24.5728 24.2475 15.9534Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9021 12.6865C34.5513 9.23476 37.7102 6.24338 41.4952 4.19409C39.7206 6.16242 38.3602 8.37362 37.6197 10.6801C35.2498 18.0549 40.0952 23.5972 48.4447 23.0567C53.2417 22.7464 58.153 20.4971 61.9135 17.2382C57.8808 23.1139 49.4844 27.1529 41.9873 26.5299C33.6481 25.8409 29.5813 19.6415 32.9021 12.6865Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.1445 12.2361C47.4524 14.7339 44.6951 15.7569 42.876 15.9791C44.0332 15.2807 46.3198 13.9592 47.8905 11.9623C50.8406 8.2098 51.1708 3.93821 48.3643 2.08338C46.7516 1.01667 44.5142 1.06509 42.3379 2.00481C45.3936 -0.405603 49.9985 -0.752442 52.1843 1.56669C54.6153 4.14219 53.3026 9.00428 50.1445 12.2361Z"
        fill="white"
      />
    </svg>
  );
};

export default Belbim;
