import { FC, memo, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { Button } from "Components";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import "./FaqsDetail.scss";
import { ChevronDown, ChevronUp } from "Icons";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "Store";
import { faqsRequest } from "Store/getFaqs";
import { FaqCategories, makeLink } from "Utils";
import { useLocalization } from "Hooks";
import { routePath } from "Navigator/routes";

type FaqsProps = {
  title: string;
};

type CuriositiesType = {
  title: string;
  onClick: () => void;
  itemID?: string;
  isOpen?: boolean;
  isOpenOnClick?: () => void;
  isDropdown?: boolean;
  Childs?: Array<{ ChildName: string }>;
  onClickSubCategory?: Function;
};

const FaqsDetail: FC<FaqsProps> = memo((props) => {
  const dispatch = useDispatch();
  const localization = useLocalization();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const { FaqList } = useSelector((state: RootState) => state.faqs);

  const _id: any = id ?? 0;
  const data = FaqList && FaqList[_id];
  const { question } = location?.state || {};
  return (
    <div id="faqsDetail" className="pageContainer">
      <h1 className="faqsDetail__title"> {localization?.faq_page_header}</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box className="faqsDetail__curiosities">
          <h5 className="faqsDetail__curiosities__title">
            {localization?.faq_category_header}
          </h5>
          <CuriositiesItem
            title={FaqCategories.BIREYSEL_TEMSILCI}
            onClick={() => {}}
            isOpenOnClick={() => {
              dispatch(faqsRequest(FaqCategories.BIREYSEL_TEMSILCI));
              navigate(routePath.faqs, {
                state: {
                  category: FaqCategories.BIREYSEL_TEMSILCI,
                },
              });
            }}
          />
          <CuriositiesItem
            title={FaqCategories.KURUMSAL_TEMSILCI}
            onClick={() => {}}
            isOpenOnClick={() => {
              dispatch(faqsRequest(FaqCategories.KURUMSAL_TEMSILCI));
              navigate(routePath.faqs, {
                state: {
                  category: FaqCategories.KURUMSAL_TEMSILCI,
                },
              });
            }}
          />
          <CuriositiesItem
            title={FaqCategories.UYE_IS_YERI}
            onClick={() => {}}
            isOpenOnClick={() => {
              dispatch(faqsRequest(FaqCategories.UYE_IS_YERI));
              navigate(routePath.faqs, {
                state: {
                  category: FaqCategories.UYE_IS_YERI,
                },
              });
            }}
          />
          <CuriositiesItem
            title={FaqCategories.KURUMSAL_MUSTERİ}
            onClick={() => {}}
            isOpenOnClick={() => {
              dispatch(faqsRequest(FaqCategories.KURUMSAL_MUSTERİ));
              navigate(routePath.faqs, {
                state: {
                  category: FaqCategories.KURUMSAL_MUSTERİ,
                },
              });
            }}
          />
        </Box>
        <Box className="faqsDetail__rightmenu">
          <div className="faqsDetail__rightmenu__questionBox">
            <Typography variant="h6" align="left">
              <Box
                fontWeight={700}
                fontSize={"15px"}
                className="faqsDetail__rightmenu__question"
                color={"#383C52"}
              >
                {data?.Question}
              </Box>
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: data?.Answer as string }} />
          </div>

          <Typography variant="h6" align="center">
            <Box
              fontWeight={450}
              fontSize={"16px"}
              className="faqsDetail__rightmenu__answer"
              color={"#606375"}
              dangerouslySetInnerHTML={{
                __html: makeLink(localization?.faq_write_us_text_desc),
              }}
            ></Box>
          </Typography>
          <div className="faqsDetail__rightmenu__buttonContainer">
            <Button
              text={localization?.faq_write_us_button_desc}
              onClick={(e) => {
                window.location.href = "mailto:B2B-info-test@belbim.com.tr";
                e.preventDefault();
              }}
              variant="outlined"
              className="faqsDetail__rightmenu__button"
              //leftIcon={<Email />}
            />
          </div>
        </Box>
      </div>
    </div>
  );
});

export default FaqsDetail;

const CuriositiesItem: FC<CuriositiesType> = memo(
  ({
    title,
    onClick,
    itemID,
    isOpen = false,
    isOpenOnClick = () => {},
    isDropdown = false,
    Childs,
    onClickSubCategory = () => {},
  }) => {
    return (
      <div
        className="faqsDetail__curiosities__item"
        onClick={onClick}
        itemID={itemID}
      >
        <Typography
          className="faqsDetail__curiosities__item__title"
          variant="h6"
          align="left"
        >
          <Box
            fontWeight={isDropdown ? "700" : "450"}
            fontSize={"16px"}
            color={isDropdown ? "#1AAADC" : "#383C52"}
            onClick={() => {
              isOpenOnClick();
            }}
          >
            {title}
          </Box>
          {isOpen && (
            <>
              {Childs &&
                Childs.map((item, i) => {
                  return (
                    <Box
                      fontWeight={"450"}
                      fontSize={"13px"}
                      color={"#383C52"}
                      onClick={() => {
                        onClickSubCategory(item?.ChildName);
                      }}
                    >
                      {item?.ChildName}
                    </Box>
                  );
                })}
            </>
          )}
        </Typography>
        {isDropdown &&
          (isOpen ? (
            <div
              style={{ marginTop: 4 }}
              onClick={() => {
                isOpenOnClick();
              }}
            >
              <ChevronUp />
            </div>
          ) : (
            <div
              style={{ marginTop: 4 }}
              onClick={() => {
                isOpenOnClick();
              }}
            >
              <ChevronDown />
            </div>
          ))}
      </div>
    );
  }
);
