import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetAnnouncementsModel } from "Models";
import { RootState } from "../index";
import { AnnouncementsState } from "./types";

export const initialState: AnnouncementsState = {
  loading: true,
  AnnouncementList: undefined,
};

export const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {
    onInit: (state) => {},
    announcementsRequest: (state: AnnouncementsState) => {
      state.loading = true;
    },
    announcementsRequestSuccess: (
      state: AnnouncementsState,
      action: PayloadAction<GetAnnouncementsModel["AnnouncementList"]>
    ) => {
      state.AnnouncementList = action.payload;
      state.loading = false;
    },
  },
});

export const { onInit,  announcementsRequest, announcementsRequestSuccess } =
  announcementsSlice.actions;

export const AnnouncementsStore = (state: RootState) => state.announcements;

export default announcementsSlice.reducer;
