import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { legalTextState } from "./types";

export const initialState: any = {
  loading: true,
  list: [],
};

export const GetIstanbulkartLegalTextPage = createSlice({
  name: "GetIstanbulkartLegalTextPage",
  initialState,
  reducers: {
    onInit: (state) => {},
    getIstanbulkartLegalTextPage: (state: legalTextState) => {
      state.loading = true;
    },
    legalTextList: (state: any, action: PayloadAction<any>) => {
      state.list = action.payload;
      state.loading = false;
    },
  },
});

export const { onInit, getIstanbulkartLegalTextPage, legalTextList } =
  GetIstanbulkartLegalTextPage.actions;

export const istanbulkartFindContentStore = (state: RootState) =>
  state.istanbulkartFindContent;

export default GetIstanbulkartLegalTextPage.reducer;
