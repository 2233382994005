// @ts-nocheck
import { axiosHelper } from "../AxiosHelper";
import { LegalTextPageContentList } from "Models/GetIstanbulkartLegalTextPageModel";

const url = `${process.env.REACT_APP_CMS_API_URL}/Api/Security/GetBelbimLegalTextPage?languageId=2`;

const getIstanbulkartLegalTextPageService =
  async (): Promise<LegalTextPageContentList> => {
    return (
      await axiosHelper({
        method: "get",
        url,
      })
    ).data;
  };

export default getIstanbulkartLegalTextPageService;
