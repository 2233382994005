import { FC, Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingGifPath from "@Assets/Images/loading.gif";
import "./Loading.scss";

type LoadingProps = {};
const Loading: FC<LoadingProps> = () => {
  const [visible, setVisible] = useState(true);
  const rootReducer = useSelector((state) => state);

  useEffect(() => {
     //@ts-ignore
    const loading = Object.keys(rootReducer).some((reducer) => {
      //@ts-ignore
      const _reducer = rootReducer[reducer];
      if ("loading" in _reducer) {
        return _reducer.loading === true;
      }
    });
    setVisible(loading);
  }, [rootReducer]);
  return (
    <Fragment>
      {visible ? (
        <div id="loading">
          <img src={LoadingGifPath} alt="" className="loading_image" />
        </div>
      ) : null}
    </Fragment>
  );
};

export default Loading;
