import { GetNewsModel } from "Models";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { getNews } from "Services/Queries";
import { newsRequest, newsRequestSuccess } from "./index";
import { languageId } from "Utils";

function* handleFaqsRequest() {
  try {
    const getNewsResult: GetNewsModel = yield call(() =>
      getNews({ languageId: languageId() })
    );

    yield put(newsRequestSuccess(getNewsResult.NewsList));
  } catch (error) {}
}

function* newsSaga() {
  yield takeLatest(newsRequest, handleFaqsRequest);
}

export default newsSaga;
