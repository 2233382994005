import * as Yup from "yup";
export const birthDateRegex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
export default Yup.object({
  tckn: Yup.string()
    .min(11, "11 karakter uzunluğunda olmalıdır!")
    .max(11, "11 karakter uzunluğunda olmalıdır!")
    .required(""),
  ad: Yup.string().min(2, "Çok kısa!").max(40, "Çok uzun!").required(""),
  soyad: Yup.string().min(2, "Çok kısa!").max(40, "Çok uzun!").required(""),
  dogumTarihi: Yup.string().required(""),
  eposta: Yup.string()
    .email("Lütfen geçerli bir e-posta adresi giriniz")
    .matches(/^[^çğöşüÇĞÖŞÜıİ]*$/g, ""),
  kartNum: Yup.string().min(16, "Çok kısa!").max(16, "Çok uzun!").required(""),
  telNo: Yup.string().min(10, "Çok kısa!").max(10, "Çok uzun!").required(""),
  kod: Yup.string().min(6, "Çok kısa!").max(6, "Çok uzun!").required(""),
  agreement1: Yup.boolean().oneOf([true], '').required(""),
  agreement2: Yup.boolean().oneOf([true], '').required(""),
  agreement3: Yup.boolean(),
  agreement4: Yup.boolean(),
  agreement5: Yup.boolean()
});
