import React from "react";

export interface IPage2Props {}

const Page2: React.FunctionComponent<IPage2Props> = (props) => {
  return (
    <div>
      <h1>Page2</h1>
    </div>
  );
};

export default Page2;
