import { FunctionComponent } from "react";
import "./SectionTab.scss";

type SectionTabProps = {
  hex?: string;
  text?: string;
  onPress?: Function;
  isActive?: boolean;
};

const SectionTab: FunctionComponent<SectionTabProps> = ({
  hex,
  text,
  onPress = () => {},
  isActive,
}) => {
  return (
    <div
      className={`sectionTab ${ isActive ? "" : "sectionTab__isActive"}`}
      onClick={() => {
        onPress();
      }}
      style={{ backgroundColor: isActive ? "#004469" : "#FFFFFF" }}
    >
      <p
        className="sectionTab__text"
        style={{ color: isActive ? "#FFFFFF" : "#004469" }}
      >
        {text}
      </p>
    </div>
  );
};

export default SectionTab;
