// @ts-nocheck
import { getFaqsParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetAnnouncementsModel } from "./../../Models";

const url = `${process.env.REACT_APP_CMS_API_URL}/Api/Report/GetActivityReports`;
const getActivityReports = async ({
  languageId,
}: getFaqsParams): Promise<GetAnnouncementsModel> => {
  return (
    await axiosHelper({
      method: "get",
      url,
      parameters: { languageId },
    })
  ).data;
};

export default getActivityReports;
