// @ts-nocheck
import { axiosHelper } from "Services/AxiosHelper";
import { GetApiGWTokenModel } from "Models";
import { gwUser, gwUserProd } from "Utils";

const credsProd = gwUserProd();
const creds = gwUser();

const getApiGWToken = async (): Promise<GetApiGWTokenModel> =>
  (
    await axiosHelper({
      method: "post",
      data: {
        //res: isDev ? creds : credsProd,
        res: creds,
      },
    })
  ).data;

export default getApiGWToken;
