const Images = {
  IbbLogo: require("./IbbFooter.png"),
  IbbPNG: require("./IBB.png"),
  IbbFooter2: require("./IbbFooter2.png"),
  BKMLogo: require("./BKM.png"),
  IbbBelbimLogo: require("./ibb-belbim.png"),
  BelbimLogo: require("./BELBIMLogo.png"),
  IslemNoktalari1: require("./IslemNoktalari1.png"),
  IslemNoktalari2: require("./IslemNoktalari2.png"),
  IslemNoktalari3: require("./IslemNoktalari3.png"),
  IslemNoktalari4: require("./IslemNoktalari4.png"),
  IslemNoktalari5: require("./IslemNoktalari5.png"),
  GenelBaskanImg: require("./GenelBaskanImg.png"),
  Back: require("./back.png"),
  ReportImg: require("./ReportImg.png"),
  UrunHizmetlerKartlar: require("./UrunHizmetlerKartlar.png"),
  UrunveHizmetler1: require("./UrunveHizmetler1.png"),
  UrunHizmetlerKartlarSmall: require("./UrunHizmetlerKartlarSmall.png"),
  UrunveHizmetler1Small: require("./UrunveHizmetler1Small.png"),
  PDKSKartlar: require("./PDKSKartlar.png"),
  IstanbulPos: require("./IstanbulPos.png"),
  TlAbonmanYukleme: require("./TlAbonmanYukleme.png"),
  Turnike: require("./Turnike.png"),
  Validator: require("./Validator.png"),
  IadeCihazi: require("./IadeCihazi.png"),
  AnneKart: require("./AnneKart.png"),
  SosyalDestekKarti: require("./SosyalDestekKarti.png"),
  HalkBakkal: require("./HalkBakkal.png"),
  NakitDestekModulu: require("./NakitDestekModulu.png"),
  KartlarDetail: require("./KartlarDetail.png"),
  UrunveHizmetler1Detail: require("./UrunveHizmetler1Detail.png"),
  PDKSKartlarDetail: require("./PDKSKartlarDetail.png"),
  IstanbulPosDetail: require("./IstanbulPosDetail.png"),
  TlAbonmanYuklemeDetail: require("./TlAbonmanYuklemeDetail.png"),
  TurnikelerDetail: require("./TurnikelerDetail.png"),
  ValidatorDetail: require("./ValidatorDetail.png"),
  IadeCihaziDetail: require("./IadeCihaziDetail.png"),
  AnneKartDetail: require("./AnneKartDetail.png"),
  SosyalDestekKartiDetail: require("./SosyalDestekKartiDetail.png"),
  HalkBakkalDetail: require("./HalkBakkalDetail.png"),
  NakitDestekModuluDetail: require("./NakitDestekModuluDetail.png"),
  KartlarimizDetail: require("./KartlarimizDetail.png"),
  IstanbulkartMobilDetail: require("./IstanbulkartMobilDetail.png"),
  PDKSKartlarMobil: require("./PDKSKartlarMobil.png"),
  IstanbulPOSMobil: require("./IstanbulPOSMobil.png"),
  KurumsalTlMobil: require("./KurumsalTlMobil.png"),
  TurnikeMobil: require("./TurnikeMobil.png"),
  ValidatorMobil: require("./ValidatorMobil.png"),
  IadeCihaziMobil: require("./IadeCihaziMobil.png"),
  AnnekartMobil: require("./AnnekartMobil.png"),
  SosyalDestekMobil: require("./SosyalDestekMobil.png"),
  HalkBakkalMobil: require("./HalkBakkalMobil.png"),
  NakitDestekModuluMobil: require("./NakitDestekModuluMobil.png"),
  SadEmoji: require("./SadEmoji.png"),
  IstanbulkartYeniGorsel: require("./IstanbulkartYeniGorsel.png"),
  IstanbulkartEskiGorsel: require("./IstanbulkartEskiGorsel.png"),
  Popover: require("./Popover.png"),
  SecurityImg: require("./securityImg.png"),
  SifremiDegistir: require("./sifremidegistir.png"),
  SifremiDegistirMobil: require("./sifremidegistirmobil.png"),
  CompanyJourney: require("./CompanyJourney.png"),
  Tcmb: require("./tcmb.png"),
};

export default Images;

export type ImageType = typeof Images;
