import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyJourneyList } from "Models/CompanyJourneyModel";
import { RootState } from "../index";
import { CompanyJourneyState } from "./types";

export const initialState: CompanyJourneyState = {
  loading: true,
  CompanyJourneyList: [],
};

export const CompanyJourneySilece = createSlice({
  name: "companyJourney",
  initialState,
  reducers: {
    onInit: (state) => {},
    CompanyJourneyRequest: (state: CompanyJourneyState) => {
      state.loading = true;
    },
    CompanyJourneyRequestSuccess: (
      state: CompanyJourneyState,
      action: PayloadAction<CompanyJourneyList>
    ) => {
      state.CompanyJourneyList = action.payload.CompanyJourneyList;
      state.loading = false;
    },
  },
});

export const { onInit, CompanyJourneyRequest, CompanyJourneyRequestSuccess } =
  CompanyJourneySilece.actions;

export const CompanyJourneyStore = (state: RootState) => state.faqs;

export default CompanyJourneySilece.reducer;
