import React, { FunctionComponent } from "react";
import {
  Box,
  Checkbox as MaterialUICheckBox,
  CheckboxProps as MaterialUICheckBoxProps,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { colors, gutters } from "../../Theme/Variables";
import { Variant } from "@material-ui/core/styles/createTypography";

type CheckboxProps = {
  errorText?: string | boolean;
  label: string | JSX.Element;
  labelVariant?: Variant;
  marginBottomGutter?: string;
  labelClassName?: string;
  onClickLink?: Function;
} & MaterialUICheckBoxProps;

const Checkbox: FunctionComponent<CheckboxProps> = ({
  errorText,
  label,
  labelVariant,
  marginBottomGutter = gutters.regular,
  labelClassName,
  onClickLink = () => {},
  ...props
}) => {
  const makeLink = (str: string) => {
    if (str && str.includes("{%")) {
      let mySubString = str.substring(
        str.lastIndexOf("{%") + 2,
        str.lastIndexOf("%}")
      );
      let deneme = str
        .replace("{%", `<a href=${mySubString} target="_blank">`)
        .replace("%}", "</a>");
      return deneme;
    } else {
      return str;
    }
  };
  return (
    <Box marginBottom={marginBottomGutter}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <MaterialUICheckBox
          style={!!errorText ? { color: colors.errorText } : {}}
          color="primary"
          {...props}
        />
        {typeof label === "string" ? (
          <Typography
            {...(labelClassName && { className: labelClassName })}
            variant={labelVariant ?? "body1"}
            onClick={() => {
              onClickLink();
            }}
            dangerouslySetInnerHTML={{
              __html: makeLink(label),
            }}
          ></Typography>
        ) : (
          label
        )}
      </div>
      {!!errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </Box>
  );
};

export default Checkbox;
