import { FC, memo, useEffect } from "react";
import { Box } from "@material-ui/core";
import "./AnnouncementsDetail.scss";
import { useSelector } from "react-redux";
import { RootState } from "Store";
import { useNavigate, useSearchParams } from "react-router-dom";
import parse from "html-react-parser";
import moment from "moment";

const AnnouncementsDetail: FC = memo(() => {
  const navigate = useNavigate()
  const { AnnouncementList } = useSelector(
    (state: RootState) => state.announcements
  );
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const _id: any = id ?? 0;
  const data =
    AnnouncementList &&
    AnnouncementList.filter(
      (item: any) => item.Id.toString() === _id?.toString()
    );

  useEffect(() => {
    const links = document.getElementsByTagName("a") ?? [];
    for (let i = 0; i < links.length; i++) {
      if (
        links[i].className.includes("header") ||
        links[i].className.includes("footer")
      ) {
      } else {
        links[i].setAttribute("target", "_blank");
      }
    }
  }, []);
  if(!data ||!data.length){
    navigate('/announcements')
  }
  return (
    <div id="announcementsDetail" className="pageContainer">
      <Box className="announcementsDetail__title__container">
        <h1 className="announcementsDetail__pageTitle">
          {data && data[0].Header}
        </h1>
        <p className="announcementsDetail__date">
          {moment(
            data && data[0]?.Date.includes("Z")
              ? data && data[0]?.Date
              : data && data[0]?.Date + "Z"
          )
            .utc()
            .format("DD.MM.YYYY")}
        </p>
      </Box>
      <Box className="announcementsDetail__desc">
        {parse((data && data[0]?.Description) ?? "")}
      </Box>
    </div>
  );
});
export default AnnouncementsDetail;
