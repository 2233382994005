// @ts-nocheck
import { Security } from "Models/GetSecurity";
import { axiosHelper } from "../AxiosHelper";

const url = `${process.env.REACT_APP_CMS_API_URL}/Api/Security/GetBelbimSecurityPage`;

const getSecurity = async ({ languageId }): Promise<Security> => {
  return (
    await axiosHelper({
      method: "get",
      url,
      parameters: { languageId },
    })
  ).data;
};

export default getSecurity;
