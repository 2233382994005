// @ts-nocheck
import { GetOurRepresentativesParams } from "Config";
import { GetOurRepresentativesModel } from "Models";
import { axiosHelper } from "../AxiosHelper";
import getAnonymousToken from "./GetAnonymousToken";

const getOurRepresentatives = async (
  params: GetOurRepresentativesParams
): Promise<GetOurRepresentativesModel> => {
  const token = (await getAnonymousToken()).token;
  const { TopUpSubProfile, ExpenseSubProfile } = params.payload || {};

  return axiosHelper({
    gatewayData: { command: "corporate.CPGetMerchantData", token },
    method: "post",
    headers: { "Content-Type": "application/json; charset=utf-8" },
    url: process.env.REACT_APP_API_BASE_URL,
    data: { id: token, TopUpSubProfile, ExpenseSubProfile },
  }).then((response) => response.data);
};

export default getOurRepresentatives;
