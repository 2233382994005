import {
  Calender,
  FacebookGrayIcon,
  LinkedInGrayIcon,
  LinkGrayIcon,
  Time,
  XGrayIcon,
} from "Icons";
import React, { useEffect } from "react";
import "./NewsDetail.scss";
import { useSelector } from "react-redux";
import { RootState } from "Store";
import { formatDate } from "Utils";
import { useNavigate, useSearchParams } from "react-router-dom";

const NewsDetail: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const { NewsList } = useSelector((state: RootState) => state.news);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const _id: any = id ?? 0;

  const data =
    NewsList &&
    NewsList.filter((item: any) => item.Id.toString() === _id?.toString());

  useEffect(() => {
    const links = document.getElementsByTagName("a") ?? [];
    for (let i = 0; i < links.length; i++) {
      if (
        links[i].className.includes("header") ||
        links[i].className.includes("footer")
      ) {
      } else {
        links[i].setAttribute("target", "_blank");
      }
    }
  }, []);
  if(!data ||!data.length){
    navigate('/news')
  }
  return (
    <div id="news__detail" className="pageContainer">
      {data && (
        <>
          <h1 className="news__detail__title">{data && data[0]?.HeadLine}</h1>
          <img
            className="news__detail__image"
            src={data && data[0]?.WebDetailPictureUrl}
            alt={data && data[0]?.HeadLine}
          />
          <div className="news__detail__info">
            <span>
              <Calender />
              {formatDate((data && data[0]?.RelaseDate) ?? "")}
            </span>
            <span>|</span>
            <span>
              <Time /> {data && data[0]?.ReadingTime} dk
            </span>
          </div>
          <div className="news__detail__desc">
            <p
              dangerouslySetInnerHTML={{
                __html: data && data[0]?.Description,
              }}
            />
          </div>
          {/* <div className="news__detail__social">
            <a target="_blank" href={`https://x.com/belbimibb`}>
              <XGrayIcon />
            </a>
            <a target="_blank" href={`https://www.facebook.com/belbim`}>
              <FacebookGrayIcon />
            </a>
            <a
              target="_blank"
              href={`https://www.linkedin.com/company/belbimas/`}
            >
              <LinkedInGrayIcon />
            </a>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `https://belbim-istanbul-website.web.app/newsDetail?id=${_id}`
                  )
                  .then(() => {
                    alert("Bağlantı kopyalandı.");
                  })
                  .catch(() => {
                    alert("Bağlantı kopyalanırken bir hata oluştu.");
                  });
              }}
            >
              <LinkGrayIcon />
            </a>
          </div> */}
        </>
      )}
    </div>
  );
};
export default NewsDetail;
