import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetActivityReportsModel } from "Models";
import { RootState } from "../index";
import { GetActivityReportsState } from "./types";

export const initialState: GetActivityReportsState = {
  loading: false,
  ActivityReports: [],
};

export const getActivityReportsSlice = createSlice({
  name: "getActivityReports",
  initialState,
  reducers: {
    getActivityReportsRequest: (state: GetActivityReportsState) => {
      state.loading = true;
    },
    getActivityReportsRequestSuccess: (
      state: GetActivityReportsState,
      action: PayloadAction<GetActivityReportsModel>
    ) => {
      state.ActivityReports = action.payload.ActivityReports;
      state.loading = false;
    },
    getActivityReportsRequestFail: (state: GetActivityReportsState) => {
      state.loading = false;
    },
  },
});

export const {
  getActivityReportsRequest,
  getActivityReportsRequestSuccess,
  getActivityReportsRequestFail,
} = getActivityReportsSlice.actions;

export const GetActivityReportsStore = (state: RootState) =>
  state.getActivityReports;

export default getActivityReportsSlice.reducer;
