import { routePath } from "Navigator/routes";
import { FC, useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Belbim, BelbimBlue, Menu, Search, Left, EyeOn } from "Icons";
import "./Header.scss";
import { useDispatch } from "react-redux";
import { setIsDetailScreen, setLanguageId } from "Store/global";
import { useLocalization } from "Hooks";
import { istanbulkartFindContentRequest } from "Store/getIstanbulkartFindContent";
import { languageId } from "Utils";
import { Input } from "Components/Input";

type HeaderProps = {};

const Header: FC<HeaderProps> = () => {
  const localization = useLocalization();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [openedMobileMenu, setOpenedMobileMenu] = useState(false);
  const [activeIslemNoktalari, setActiveIslemNoklari] = useState(false);
  const [activeKurumsal, setActiveKurumsal] = useState(false);
  const [activeUrunveHizmetler, setActiveUrunveHizmetler] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [mobileSearchActive, setMobileSearchActive] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    dispatch(
      istanbulkartFindContentRequest({
        languageId: languageId(),
        SearchKey: searchValue,
      })
    );
  }, [searchValue]);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (inputRef.current && !inputRef.current.contains(event?.target)) {
        setSearchOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (location.pathname === routePath.institutional) {
      setActiveTab(localization?.homepage_menu_institutional);
    } else if (location.pathname === routePath.productsAndServices) {
      setActiveTab(localization?.homepage_menu_product_and_services);
    } else if (location.pathname === routePath.processingPoints) {
      setActiveTab(localization?.homepage_menu_transaction_points);
    } else {
      setActiveTab("");
    }
  }, [navigate, localization]);
  return (
    <>
      <div
        id="app__header"
        className={`${openedMobileMenu ? "app__header__fixed" : ""}`}
      >
        <div className="pageContainer app__header__content">
          <div className="app__header__menu">
            <Link
              to={routePath.welcome}
              onClick={() => {
                setOpenedMobileMenu(false);
                setMobileSearchActive(false);
                setSearchValue("");
                setActiveIslemNoklari(false);
                setActiveKurumsal(false);
                setActiveUrunveHizmetler(false);
              }}
              className="app__header__menu__logo"
            >
              <Belbim />
              <BelbimBlue />
            </Link>
            <ul>
              <li>
                <Link
                  to={routePath.institutional}
                  style={{
                    color:
                      activeTab === localization?.homepage_menu_institutional
                        ? "#43A9D6"
                        : "#fff",
                  }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                  }}
                >
                  {localization?.homepage_menu_institutional}
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.productsAndServices}
                  style={{
                    color:
                      activeTab ===
                      localization?.homepage_menu_product_and_services
                        ? "#43A9D6"
                        : "#fff",
                  }}
                >
                  {localization?.homepage_menu_product_and_services}
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.processingPoints}
                  style={{
                    color:
                      activeTab ===
                      localization?.homepage_menu_transaction_points
                        ? "#43A9D6"
                        : "#fff",
                  }}
                >
                  {localization?.homepage_menu_transaction_points}
                </Link>
              </li>
            </ul>
          </div>
          <div className="app__header__right">
            <div
              id="sb-search"
              className={`sb-search ${searchOpen ? "sb-search-open" : ""}`}
            >
              <input
                ref={inputRef}
                className="sb-search-input"
                placeholder={localization?.homepage_search_button_desc}
                type="text"
                value={searchValue}
                autoComplete="no"
                onChange={(event) => {
                  const val = event?.target?.value;
                  setSearchValue(val);
                  navigate(routePath.searchListPage);
                }}
              />
              <span
                className="sb-icon-search"
                onClick={() => {
                  setSearchOpen(!searchOpen);
                  setSearchValue("");
                }}
              >
                <Search />
              </span>
            </div>
            <div
              onClick={() => {
                setMobileSearchActive(!mobileSearchActive);
              }}
              className={`app__header__mobileSearchIcon ${
                mobileSearchActive
                  ? "app__header__mobileSearchIcon__active"
                  : ""
              }`}
            >
              {/*@ts-ignore */}
              <Search fill="#004469" />
            </div>
            <Link
              className="app__header__button__link"
              to={routePath.onlineTransactions}
            >
              {localization?.homepage_header_button}
            </Link>
            {/* @ts-ignore */}
            <Link
              className="app__header__lang"
              onClick={() => {
                const id = languageId() == "2" ? 1 : 2;
                dispatch(setLanguageId(id));
                setTimeout(() => {
                  window.location.reload();
                }, 0);
              }}
            >
              {languageId() == "2" ? "EN" : "TR"}
            </Link>
            <div
              className="mobil-menu"
              onClick={() => {
                setOpenedMobileMenu(!openedMobileMenu);
                setMobileSearchActive(false);
                setSearchValue("");
              }}
            >
              <Menu />
            </div>
          </div>
        </div>

        {activeUrunveHizmetler ? (
          <div
            className={`mobile__menu ${
              openedMobileMenu ? "active__mobil__menu" : ""
            }`}
          >
            <div
              style={{ paddingTop: 10, marginBottom: -20 }}
              onClick={() => {
                setActiveUrunveHizmetler(!activeUrunveHizmetler);
                window.scrollTo({ top: 0 });
              }}
            >
              <Left width={38} height={38} />
            </div>

            <ul>
              <li>
                <Link
                  to={routePath.productsAndServices}
                  state={{ id: 0 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    dispatch(setIsDetailScreen(false));
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.product_and_services_menu_individual_services}
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.productsAndServices}
                  state={{ id: 1 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    dispatch(setIsDetailScreen(false));
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {
                    localization?.product_and_services_menu_institutional_services
                  }
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.productsAndServices}
                  state={{ id: 2 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    dispatch(setIsDetailScreen(false));
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {
                    localization?.product_and_services_menu_EUTS_in_transportation
                  }
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.productsAndServices}
                  state={{ id: 3 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    dispatch(setIsDetailScreen(false));
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.product_and_services_menu_social_services}
                </Link>
              </li>
            </ul>
            <div className="tcmb-belbim-logo-header">
              <img src="./assets/png/ibb-blue-logo.png" alt="" />
              <img src="./assets/png/tcmb-logo.png" alt="" />
            </div>
            <div className="copyright-header">
              <p>{localization?.footer_belbim_rights_desc}</p>
            </div>
          </div>
        ) : activeKurumsal ? (
          <div
            className={`mobile__menu ${
              openedMobileMenu ? "active__mobil__menu" : ""
            }`}
          >
            <div
              style={{ paddingTop: 10, marginBottom: -20 }}
              onClick={() => {
                setActiveKurumsal(!activeKurumsal);
                window.scrollTo({ top: 0 });
              }}
            >
              <Left width={38} height={38} />
            </div>

            <ul>
              <li>
                <Link
                  to={`${routePath.institutional}?page=president-message`}
                  state={{ id: 0 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.institutional_menu_presidents_message}
                </Link>
              </li>
              <li>
                <Link
                  to={`${routePath.institutional}?page=general-manager`}
                  state={{ id: 1 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.institutional_menu_general_manager}
                </Link>
              </li>
              <li>
                <Link
                  to={`${routePath.institutional}?page=about-us`}
                  state={{ id: 2 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.institutional_menu_about_us}
                </Link>
              </li>
              <li>
                <Link
                  to={`${routePath.institutional}?page=reports`}
                  state={{ id: 3 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.institutional_menu_reports}
                </Link>
              </li>
            </ul>
            <div className="tcmb-belbim-logo-header">
              <img src="./assets/png/ibb-blue-logo.png" alt="" />
              <img src="./assets/png/tcmb-logo.png" alt="" />
            </div>
            <div className="copyright-header">
              <p>{localization?.footer_belbim_rights_desc}</p>
            </div>
          </div>
        ) : activeIslemNoktalari ? (
          <div
            className={`mobile__menu ${
              openedMobileMenu ? "active__mobil__menu" : ""
            }`}
          >
            <div
              style={{ paddingTop: 10, marginBottom: -20 }}
              onClick={() => {
                setActiveIslemNoklari(!activeIslemNoktalari);
              }}
            >
              <Left width={38} height={38} />
            </div>

            <ul>
              <li>
                <Link
                  to={routePath.processingPoints}
                  state={{ id: 0 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.trading_points_biletmatik_header}
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.processingPoints}
                  state={{ id: 1 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.trading_points_menu_istanbulkart_apply_center}
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.processingPoints}
                  state={{ id: 2 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.trading_points_menu_individual_representative}
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.processingPoints}
                  state={{ id: 3 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {
                    localization?.trading_points_menu_institutional_representative
                  }
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.processingPoints}
                  state={{ id: 4 }}
                  onClick={() => {
                    setOpenedMobileMenu(false);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.trading_points_menu_member_bussiness}
                </Link>
              </li>
            </ul>
            <div className="tcmb-belbim-logo-header">
              <img src="./assets/png/ibb-blue-logo.png" alt="" />
              <img src="./assets/png/tcmb-logo.png" alt="" />
            </div>
            <div className="copyright-header">
              <p>{localization?.footer_belbim_rights_desc}</p>
            </div>
          </div>
        ) : (
          <div
            className={`mobile__menu ${
              openedMobileMenu ? "active__mobil__menu" : ""
            }`}
          >
            <ul>
              <li>
                <Link
                  to={routePath.institutional}
                  onClick={() => {
                    setActiveKurumsal(!activeKurumsal);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.homepage_menu_institutional}
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.productsAndServices}
                  onClick={() => {
                    setActiveUrunveHizmetler(!activeUrunveHizmetler);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.homepage_menu_product_and_services}
                </Link>
              </li>
              <li>
                <Link
                  to={routePath.processingPoints}
                  onClick={() => {
                    setActiveIslemNoklari(!activeIslemNoktalari);
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {localization?.homepage_menu_transaction_points}
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to={routePath.onlineTransactions}
                  className="online"
                >
                  <span>{localization?.homepage_header_button}</span>
                </Link>
              </li>
            </ul>
            <div className="tcmb-belbim-logo-header">
              <img src="./assets/png/ibb-blue-logo.png" alt="" />
              <img src="./assets/png/tcmb-logo.png" alt="" />
            </div>
            <div className="copyright-header">
              <p>{localization?.footer_belbim_rights_desc}</p>
            </div>
          </div>
        )}
      </div>
      {mobileSearchActive ? (
        <div className="mobile__input__search">
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              value={searchValue}
              placeholder={localization?.homepage_search_button_desc}
              autoComplete="no"
              onChange={(event) => {
                const val = event?.target?.value;
                setSearchValue(val);
                navigate(routePath.searchListPage);
              }}
            />
            <div className="icon__container">
              {searchValue.length > 0 ? (
                <EyeOn
                  onClick={() => {
                    setSearchValue("");
                  }}
                />
              ) : (
                <Search />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Header;
