import { FunctionComponent } from "react";
import "./TabBox.scss";

type TabBoxProps = {
  hex?: string;
  text?: string;
  onPress?: Function;
  isActive?: boolean;
  photoPath?: string;
};

const TabBox: FunctionComponent<TabBoxProps> = ({
  hex,
  text,
  onPress = () => {},
  isActive,
  photoPath,
}) => {
  return (
    <div
      className="tabBox"
      onClick={() => {
        onPress();
      }}
      style={{ backgroundColor: isActive ? "#43A9D6" : "#F7F8F9" }}
    >
      <p
        className="tabBox__text"
        style={{ color: isActive ? "#FFFFFF" : "#004469" }}
      >
        {text}
      </p>
    </div>
  );
};

export default TabBox;
