import { cookieKeys, cookies } from "Config";
const { apiGWToken, apiGWRefreshToken } = cookieKeys;

export const setApiGWCookies = (token: string, refreshToken?: string) => {
  cookies.set(apiGWToken.name, token, {
    ...apiGWToken.options,
    maxAge: 1800,
  });

  if (refreshToken) {
    cookies.set(apiGWRefreshToken.name, refreshToken, {
      ...apiGWRefreshToken.options,
      maxAge: 1800,
    });
  }
};

export const getApiGWTokenCookie = () => cookies.get(apiGWToken.name);
export const getApiGWRefreshTokenCookie = () =>
  cookies.get(apiGWRefreshToken.name);
