import React from "react";
import "./AggrementPopup.scss";
import { Button } from "Components";
import parse from "html-react-parser";

export interface IAggrementPopupProps {
  header: string;
  description: string;
  onClose: Function;
  button1Text: string;
  button2Text?: string;
  isVisibleButton2?: boolean;
  onButton2: Function;
}

const AggrementPopup: React.FunctionComponent<IAggrementPopupProps> = ({
  header,
  description,
  onClose = () => {},
  button1Text,
  button2Text,
  isVisibleButton2,
  onButton2 = () => {},
}) => {
  return (
    <div className="aggrement-popup">
      <div>
        <p className="aggrement-popup__header">{header}</p>
        <div className="aggrement-popup__line" />
        <p className="aggrement-popup__description">{parse(description)}</p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          text={button1Text}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          variant="contained"
          className="aggrement-popup__close"
        />
        {isVisibleButton2 && (
          <Button
            text={button2Text}
            onClick={(e) => {
              e.stopPropagation();
              onButton2();
            }}
            variant="outlined"
            className="aggrement-popup__close"
          />
        )}
      </div>
    </div>
  );
};

export default AggrementPopup;
