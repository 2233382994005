import * as React from 'react';

function LocationBlue(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>46DA3141-C6C4-43BC-98DB-FD066FB1FE84</title>
      <defs>
        <path
          d="M8,1.42108547e-14 C12.3872237,-0.000219366614 15.9561278,3.53299568 16,7.92 C16,13.4 9,19.5 8.65,19.76 C8.27577598,20.0800877 7.72422402,20.0800877 7.35,19.76 C7.05,19.5 0,13.4 0,7.92 C0.0438722314,3.53299568 3.61277632,-0.000219366614 8,1.42108547e-14 Z M8,4 C6.06700338,4 4.5,5.56700338 4.5,7.5 C4.5,9.43299662 6.06700338,11 8,11 C8.92825771,11 9.81849641,10.6312511 10.4748737,9.97487373 C11.1312511,9.31849641 11.5,8.42825771 11.5,7.5 C11.5,5.56700338 9.93299662,4 8,4 Z M8,6 C8.82842712,6 9.5,6.67157288 9.5,7.5 C9.5,8.32842712 8.82842712,9 8,9 C7.17157288,9 6.5,8.32842712 6.5,7.5 C6.5,6.67157288 7.17157288,6 8,6 Z"
          id="path-1"
        ></path>
      </defs>
      <g id="Hesabım---bize-ulaşın" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop---Bize-Ulaşın---1.1" transform="translate(-461.000000, -486.000000)">
          <g id="list/withline/icon+title+description+chevron/1" transform="translate(445.000000, 453.000000)">
            <g id="icon/location" transform="translate(16.000000, 33.000000)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <g id="brand/color/secondary" transform="translate(4.000000, 2.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#path-1"></use>
                <g id="brand/color_cards/blue/2" mask="url(#mask-2)" fill="#1AAADC">
                  <g transform="translate(-4.000000, -2.000000)" id="brand/color/1st/333333">
                    <rect x="0" y="0" width="24" height="24"></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LocationBlue;
