import React, { useState, useEffect, useRef } from "react";
import "./Personalization.scss";
import { Input, Checkbox, Button, MaskInput } from "Components";
import { Close } from "Icons";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Images from "Assets/Images";
import { Formik, Form, FormikProps } from "formik";
import PersonalizationSchema from "Validations/PersonalizationSchema";
import { useLocalization, useWindowDimensions } from "Hooks";
import {
  checkCustomerInformationRequest,
  approveKVKKAgreementRequest,
} from "Store/checkCustomerInformationDemo";
import { useDispatch } from "react-redux";
import { makeLink, belbimcercevesozlesme, aydinlatmaMetniHtml } from "Utils";
import { AggrementPopup } from "./AggrementPopup";
import { CardNumberInfoPopup } from "./CardNumberInfoPopup";
export interface IPage1Props {
  title: string;
}

interface IFormikProps {
  tckn: string;
  ad: string;
  soyad: string;
  dogumTarihi: string;
  eposta: string;
  kartNum: string;
  telNo: string;
  kod: string;
  agreement1: boolean;
  agreement2: boolean;
  agreement3: boolean;
  agreement4: boolean;
  agreement5: boolean;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 12,
    height: 606,
    width: 550,
  },

  paperTiny: {
    position: "absolute",
    width: 270,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 12,
    height: 690,
  },
  close: {
    position: "absolute",
    right: 24,
    top: 24,
  },
  title: {
    fontFamily: "Mark Pro",
    fontWeight: 700,
    fontSize: 24,
    textAlign: "center",
    letterSpacing: -0.6,
    color: "#383C52",
    marginTop: 50,
  },
  content: {
    fontFamily: "Mark Pro",
    fontWeight: 400,
    fontSize: 16,
    textAlign: "center",
    letterSpacing: -0.4,
    marginTop: 30,
    color: "#134A63",
  },
  cardContainer: {
    backgroundColor: "#F5F5F6",
    borderRadius: 12,
    marginBottom: 50,
    padding: 18,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  tabContainer: {
    display: "flex",
    flexDirection: "row",
  },
  selectTabText: {
    fontFamily: "Mark Pro",
    fontWeight: 400,
    fontSize: 15,
    textAlign: "center",
    letterSpacing: -0.35,
    color: "#383C52",
    paddingLeft: 22,
    paddingRight: 22,
    marginTop: 0,
    backgroundColor: "white",
    padding: 10,
    borderRadius: 8,
    cursor: "pointer",
  },

  unselectTabText: {
    fontFamily: "Mark Pro",
    fontWeight: 400,
    fontSize: 15,
    textAlign: "center",
    letterSpacing: -0.35,
    color: "#9B9DA8",
    paddingLeft: 22,
    paddingRight: 22,
    marginTop: 0,
    padding: 10,
    cursor: "pointer",
  },
}));

const Personalization: React.FunctionComponent<IPage1Props> = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<IFormikProps>>(null);
  const localization = useLocalization();
  const makeid = (length: number) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };
  const { width } = useWindowDimensions();
  const [selectedTab, setSelectedTab] = useState("new");
  const [verificationCode, setVerificationCode] = useState(makeid(6));
  const [openModal, setOpenModal] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const classes = useStyles();
  const [showPopup, setShowPopup] = useState(false);
  const [modalStyle, setModalStyle] = React.useState(getModalStyle);
  const [aggrementData, setAggrementData] = useState({
    header: "",
    description: "",
    aggrementType: "",
  });
  function getModalStyle() {
    return {
      top: width < 800 ? 2 : "15%",
      left: width <= 600 ? (width - 325) / 2 : width < 800 ? "10%" : "26%",
    };
  }

  useEffect(() => {
    setModalStyle(getModalStyle);
  }, [width]);
  return (
    <div className="personalization pageContainer" id="personalization">
      {showPopup && (
        //@ts-ignore
        <AggrementPopup
          header={aggrementData?.header}
          description={aggrementData?.description}
          button1Text={
            aggrementData?.aggrementType === "agreement1"
              ? localization?.personalization_form_clarification_text_button
              : localization?.personalization_terms_of_use_text_agree_button
          }
          button2Text={
            aggrementData?.aggrementType === "agreement1"
              ? ""
              : localization?.personalization_explicit_consent_text_disagree_button
          }
          isVisibleButton2={!(aggrementData?.aggrementType === "agreement1")}
          onClose={() => {
            formRef?.current?.setFieldValue(aggrementData?.aggrementType, true);
            setShowPopup(false);
          }}
          onButton2={() => {
            formRef?.current?.setFieldValue(
              aggrementData?.aggrementType,
              false
            );
            setShowPopup(false);
          }}
        />
      )}
      <h1 className="personalization__title">
        {localization?.online_transactions_personalization_page_header}
      </h1>
      <h6
        className="personalization__description"
        dangerouslySetInnerHTML={{
          __html: makeLink(
            localization?.online_transactions_personalization_page_desc
          ),
        }}
      ></h6>
      <Formik
        innerRef={formRef}
        initialValues={{
          tckn: "",
          ad: "",
          soyad: "",
          dogumTarihi: "",
          eposta: "",
          kartNum: "",
          telNo: "",
          kod: "",
          agreement1: false,
          agreement2: false,
          agreement3: false,
          agreement4: false,
          agreement5: false,
        }}
        validationSchema={PersonalizationSchema}
        onSubmit={(values) => {
          // same shape as initial values
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          isValid,
          setFieldValue,
          handleBlur,
        }) => (
          <Form className="personalization__form">
            <Input
              labelText={
                localization?.online_transactions_personalization_form_tckn
              }
              typeInput="tel"
              value={values.tckn}
              handleBlur={handleBlur("tckn")}
              style={{ width: "100%" }}
              onChangeText={(val: string) => {
                const result = val.replace(
                  /[a-z-iöğüşçİı" "!"é#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/gi,
                  ""
                );
                if (val.length <= 11) {
                  setFieldValue("tckn", result);
                  handleChange("tckn");
                }
              }}
              errorMessage={touched.tckn && errors.tckn}
            />
            <Input
              labelText={
                localization?.online_transactions_personalization_form_name
              }
              typeInput="text"
              value={values.ad}
              handleBlur={handleBlur("ad")}
              onChangeText={(val: string) => {
                const result = val.replace(/[^a-ziöğüşç ]/gi, "");
                if (val.length <= 40) {
                  setFieldValue("ad", result);
                  handleChange("ad");
                }
              }}
              style={{ width: "100%" }}
              errorMessage={touched.ad && errors.ad}
            />
            <Input
              labelText={
                localization?.online_transactions_personalization_form_surname
              }
              typeInput="text"
              handleBlur={handleBlur("soyad")}
              style={{ width: "100%" }}
              value={values.soyad}
              onChangeText={(val: string) => {
                const result = val.replace(/[^a-ziöğüşç ]/gi, "");
                if (val.length <= 40) {
                  setFieldValue("soyad", result);
                  handleChange("soyad");
                }
              }}
              errorMessage={touched.soyad && errors.soyad}
            />
            <MaskInput
              labelText={
                localization?.online_transactions_personalization_form_birth_date
              }
              maskType="99.99.9999"
              style={{ width: "100%" }}
              value={values.dogumTarihi}
              handleBlur={handleBlur("dogumTarihi")}
              onChangeText={handleChange("dogumTarihi")}
              errorMessage={touched.dogumTarihi && errors.dogumTarihi}
            />
            <Input
              labelText={
                localization?.online_transactions_personalization_form_email
              }
              typeInput="text"
              style={{ width: "100%" }}
              value={values.eposta}
              handleBlur={handleBlur("eposta")}
              onChangeText={handleChange("eposta")}
              errorMessage={touched.eposta && errors.eposta}
            />
            <div className="personalization__relative">
              <div style={{}}>
                <Input
                  labelText={
                    localization?.online_transactions_personalization_form_card_no
                  }
                  typeInput="text"
                  style={{ width: "100%" }}
                  value={values.kartNum}
                  handleBlur={handleBlur("kartNum")}
                  onChangeText={(val: string) => {
                    const result = val.replace(
                      /[!"é#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/gi,
                      ""
                    );
                    if (val.length <= 16) {
                      setFieldValue("kartNum", result);
                      handleChange("kartNum");
                    }
                  }}
                  isQuestionMark={true}
                  isTooltip={true}
                  errorMessage={touched.kartNum && errors.kartNum}
                  openPopover={openPopover}
                  onClickTooltip={() => {
                    setOpenModal(!openModal);
                  }}
                  onMouseOverTooltip={() => {
                    setOpenPopover(true);
                  }}
                  onMouseLeaveTooltip={() => {
                    setOpenPopover(false);
                  }}
                />
              </div>
            </div>
            <Input
              labelText={
                localization?.online_transactions_personalization_form_cell_phone
              }
              typeInput="tel"
              suffix={"+90"}
              handleBlur={handleBlur("telNo")}
              value={values.telNo}
              style={{ width: "100%" }}
              onChangeText={(val: string) => {
                const result = val.replace(
                  /[a-z-iöğüşçİı" "é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/gi,
                  ""
                );
                if (val.length <= 10) {
                  setFieldValue("telNo", result);
                  handleChange("telNo");
                }
              }}
              errorMessage={touched.telNo && errors.telNo}
            />
            <div className="personalization__codeContainer">
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  flexDirection: "column",
                  alignSelf: "flex-start",
                }}
              >
                <Input
                  labelText={
                    localization?.online_transactions_personalization_form_validation_code
                  }
                  typeInput="text"
                  value={values.kod}
                  handleBlur={handleBlur("kod")}
                  onChangeText={(val: string) => {
                    if (val.length <= 6) {
                      setFieldValue("kod", val);
                    } else if (val.length === 6 && verificationCode === val) {
                      handleChange("kod");
                    }
                  }}
                  errorMessage={touched.kod && errors.kod}
                  style={{ marginLeft: 0, width: "100%" }}
                  width={"96%"}
                />
              </div>

              <div className="personalization__verificationcode">
                <h4>{verificationCode}</h4>
              </div>
            </div>
            <div className="personalization__checkbox">
              <Checkbox
                label={localization?.personalization_form_clarify_text_link.replace(
                  "Aydınlatma Metni",
                  '<span style="color:#606375;font-weight:bold;text-decoration:underline;cursor:pointer">Aydınlatma Metni</span>'
                )}
                onClickLink={() => {
                  setAggrementData({
                    header:
                      "BELBİM A.Ş. KİŞİSELLEŞTİRİLMİŞ KART KULLANICILARI KİŞİSEL VERİLERİN İŞLENMESİ AYDINLATMA METNİ",
                    description: aydinlatmaMetniHtml,
                    aggrementType: "agreement1",
                  });
                  setShowPopup(true);
                }}
                checked={values.agreement1}
                errorText={errors.agreement1}
                onChange={(event) => {
                  setFieldValue("agreement1", event.target.checked);
                  handleChange("agreement1");
                }}
              />
              <Checkbox
                label={localization?.personalization_form_terms_of_use_link.replace(
                  "Kullanım Koşulları ve Çerçeve Sözleşmesi",
                  '<span style="color:#606375;font-weight:bold;text-decoration:underline;cursor:pointer">Kullanım Koşulları ve Çerçeve Sözleşmesi</span>'
                )}
                checked={values.agreement2}
                onClickLink={() => {
                  setAggrementData({
                    header: "Kullanım Koşulları ve Çerçeve Sözleşmesi",
                    description: belbimcercevesozlesme,
                    aggrementType: "agreement2",
                  });
                  setShowPopup(true);
                }}
                errorText={errors.agreement2}
                onChange={(event) => {
                  setFieldValue("agreement2", event.target.checked);
                  handleChange("agreement2");
                }}
              />
              <Checkbox
                label={localization?.personalization_form_explicit_consent_text.replace(
                  "Açık Rıza Metni",
                  '<span style="color:#606375;font-weight:bold;text-decoration:underline;cursor:pointer">Açık Rıza Metni</span>'
                )}
                onClickLink={() => {
                  setAggrementData({
                    header: "Açık Rıza Metni",
                    description:
                      'Profilleme, Analiz ve Diğer Pazarlama Faaliyetleri\nAd, soyad, alışveriş tercihleri vb. kişisel verilerimin Belbim Elektronik Para ve Ödeme Hizmetleri A.Ş. (“Belbim A.Ş.”) tarafından ürün/hizmetlere yönelik profilleme, analiz ve diğer pazarlama faaliyetlerinin yürütülmesi amacıyla https://www.istanbulkart.istanbul sitesinde yer alan "Aydınlatma Metni" uyarınca işlenmesine, gerekmesi halinde Belbim A.Ş. tarafından bu konuda hizmet alınan üçüncü taraflara aktarılmasına ve ayrıca aynı kapsamdaki faaliyetlerin Istanbul Büyükşehir Belediyesi, diğer iştirakleri ve bağlı kuruluşları tarafından yürütülebilmesi için verilerimin söz konusu şirketlere aktarılmasına izin veriyorum.',
                    aggrementType: "agreement3",
                  });
                  setShowPopup(true);
                }}
                checked={values.agreement3}
                errorText={errors.agreement3}
                onChange={(event) => {
                  setFieldValue("agreement3", event.target.checked);
                  handleChange("agreement3");
                }}
              />
              <Checkbox
                label={localization?.personalization_form_sms_perm_link.replace(
                  "SMS ile ticari elektronik ileti",
                  '<span style="color:#606375;font-weight:bold;text-decoration:underline;cursor:pointer">SMS ile ticari elektronik ileti</span>'
                )}
                checked={values.agreement4}
                errorText={errors.agreement4}
                onClickLink={() => {
                  setAggrementData({
                    header: "Ticari Elektronik İleti İzni (SMS)",
                    description:
                      'Belbim Elektronik Para ve Ödeme Hizmetleri A.Ş. (“Belbim A.Ş.”) ürün ve hizmetlerinin pazarlama ve tanıtımı, yenilik, kampanya ve promosyonlardan haberdar edilmem amacıyla tarafıma SMS yoluyla ticari elektronik ileti gönderilmesine cep telefonu numaramın bu amaçla <a href="https://www.istanbulkart.istanbul">https://www.istanbulkart.istanbul</a> sitesinde yer alan Aydınlatma Metni uyarınca işlenmesine ve gerekmesi halinde Belbim A.Ş. tarafından bu konuda hizmet alınan üçüncü taraflara aktarılmasına izin veriyorum.',
                    aggrementType: "agreement4",
                  });
                  setShowPopup(true);
                }}
                onChange={(event) => {
                  setFieldValue("agreement4", event.target.checked);
                  handleChange("agreement4");
                }}
              />
              <Checkbox
                label={localization?.personalization_form_email_perm_link.replace(
                  "E-posta ile ticari elektronik ileti",
                  '<span style="color:#606375;font-weight:bold;text-decoration:underline;cursor:pointer">E-posta ile ticari elektronik ileti</span>'
                )}
                checked={values.agreement5}
                errorText={errors.agreement5}
                onClickLink={() => {
                  setAggrementData({
                    header: "Ticari Elektronik İleti İzni (E-posta)",
                    description:
                      'Belbim Elektronik Para ve Ödeme Hizmetleri A.Ş. (“Belbim A.Ş.”) ürün ve hizmetlerinin pazarlama ve tanıtımı, yenilik, kampanya ve promosyonlardan haberdar edilmem amacıyla tarafıma SMS yoluyla ticari elektronik ileti gönderilmesine cep telefonu numaramın bu amaçla <a href="https://www.istanbulkart.istanbul">https://www.istanbulkart.istanbul</a> sitesinde yer alan "Aydınlatma Metni" uyarınca işlenmesine ve gerekmesi halinde Belbim A.Ş. tarafından bu konuda hizmet alınan üçüncü taraflara aktarılmasına izin veriyorum.',
                    aggrementType: "agreement5",
                  });
                  setShowPopup(true);
                }}
                onChange={(event) => {
                  setFieldValue("agreement5", event.target.checked);
                  handleChange("agreement5");
                }}
              />
              <Button
                disabled={!isValid}
                text={
                  localization?.online_transactions_personalization_form_button_desc
                }
                onClick={() => {
                  dispatch(checkCustomerInformationRequest());
                  dispatch(approveKVKKAgreementRequest());
                }}
                variant="contained"
                className="personalization__button"
              />
            </div>
          </Form>
        )}
      </Formik>
      {openModal && (
        <CardNumberInfoPopup
          selectedTab={selectedTab}
          setOpenModal={setOpenModal}
          openModal={openModal}
          setSelectedTab={setSelectedTab}
        />
      )}
    </div>
  );
};
export default Personalization;
