// @ts-nocheck
import { getCheckParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { CheckCustomerInformationDemoModel } from "./../../Models";

const url =
  "https://testadp.belbim.istanbul/EparaAdapter/api/RestAdapter/CheckCustomerInformationDemo";
const checkCustomerInfromation = async ({
  Name,
  Surname,
  Birthday,
  CitizenshipNumber,
  CardNumber,
  PhoneNumber,
  KVKKAgreementVersion,
  Mail,
  CommercialMessageStatus,
  PhoneCountryCode,
  SMS,
  EMAIL,
  ExcplictConsent,
}: getCheckParams): Promise<CheckCustomerInformationDemoModel.request> => {

  return (
    await axiosHelper({
      method: "post",
      url,
      data: {
        Name,
        Surname,
        Birthday,
        CitizenshipNumber,
        CardNumber,
        PhoneNumber,
        KVKKAgreementVersion,
        Mail,
        CommercialMessageStatus,
        PhoneCountryCode,
        SMS,
        EMAIL,
        ExcplictConsent,
      },
    })
  ).data;
};

export default checkCustomerInfromation;
