import * as React from "react";

function Rectangle386(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="44"
      height="7"
      viewBox="0 0 44 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="43.2475" height="7" fill="#43A9D6" />
    </svg>
  );
}

export default Rectangle386;
