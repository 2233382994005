import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetCityListModel } from "Models";
import { RootState } from "../index";
import { CityListState } from "./types";

export const initialState: CityListState = {
  loading: true,
  Cities: undefined,
};

export const cityListSlice = createSlice({
  name: "cityList",
  initialState,
  reducers: {
    onInit: (state) => {},
    citiesRequest: (state: CityListState) => {
      state.loading = true;
    },
    cityListRequestSuccess: (
      state: CityListState,
      action: PayloadAction<GetCityListModel["Cities"]>
    ) => {
      state.Cities = action.payload;
      state.loading = false;
    },
  },
});

export const { onInit, citiesRequest, cityListRequestSuccess } =
  cityListSlice.actions;

export const CityListStore = (state: RootState) => state.cityList;

export default cityListSlice.reducer;
