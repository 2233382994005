import { FunctionComponent } from "react";

const Close: FunctionComponent<React.SVGProps<SVGSVGElement>> =({fill, width, height}: any) => {
  return (
    <svg
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill={fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 20L20 4"
        stroke={fill ?? "#383C52"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 20L4 4"
        stroke={fill ?? "#383C52"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Close;
