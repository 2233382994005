import { axiosHelper } from "Services/AxiosHelper";
import { GetApiGWTokenModel } from "Models";

const getApiGWRefreshToken = async (
  refreshToken: string
): Promise<GetApiGWTokenModel> =>
  (
    await axiosHelper({
      method: "post",
      data: {
        command: "login.refreshToken",
        rid: refreshToken,
      },
    })
  ).data;

export default getApiGWRefreshToken;
