import { FunctionComponent } from "react";

const Time: FunctionComponent<React.SVGProps<SVGSVGElement>> = (props) => {
  const randomId = Math.floor(Math.random() * 1000) + "_" + Math.floor(Math.random() * 100);
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id={`mask0_535_23052${randomId ?? 0}`}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="10"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 6C1 3.23858 3.23858 1 6 1C7.32608 1 8.59785 1.52678 9.53553 2.46447C10.4732 3.40215 11 4.67392 11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6ZM6 6.5H8C8.27614 6.5 8.5 6.27614 8.5 6C8.5 5.72386 8.27614 5.5 8 5.5H6.5V4C6.5 3.72386 6.27614 3.5 6 3.5C5.72386 3.5 5.5 3.72386 5.5 4V6C5.5 6.27614 5.72386 6.5 6 6.5Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_535_23052${randomId ?? 0})`}>
        <rect width="12" height="12" fill={props?.fill ?? "#383C52"} />
      </g>
    </svg>
  );
};

export default Time;
