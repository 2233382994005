import { FunctionComponent } from "react";

const ChevronDown: FunctionComponent<React.SVGProps<SVGSVGElement>> = ({
  fill,
  width,
  height,
}: any) => {
  const randomId = Math.floor(Math.random() * 1000) + "_" + Math.floor(Math.random() * 100);
  return (
    <svg
      width={width ?? "16"}
      height={height ?? "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id={`mask0_1056_23394${randomId ?? 0}`}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="10"
        height="6"
      >
        <path
          d="M3.52864 5.52864C3.76896 5.28831 4.14711 5.26983 4.40864 5.47318L4.47145 5.52864L8.00004 9.05671L11.5286 5.52864C11.769 5.28831 12.1471 5.26983 12.4086 5.47318L12.4714 5.52864C12.7118 5.76896 12.7303 6.14711 12.5269 6.40864L12.4714 6.47145L8.47145 10.4714C8.23112 10.7118 7.85297 10.7303 7.59144 10.5269L7.52864 10.4714L3.52864 6.47145C3.26829 6.2111 3.26829 5.78899 3.52864 5.52864Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_1056_23394${randomId ?? 0})`}>
        <rect width="16" height="16" fill={fill ?? "#1AAADD"} />
      </g>
    </svg>
  );
};

export default ChevronDown;
