import { GetCityListModel } from "Models";
import { call, put, takeLatest } from "redux-saga/effects";
import { getCityList } from "Services/Queries";
import { citiesRequest, cityListRequestSuccess } from "./index";

function* handleCityListRequest() {
  try {
    const getCityListResult: GetCityListModel["Cities"] = yield call(() =>
      getCityList()
    );
    yield put(cityListRequestSuccess(getCityListResult));
  } catch (error) {}
}

function* citiesSaga() {
  yield takeLatest(citiesRequest, handleCityListRequest);
}

export default citiesSaga;
