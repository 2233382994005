import { FunctionComponent } from "react";
import "./PressColorBox.scss";

type PressColorBoxProps = {
  hex?: string;
  rgb?: string;
  cmyk?: string;
  text?: string;
};

const PressColorBox: FunctionComponent<PressColorBoxProps> = ({
  hex,
  rgb,
  cmyk,
  text,
}) => {
  return (
    <div className="press__colorbox" style={{backgroundColor:hex}}>
      <div className="press__colorbox__codes">
        <p className="press__colorbox__code">{hex}</p>
        <p className="press__colorbox__code">{cmyk}</p>
        <p className="press__colorbox__code">{rgb}</p>
      </div>
      <p className="press__colorbox__text">{text}</p>
    </div>
  );
};

export default PressColorBox;
