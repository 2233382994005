import { FunctionComponent } from "react";

const Pencil: FunctionComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#1AAADC" fillOpacity="0.1" />
      <rect opacity="0.01" x="8" y="8" width="48" height="48" fill="#0080BC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.3201 17.2L46.8001 22.68C48.3321 24.1395 48.3947 26.5633 46.9401 28.1L28.9401 46.1C28.2882 46.7465 27.4337 47.149 26.5201 47.24L18.1801 48H18.0001C17.4685 48.0031 16.9576 47.7944 16.5801 47.42C16.1582 46.9996 15.9456 46.4131 16.0001 45.82L16.8601 37.48C16.9511 36.5664 17.3536 35.7119 18.0001 35.06L36.0001 17.06C37.5518 15.7491 39.8396 15.8093 41.3201 17.2ZM34.6401 24L40.0001 29.36L44.0001 25.46L38.5401 20L34.6401 24Z"
        fill="#0080BC"
      />
      <mask
        id="mask0_412_11155"
        maskUnits="userSpaceOnUse"
        x="15"
        y="16"
        width="33"
        height="32"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.3201 17.2L46.8001 22.68C48.3321 24.1395 48.3947 26.5633 46.9401 28.1L28.9401 46.1C28.2882 46.7465 27.4337 47.149 26.5201 47.24L18.1801 48H18.0001C17.4685 48.0031 16.9576 47.7944 16.5801 47.42C16.1582 46.9996 15.9456 46.4131 16.0001 45.82L16.8601 37.48C16.9511 36.5664 17.3536 35.7119 18.0001 35.06L36.0001 17.06C37.5518 15.7491 39.8396 15.8093 41.3201 17.2ZM34.6401 24L40.0001 29.36L44.0001 25.46L38.5401 20L34.6401 24Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_412_11155)">
        <rect
          x="5.3335"
          y="5.33334"
          width="53.3333"
          height="53.3333"
          fill="#0080BC"
        />
      </g>
    </svg>
  );
};

export default Pencil;
