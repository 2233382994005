import { GetApiGWTokenModel, GetCmsModel } from "Models";
import { call, delay, put, takeLatest, select } from "redux-saga/effects";
import { getApiGWRefreshToken, getApiGWToken, getCms } from "Services/Queries";
import {
  getApiGWRefreshTokenCookie,
  getApiGWTokenCookie,
  setApiGWCookies,
  languageId,
  makeLink,
} from "Utils";
import {
  apiGWTokenRequest,
  cmsRequest,
  cmsRequestSuccess,
  onInit,
  cmsDataForContactPage,
} from "./index";
import { globalStore } from "Store/global";
import { getActivityReportsRequest } from "Store/getActivityReports";

function* appInit() {}

function* handleCmsRequest() {
  try {
    const userGWToken = getApiGWTokenCookie();
    if (!userGWToken) {
      yield put(apiGWTokenRequest());
      yield delay(1500);
    }
    let getCmsResult: GetCmsModel = yield call(() =>
      getCms({ languageId: languageId() })
    );
    yield put(
      cmsDataForContactPage({
        contact_belbim_office_address:
          getCmsResult.CmsKeys.contact_belbim_office_address,
        contact_controller_address:
          getCmsResult.CmsKeys.contact_controller_address,
      })
    );

    localStorage.setItem("CmsKeys", JSON.stringify(getCmsResult));
    for (let key in getCmsResult.CmsKeys) {
      if (typeof getCmsResult.CmsKeys[key] === "string") {
        getCmsResult.CmsKeys[key] = makeLink(
          getCmsResult.CmsKeys[key] as string
        );
      }
    }
    yield put(cmsRequestSuccess(getCmsResult));
    yield put(getActivityReportsRequest());
  } catch (error) {
    // yield put(
    //   showToastify({
    //     toastMessage: staticErrorMessage,
    //     type: 'error',
    //   })
    // );
  }
}

function* handleOnApiGWTokenRequest() {
  const tokenCookie = getApiGWTokenCookie();
  const refreshTokenCookie = getApiGWRefreshTokenCookie();

  if (!tokenCookie) {
    if (refreshTokenCookie) {
      try {
        const getApiGwRefreshTokenResult: GetApiGWTokenModel = yield call(() =>
          getApiGWRefreshToken(refreshTokenCookie)
        );

        setApiGWCookies(getApiGwRefreshTokenResult.token);
      } catch (error) {
        //yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
      }
    } else {
      try {
        const getApiGwTokenResult: GetApiGWTokenModel = yield call(
          getApiGWToken
        );
        const { token, refreshToken } = getApiGwTokenResult;

        setApiGWCookies(token, refreshToken);
      } catch (error) {
        // ((yield put(showToastify({ toastMessage: staticErrorMessage, type: 'error' }));
      }
    }
  }
}

function* globalSaga() {
  yield takeLatest(onInit, appInit);
  yield takeLatest(cmsRequest, handleCmsRequest);
  yield takeLatest(apiGWTokenRequest, handleOnApiGWTokenRequest);
}

export default globalSaga;
