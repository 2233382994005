import React from "react";
import "./OnlineTransactions.scss";
import { ListBlue, Pencil, ProfileBlue } from "Icons";
import Card from "./Card";
import { useLocalization } from "Hooks";

export interface IPage1Props {
  title: string;
}

const OnlineTransactions: React.FunctionComponent<IPage1Props> = (props) => {
  const localization = useLocalization();
  const goUrl = (url: string) => window.open(url, "_blank");

  return (
    <div id="onlineTransactions" className="pageContainer">
      <div className="onlineTransactions">
        <h5 className="onlineTransactions__title">
          {localization?.belbim_online_transactions_header}
        </h5>
        <div className="onlineTransactions__container">
          <Card
            title={
              localization?.online_transactions_individual_transactions_header
            }
            TopIcon={ListBlue}
            item1={
              localization?.online_transactions_individual_transactions_desc
            }
            onPressTitle={() =>
              goUrl(process.env.REACT_APP_API_BIREYSEL_URL as string)
            }
          />
          <Card
            title={
              localization?.online_transactions_institutional_transactions_header
            }
            TopIcon={Pencil}
            item1={
              localization?.online_transactions_institutional_transactions_desc
            }
            onPressTitle={() =>
              goUrl(process.env.REACT_APP_API_B2B_URL as string)
            }
          />
          <Card
            title={localization?.online_transactions_personalization_header}
            TopIcon={ProfileBlue}
            item1={localization?.online_transactions_personalization_desc}
            isNew={true}
            onPressTitle={() =>
              goUrl(process.env.REACT_APP_API_KISISELLESTIRME_URL as string)
            }
          />
        </div>
      </div>
    </div>
  );
};
export default OnlineTransactions;
