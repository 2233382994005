import { FC, memo } from "react";
import { Box, Typography } from "@material-ui/core";
import "./MediaMaterials.scss";
import { PressColorBox, PressImageBox } from "Components";
import { BelbimLogo } from "Icons";
import Images from "Assets/Images";
import { Link } from "react-router-dom";
import { useLocalization } from "Hooks";
import { makeLink } from "Utils";

type MediaMaterialsProps = {
  title: string;
};

const MediaMaterials: FC<MediaMaterialsProps> = memo((props) => {
  const localization = useLocalization();
  return (
    <div id="mediaMaterials" className="pageContainer">
      <Typography variant="h5" align="center">
        <Box fontWeight={700} className="mediaMaterials__title">
          {localization?.press_materials_page_header}
        </Box>
      </Typography>
      <Box className="mediaMaterials__content">
        {/* İstanbul Kart start */}
        <Typography
          variant="h5"
          align="left"
          className="mediaMaterials__mini_title"
        >
          {localization?.press_materials_belbim_logo_header}
        </Typography>
        <Box className="mediaMaterials__logoContainer">
          <PressImageBox
            image={localization?.belbim_press_materials_new_visual1}
            title={localization?.belbim_press_materials_belbim_blue_logo_desc}
            linkName={localization?.press_materials_belbim_blue_logo_link_desc}
            downloadPath={localization?.belbim_press_materials_new_visual1}
          />

          <PressImageBox
            image={localization?.belbim_press_materials_new_visual2}
            title={localization?.belbim_press_materials_ibb_logo_desc}
            linkName={localization?.belbim_press_materials_ibb_logo_link_desc}
            downloadPath={localization?.belbim_press_materials_new_visual2}
          />
          <PressImageBox
            image={localization?.belbim_press_materials_new_visual3}
            title={localization?.press_materials_ibb_belbim_logo_desc}
            linkName={localization?.press_materials_ibb_belbim_logo_link_desc}
            downloadPath={localization?.belbim_press_materials_new_visual3}
          />
        </Box>
        {/* İstanbul Kart end */}
        {/* İstanbulkart Logo Birincil Renk Kullanımı start */}
        <Typography
          variant="h5"
          align="left"
          className="mediaMaterials__mini_title"
        >
          {localization?.press_materials_belbim_color_usage_header}
        </Typography>
        <Typography
          variant="body1"
          align="left"
          className="mediaMaterials__text"
          dangerouslySetInnerHTML={{
            __html: makeLink(
              localization?.press_materials_belbim_color_usage_desc
            ),
          }}
        ></Typography>
        <Box className="mediaMaterials__colorContainer">
          <PressColorBox
            hex="#003D4C"
            cmyk="CMYK: 100, 18, 0, 65"
            rgb=" RGB: 0, 61, 76"
            text={localization?.press_materials_belbim_instituonal_colors_desc1}
          />
          <PressColorBox
            hex="#8DB9CA"
            cmyk="CMYK: 43, 0, 0, 23"
            rgb=" RGB: 141, 185, 202"
            text={localization?.press_materials_belbim_instituonal_colors_desc2}
          />
        </Box>
        {/* İstanbulkart Logo Birincil Renk Kullanımı end */}
        <div className="mediaMaterials__button">
          <Link
            to="#"
            onClick={() => {
              let newWindow: any = window.open();
              newWindow.location =
                "https://istanbulkart-istanbul-website.web.app/media-materials";
            }}
          >
            {localization?.press_materials_istanbulkart_materials_button_desc}
          </Link>
        </div>
      </Box>
    </div>
  );
});

export default MediaMaterials;
