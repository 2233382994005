import { FunctionComponent } from "react";
import {
  ButtonProps as MaterialUIButtonProps,
  Button as MaterialUIButton,
} from "@material-ui/core";
import "./Button.scss";
import classNames from "classnames";

type ButtonProps = {
  text?: string;
  isDisabled?: boolean;
  light?: boolean;
  marginTop?: number | string;
  maxWidth?: string;
  backgroundColor?: string;
} & MaterialUIButtonProps;

const Button: FunctionComponent<ButtonProps> = ({
  children,
  text,
  ...props
}) => {
  return (
    <MaterialUIButton
      className={classNames({
        app__button: true,
      })}
      {...props}
      type={props.disabled ? undefined : props.type ?? "submit"}
      variant={props.variant ?? "outlined"}
      onClick={props.disabled ? () => {} : props.onClick}
    >
      {text}
      {children}
    </MaterialUIButton>
  );
};

export default Button;
