import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { SecurityState } from "./types";
import GetSecurityModel from "Models/GetSecurity";

export const initialState: SecurityState = {
  loading: true,
  Security: [],
};

export const securitySlice = createSlice({
  name: "security",
  initialState,
  reducers: {
    onInit: (state) => {},
    SecurityRequest: (state: SecurityState, action: PayloadAction<string>) => {
      state.loading = true;
    },
    SecurityRequestSuccess: (
      state: SecurityState,
      action: PayloadAction<GetSecurityModel>
    ) => {
      state.Security = action.payload.SecurityPageContentList;
      state.loading = false;
    },
  },
});

export const { onInit, SecurityRequest, SecurityRequestSuccess } =
  securitySlice.actions;

export const securityStore = (state: RootState) => state.faqs;

export default securitySlice.reducer;
