import { Box, Typography } from "@material-ui/core";
import { ProductAndService } from "Models/ProductsAndServices";
import { makeLink } from "Utils";
import { FunctionComponent } from "react";
import "./DetailScreen.scss";

type DetailScreenProps = {
  data: ProductAndService;
};

const DetailScreen: FunctionComponent<DetailScreenProps> = ({ data }) => {
  return (
    <div className="detailScreen">
      <div className="detailScreen__container">
        <div className="detailScreen__container__left">
          <Typography variant="h2" align="center">
            <Box
              fontWeight={700}
              className="detailScreen__title"
              dangerouslySetInnerHTML={{ __html: data?.Title }}
            ></Box>
          </Typography>
          <p
            className="detailScreen__content"
            dangerouslySetInnerHTML={{
              __html: data?.Description,
            }}
          ></p>
          <div className="group-buttons">
            {data.ButtonText1 && (
              <div className="processingPoints__button">
                <a
                  href={data.ButtonUrl1}
                  download={data.ButtonText1}
                  dangerouslySetInnerHTML={{ __html: data.ButtonText1 }}
                  target="_blank"
                ></a>
              </div>
            )}
            {data.ButtonText2 && (
              <div className="processingPoints__button">
                <a
                  href={data.ButtonUrl2}
                  download={data.ButtonText2}
                  dangerouslySetInnerHTML={{ __html: data.ButtonText2 }}
                  target="_blank"
                ></a>
              </div>
            )}
          </div>
          <div className="group-buttons">
            {data.ButtonText3 && (
              <div className="processingPoints__button">
                <a
                  href={data.ButtonUrl3}
                  download={data.ButtonUrl3}
                  dangerouslySetInnerHTML={{ __html: data.ButtonText3 }}
                  target="_blank"
                ></a>
              </div>
            )}
            {data.ButtonText4 && (
              <div className="processingPoints__button">
                <a
                  href={data.ButtonUrl4}
                  dangerouslySetInnerHTML={{ __html: data.ButtonText4 }}
                  download={data.ButtonUrl4}
                  target="_blank"
                ></a>
              </div>
            )}
          </div>
        </div>
       {data.DetailImageUrl && <div className="detailScreen__imageContainer">
          <Typography variant="h5" align="center">
            <Box
              fontWeight={700}
              className="detailScreen__header"
              dangerouslySetInnerHTML={{ __html: data?.Title }}
            ></Box>
          </Typography>
          <img
            loading="lazy"
            src={data.DetailImageUrl}
            className="detailScreen__image"
          />
        </div>}
      </div>
      {data?.BannerText && (
        <div className="detailScreen__container__banner">
          <Typography variant="h2" align="center">
            <Box
              fontWeight={700}
              className="banner_title"
              dangerouslySetInnerHTML={{ __html: data?.BannerText }}
            ></Box>
          </Typography>
          <img
            loading="lazy"
            src={data.BannerImageUrl}
            className="bannerImage"
          />
        </div>
      )}
    </div>
  );
};

export default DetailScreen;
