import { FunctionComponent } from "react";

const QuestionMark: FunctionComponent<React.SVGProps<SVGSVGElement>> =({fill}: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_407_12988"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18ZM13 14V12.84C14.7289 12.3245 15.7827 10.5794 15.434 8.80939C15.0853 7.03935 13.4483 5.82434 11.6531 6.00314C9.85791 6.18194 8.49274 7.69595 8.5 9.5C8.5 10.0523 8.94771 10.5 9.5 10.5C10.0523 10.5 10.5 10.0523 10.5 9.5C10.5 8.67157 11.1716 8 12 8C12.8284 8 13.5 8.67157 13.5 9.5C13.5 10.3284 12.8284 11 12 11C11.4477 11 11 11.4477 11 12V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_407_12988)">
        <rect width="24" height="24" fill={fill ? fill :"#1AAADD"} />
      </g>
    </svg>
  );
};

export default QuestionMark;
