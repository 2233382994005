import { Box, Typography } from "@material-ui/core";
import { FC, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { A11y, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import { TabBox } from "Components";
import { useLocalization, useWindowDimensions } from "Hooks";
import { ChevronDarkRightIcon } from "Icons";
import { LegalTextPageContent } from "Models/GetIstanbulkartLegalTextPageModel";
import { getIstanbulkartLegalTextPage } from "Store/getIstanbulkartLegalTextPage";
import { fontSizes } from "Theme/Variables";
import "./LegalText.scss";
import SectionTab from "./SectionTab";

interface LegalTextProps {
  title: string;
}
export interface GroupedLegalTexts {
  categoryName: string;
  items: LegalTextPageContent[];
}

const LegalText: FC<LegalTextProps> = memo(() => {
  const dispatch = useDispatch();
  const localization = useLocalization();
  const [activeTab, setActiveTab] = useState(0);
  const legalTextList = useSelector(
    (state: any) => state.getIstanbulkartLegalTextPage.list
  );
  const windowWidth = useWindowDimensions().width;

  useEffect(() => {
    dispatch(getIstanbulkartLegalTextPage());
  }, [dispatch]);

  const title =
    activeTab === 4
      ? localization?.legal_text_todeb_tab_header
      : localization?.legal_texts_page_title;
  const groupedLegalTexts = groupLegalTextsByCategory(legalTextList);

  return (
    <div id="ltcontainer" className="pageContainer">
      {windowWidth > 959 && (
        <TabList
          tabs={groupedLegalTexts}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}

      <div id="legaltext">
        {title ? (
          <Typography variant="h5" align="center">
            <Box fontWeight={700} className="legaltext__title">
              {title}
            </Box>
          </Typography>
        ) : null}

        {useWindowDimensions().width <= 959 && (
          <div
            className="legaltext"
            style={{
              display: "flex",
              // marginTop: -80,
            }}
          >
            <MobileTabList
              tabs={groupedLegalTexts}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        )}

        {groupedLegalTexts.length > 0 &&
          groupedLegalTexts[activeTab].items.map(
            (item: LegalTextPageContent, index: number) => (
              <LegalTextItem
                key={index}
                id={index}
                title={item.FileName}
                fileUrl={item.FileUrl}
              />
            )
          )}
      </div>
    </div>
  );
});

const groupLegalTextsByCategory = (
  legalTextList: LegalTextPageContent[]
): GroupedLegalTexts[] => {
  const grouped = legalTextList.reduce(
    (
      acc: { [key: string]: LegalTextPageContent[] },
      item: LegalTextPageContent
    ) => {
      const categoryName = item.LegalTextCategory.Value;
      if (!acc[categoryName]) {
        acc[categoryName] = [];
      }
      acc[categoryName].push(item);
      return acc;
    },
    {}
  );

  return Object.keys(grouped).map((key) => ({
    categoryName: key,
    items: grouped[key],
  }));
};

const TabList: FC<{
  tabs: GroupedLegalTexts[];
  activeTab: number;
  setActiveTab: (index: number) => void;
}> = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {tabs.map((tab, index) => (
        <TabBox
          key={index}
          text={tab.categoryName}
          isActive={activeTab === index}
          onPress={() => setActiveTab(index)}
        />
      ))}
    </div>
  );
};

const MobileTabList: FC<{
  tabs: GroupedLegalTexts[];
  activeTab: number;
  setActiveTab: (index: number) => void;
}> = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <Swiper modules={[Pagination, A11y]} slidesPerView={3} pagination={false}>
      {tabs.map((tab, index) => (
        <SwiperSlide key={index}>
          <SectionTab
            text={tab.categoryName}
            isActive={activeTab === index}
            onPress={() => setActiveTab(index)}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const LegalTextItem: FC<{ title: string; fileUrl: string; id: number }> = memo(
  ({ title, fileUrl }) => {
    const handleClick = () => {
      window.open(fileUrl, "_blank");
    };

    return (
      <div className="legaltext__item" onClick={handleClick}>
        <Typography className="legaltext__text" variant="h6" align="left">
          <Box fontWeight="bold" fontSize={fontSizes.regular}>
            {title}
          </Box>
        </Typography>
        <ChevronDarkRightIcon />
      </div>
    );
  }
);

export default LegalText;
