import { Grid } from "@material-ui/core";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import "./Sitemap.scss";
import { routePath } from "Navigator/routes";
import { useSelector, useDispatch } from "react-redux";
import {
  setBireyselHizmetler,
  setKurumsalHizmetler,
  setSosyalHizmetler,
  setEuts,
  setIsDetailScreen,
} from "Store/global";
import { useLocalization } from "Hooks";

const Sitemap: FC = () => {
  const dispatch = useDispatch();
  const localization = useLocalization();
  return (
    <div id="sitemap">
      <div className="sitemap__head">
        <h1>{localization?.site_map_page_header}</h1>
      </div>
      <div className="pageContainer">
        <div className="links">
          <Grid container>
            <Grid item md={4}>
              <h3 className="row__title">
                {localization?.site_map_tab_institutional}
              </h3>
              <ul>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.institutional}
                    state={{ tab: "1", id: 0 }}
                  >
                    Başkanın Mesajı
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.institutional + "?page=general-manager"}
                    state={{ tab: "2", id: 1 }}
                  >
                    Genel Müdür
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.institutionalAboutUs}
                    state={{ tab: "3", subTab: "1", id: 2 }}
                  >
                    Hakkımızda
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.institutionalAboutUs}
                    state={{ tab: "3", subTab: "2", id: 2 }}
                  >
                    Vizyon/Misyon
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.institutionalAboutUs}
                    state={{ tab: "3", subTab: "3", id: 2 }}
                  >
                    Organizasyon Şeması
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.institutional + "?page=reports"}
                    state={{ tab: "4", id: 3 }}
                  >
                    Raporlar
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item md={4} xs={12}>
              <h3 className="row__title">
                {localization?.site_map_tab_product_and_services}
              </h3>
              <ul>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.productsAndServices}
                    state={{ tab: "1", id: 0 }}
                    onClick={() => {
                      dispatch(setBireyselHizmetler(true));
                      dispatch(setKurumsalHizmetler(false));
                      dispatch(setEuts(false));
                      dispatch(setSosyalHizmetler(false));
                      dispatch(setIsDetailScreen(false));
                    }}
                  >
                    Bireysel Hizmetler
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.productsAndServices}
                    state={{ tab: "2", id: 1 }}
                    onClick={() => {
                      dispatch(setBireyselHizmetler(false));
                      dispatch(setKurumsalHizmetler(true));
                      dispatch(setEuts(false));
                      dispatch(setSosyalHizmetler(false));
                      dispatch(setIsDetailScreen(false));
                    }}
                  >
                    Kurumsal Hizmetler
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.productsAndServices}
                    state={{ tab: "3", id: 2 }}
                    onClick={() => {
                      dispatch(setBireyselHizmetler(false));
                      dispatch(setKurumsalHizmetler(false));
                      dispatch(setEuts(true));
                      dispatch(setSosyalHizmetler(false));
                      dispatch(setIsDetailScreen(false));
                    }}
                  >
                    Ulaşımda EÜTS
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.productsAndServices}
                    state={{ tab: "4", id: 3 }}
                    onClick={() => {
                      dispatch(setBireyselHizmetler(false));
                      dispatch(setKurumsalHizmetler(false));
                      dispatch(setEuts(false));
                      dispatch(setSosyalHizmetler(true));
                      dispatch(setIsDetailScreen(false));
                    }}
                  >
                    Sosyal Hizmetler
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item md={4} xs={12}>
              <h3 className="row__title">
                {localization?.site_map_tab_trading_points}
              </h3>
              <ul>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.processingPoints}
                    state={{ tab: "1", id: 0 }}
                  >
                    Biletmatik
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.processingPoints}
                    state={{ tab: "2", id: 1 }}
                  >
                    İstanbul Başvuru Merkezleri
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.processingPoints}
                    state={{ tab: "3", id: 2 }}
                  >
                    Bireysel Temsilci
                  </Link>
                </li>
                {/* <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.processingPoints}
                    state={{ tab: "3", subTab: "1", id: 2 }}
                  >
                    Avantajlar
                  </Link>
                </li> */}
                {/* <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.processingPoints}
                    state={{ tab: "3", subTab: "2", id: 2 }}
                  >
                    Başvuru
                  </Link>
                </li> */}
                {/* <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.processingPoints}
                    state={{ tab: "3", subTab: "3", id: 2 }}
                  >
                    Uygulama Süreci
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.processingPoints}
                    state={{ tab: "3", subTab: "4", id: 2 }}
                  >
                    SSS
                  </Link>
                </li> */}
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.processingPoints}
                    state={{ tab: "4", id: 3 }}
                  >
                    Kurumsal Temsilci
                  </Link>
                </li>
                <li>
                  <span className="row__circle"></span>
                  <Link
                    to={routePath.processingPoints}
                    state={{ tab: "5", id: 4 }}
                  >
                    Üye İş Yeri
                  </Link>
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
