import LegalTextPageContentList from "Models/GetIstanbulkartLegalTextPageModel";
import getIstanbulkartLegalTextPageService from "Services/Queries/GetIstanbulkartLegalTextPageService";
import { call, put, takeLatest } from "redux-saga/effects";
import { getIstanbulkartLegalTextPage, legalTextList } from "./index";

function* getIstanbulkartLegalTextPageRequest() {
  try {
    const getIstanbulkartFindContentResult: LegalTextPageContentList =
      yield call(() => getIstanbulkartLegalTextPageService());
    yield put(
      legalTextList(getIstanbulkartFindContentResult?.LegalTextPageContentList)
    );
  } catch (error) {}
}

function* istanbulkartFindContentSaga() {
  yield takeLatest(
    getIstanbulkartLegalTextPage,
    getIstanbulkartLegalTextPageRequest
  );
}

export default istanbulkartFindContentSaga;
