import { FunctionComponent } from "react";

const EyeOn: FunctionComponent<React.SVGProps<SVGSVGElement>> = (
  props: any
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92898 19.0711C1.02373 15.1658 1.02373 8.83418 4.92898 4.92893C6.80434 3.05357 9.34788 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C22.9764 8.83418 22.9764 15.1658 19.0711 19.0711C15.1659 22.9763 8.83422 22.9763 4.92898 19.0711ZM13.5557 12L15.1113 10.4444C15.5409 10.0148 15.5409 9.31831 15.1113 8.88873C14.6817 8.45915 13.9853 8.45915 13.5557 8.88873L12 10.4444L10.4444 8.88873C10.0148 8.45915 9.31835 8.45915 8.88878 8.88873C8.4592 9.31831 8.4592 10.0148 8.88878 10.4444L10.4444 12L8.88878 13.5556C8.4592 13.9852 8.4592 14.6817 8.88878 15.1113C9.31835 15.5408 10.0148 15.5408 10.4444 15.1113L12 13.5556L13.5557 15.1113C13.9853 15.5408 14.6817 15.5408 15.1113 15.1113C15.5409 14.6817 15.5409 13.9852 15.1113 13.5556L13.5557 12Z"
        fill="#004469"
      />
      <mask
        id="mask0_1666_890"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.92898 19.0711C1.02373 15.1658 1.02373 8.83418 4.92898 4.92893C6.80434 3.05357 9.34788 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C22.9764 8.83418 22.9764 15.1658 19.0711 19.0711C15.1659 22.9763 8.83422 22.9763 4.92898 19.0711ZM13.5557 12L15.1113 10.4444C15.5409 10.0148 15.5409 9.31831 15.1113 8.88873C14.6817 8.45915 13.9853 8.45915 13.5557 8.88873L12 10.4444L10.4444 8.88873C10.0148 8.45915 9.31835 8.45915 8.88878 8.88873C8.4592 9.31831 8.4592 10.0148 8.88878 10.4444L10.4444 12L8.88878 13.5556C8.4592 13.9852 8.4592 14.6817 8.88878 15.1113C9.31835 15.5408 10.0148 15.5408 10.4444 15.1113L12 13.5556L13.5557 15.1113C13.9853 15.5408 14.6817 15.5408 15.1113 15.1113C15.5409 14.6817 15.5409 13.9852 15.1113 13.5556L13.5557 12Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1666_890)">
        <rect width="24" height="24" fill="#004469" />
      </g>
    </svg>
  );
};

export default EyeOn;
