import { FunctionComponent } from "react";

const RightArrowGray: FunctionComponent = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_353_10994"
        maskUnits="userSpaceOnUse"
        x="6"
        y="3"
        width="6"
        height="10"
      >
        <path
          d="M6.45857 3.52864C6.69889 3.28831 7.07704 3.26983 7.33857 3.47318L7.40138 3.52864L11.4014 7.52864C11.6417 7.76896 11.6602 8.14711 11.4568 8.40864L11.4014 8.47145L7.40138 12.4714C7.14103 12.7318 6.71892 12.7318 6.45857 12.4714C6.21825 12.2311 6.19976 11.853 6.40311 11.5914L6.45857 11.5286L9.98664 8.00004L6.45857 4.47145C6.21825 4.23112 6.19976 3.85297 6.40311 3.59144L6.45857 3.52864Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_353_10994)">
        <rect x="0.929993" width="16" height="16" fill="#B9C2CA" />
      </g>
    </svg>
  );
};

export default RightArrowGray;
