import React, { useState } from "react";
import { OrangeFavorite } from "Icons";
import { Link } from "react-router-dom";
import "./Card.scss";
import { makeLink } from "Utils";
import parse from "html-react-parser";

export interface ICardProps {
  TopIcon: Function;
  title: string;
  item1?: any;
  isNew?: boolean;
  onPressTitle: Function;
  toPathItem1?: string;
  toPath?: string;
}

const Card: React.FunctionComponent<ICardProps> = ({
  TopIcon,
  title,
  item1,
  isNew = false,
  onPressTitle = () => {},
  toPathItem1,
  toPath,
}) => {
  const [titleUnderline, setTitleUnderline] = useState(false);
  const listItem1 = item1?.split("#") ?? "";
  return (
    <div className="card">
      <div className="card__content__newContainer">
        <TopIcon />
        {isNew && (
          <h5 className="card__content__new">
            Yeni
            <div style={{ width: 8 }} /> <OrangeFavorite />
          </h5>
        )}
      </div>
      <div className="card__content">
        <Link
          className="card__content__title"
          onMouseOver={() => {
            setTitleUnderline(true);
          }}
          onMouseLeave={() => {
            setTitleUnderline(false);
          }}
          style={{
            textDecoration: titleUnderline ? "underline" : "none",
          }}
          onClickCapture={() => {
            onPressTitle();
          }}
          to={toPath ? toPath : "#"}
        >
          {title}
        </Link>
        {listItem1 &&
          listItem1.map((item: any, i: any) => {
            return (
              <Link to={toPathItem1 ?? "#"} className="card__content__content">
                <div>{item && <div className="card__content__circle" />}</div>
                <div>{item ? parse(makeLink(item)) : ""}</div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};
export default Card;
