import * as React from "react";

function XIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="black" />
      <g clip-path="url(#clip0_2247_1923)">
        <mask
          id="mask0_2247_1923"
          maskUnits="userSpaceOnUse"
          x="9"
          y="9"
          width="22"
          height="22"
        >
          <path d="M9 9H31V31H9V9Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2247_1923)">
          <path
            d="M26.325 10.0309H29.6989L22.3289 18.4757L31 29.9691H24.2114L18.8906 22.9999L12.8091 29.9691H9.43214L17.3144 20.9334L9 10.0324H15.9614L20.7637 16.4014L26.325 10.0309ZM25.1386 27.9451H27.0086L14.94 11.9496H12.9349L25.1386 27.9451Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2247_1923">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(9 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default XIcon;
