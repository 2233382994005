import { PayloadAction } from "@reduxjs/toolkit";
import { CompanyJourneyList } from "Models/CompanyJourneyModel";
import getCompanyJourney from "Services/Queries/GetCompanyJourney";
import { languageId } from "Utils";
import { call, put, takeLatest } from "redux-saga/effects";
import { CompanyJourneyRequest, CompanyJourneyRequestSuccess } from "./index";

function* handleCompanyJourneyRequest() {
  try {
    console.log("geldimiiii");
    const getCompanyJourneyResult: CompanyJourneyList = yield call(() =>
      getCompanyJourney({ languageId: languageId() })
    );
    yield put(CompanyJourneyRequestSuccess(getCompanyJourneyResult));
  } catch (error) {}
}

function* companyJourneySaga() {
  yield takeLatest(CompanyJourneyRequest, handleCompanyJourneyRequest);
}

export default companyJourneySaga;
