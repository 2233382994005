import { get } from "lodash";
import { GetCmsModel } from "Models";
import { useSelector } from "react-redux";
import { storeCmsData } from "Store/global";

const useLocalization = () => {
  const cmsData = useSelector(storeCmsData);

  return get(cmsData, "CmsKeys") as GetCmsModel["CmsKeys"];
};

export default useLocalization;
