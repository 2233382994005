import { FC, memo, useRef } from "react";
import { Box, Typography } from "@material-ui/core";
import "./LegalTextDetail.scss";
import { DownloadIcon } from "Icons";
import { saveAs } from "file-saver";

type LegalTextDetailProps = {
  title: string;
};
const LegalTextDetail: FC<LegalTextDetailProps> = memo((props) => {
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  return (
    <div id="legalTextDetail" className="pageContainer">
      <Typography
        variant="h3"
        align="center"
        className="legalTextDetail__title"
      >
        <Box fontWeight={600}>{"BELBİM AŞ Aydınlatma Metni*"}</Box>
        <a
          className="downloadLink"
          onClick={() => {
            saveAs(
              "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
              "example.pdf"
            );
          }}
        >
          <DownloadIcon />
        </a>
      </Typography>
      <Box className="legalTextDetail__desc">
        <Typography variant="h5" align="left">
          <Box fontWeight={700}>
            1.Lorem ipsum dolor sit amet.* <br />
            <br />
          </Box>
        </Typography>
        <Typography variant="body1" align="left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae et
          purus ac ut at sit semper in risus. Euismod dolor euismod dui, leo
          purus pellentesque scelerisque. Consectetur volutpat sed vulputate
          aliquet nibh dui. Eget facilisi posuere amet ac in diam adipiscing
          amet adipiscing. In massa id sapien orci, at. Ac interdum porta
          vestibulum, fames diam venenatis, tortor congue. Nisl pellentesque
          diam blandit est. Etiam tincidunt tristique gravida tincidunt vel id.
          Tortor velit lacus, at blandit dui mi quis. Et urna lacus morbi
          eleifend scelerisque viverra laoreet. Scelerisque at elementum
          praesent ut scelerisque ut vitae bibendum mauris. Posuere at quisque
          arcu mi fermentum, purus id quam enim. Ut dis metus, est.*
          <br />
          <br />
        </Typography>
        <Typography variant="h5" align="left">
          <Box fontWeight={700}>
            2.Lorem ipsum dolor sit amet.* <br />
            <br />
          </Box>
        </Typography>
        <Typography variant="body1" align="left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae et
          purus ac ut at sit semper in risus. Euismod dolor euismod dui, leo
          purus pellentesque scelerisque. Consectetur volutpat sed vulputate
          aliquet nibh dui. Eget facilisi posuere amet ac in diam adipiscing
          amet adipiscing. In massa id sapien orci, at. Ac interdum porta
          vestibulum, fames diam venenatis, tortor congue. Nisl pellentesque
          diam blandit est. Etiam tincidunt tristique gravida tincidunt vel id.
          Tortor velit lacus, at blandit dui mi quis. Et urna lacus morbi
          eleifend scelerisque viverra laoreet. Scelerisque at elementum
          praesent ut scelerisque ut vitae bibendum mauris. Posuere at quisque
          arcu mi fermentum, purus id quam enim. Ut dis metus, est.*
          <br />
          <br />
        </Typography>
        <Typography variant="h5" align="left">
          <Box fontWeight={700}>
            3.Lorem ipsum dolor sit amet.* <br />
            <br />
          </Box>
        </Typography>
        <Typography variant="body1" align="left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae et
          purus ac ut at sit semper in risus. Euismod dolor euismod dui, leo
          purus pellentesque scelerisque. Consectetur volutpat sed vulputate
          aliquet nibh dui. Eget facilisi posuere amet ac in diam adipiscing
          amet adipiscing. In massa id sapien orci, at. Ac interdum porta
          vestibulum, fames diam venenatis, tortor congue. Nisl pellentesque
          diam blandit est. Etiam tincidunt tristique gravida tincidunt vel id.
          Tortor velit lacus, at blandit dui mi quis. Et urna lacus morbi
          eleifend scelerisque viverra laoreet. Scelerisque at elementum
          praesent ut scelerisque ut vitae bibendum mauris. Posuere at quisque
          arcu mi fermentum, purus id quam enim. Ut dis metus, est.*
          <br />
          <br />
        </Typography>
        <Typography variant="h5" align="left">
          <Box fontWeight={700}>
            4.Lorem ipsum dolor sit amet.* <br />
            <br />
          </Box>
        </Typography>
        <Typography variant="body1" align="left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae et
          purus ac ut at sit semper in risus. Euismod dolor euismod dui, leo
          purus pellentesque scelerisque. Consectetur volutpat sed vulputate
          aliquet nibh dui. Eget facilisi posuere amet ac in diam adipiscing
          amet adipiscing. In massa id sapien orci, at. Ac interdum porta
          vestibulum, fames diam venenatis, tortor congue. Nisl pellentesque
          diam blandit est. Etiam tincidunt tristique gravida tincidunt vel id.
          Tortor velit lacus, at blandit dui mi quis. Et urna lacus morbi
          eleifend scelerisque viverra laoreet. Scelerisque at elementum
          praesent ut scelerisque ut vitae bibendum mauris. Posuere at quisque
          arcu mi fermentum, purus id quam enim. Ut dis metus, est.*
          <br />
          <br />
        </Typography>
      </Box>
      <div ref={buttonContainerRef}></div>
      
    </div>
  );
});
export default LegalTextDetail;
