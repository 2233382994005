// @ts-nocheck
import { getCollaborationsParams } from "Config";
import { axiosHelper } from "../AxiosHelper";
import { GetCollaborationsModel } from "Models";

const url = `${process.env.REACT_APP_CMS_API_URL}/api/Reference/GetCollaborations`;
const getCollaborations = async ({
  languageId,
}: getCollaborationsParams): Promise<GetCollaborationsModel> => {
  return (
    await axiosHelper({
      method: "get",
      url,
      data: { LanguageId: languageId },
      parameters: { languageId },
    })
  ).data;
};

export default getCollaborations;
